.group-info-wrapper {
  padding: 32px;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  text-align: center;
}

.group-profile {
  text-align: left;
}

.group-logo {
  width: 108px;
  height: 108px;
  border-radius: 8px;
}
.group-name-wrapper {
  height: 30px;
  overflow: hidden;
}
.group-info {
  display: inline-block;
  vertical-align: middle;
  margin-left: 32px;
  // width: calc(100% - 150px);
  .name,
  .group-owner,
  .item {
    display: inline-block;
  }
  .name {
    font-size: 20px;
    color: #333333;
    vertical-align: middle;
  }
  .group-owner {
    padding: 2px 8px;
    margin-left: 10px;
    background-color: #4edb88;
    border-radius: 26px;
    vertical-align: middle;
    font-size: 12px;
    color: #fff;
    img,
    span,
    a {
      vertical-align: middle;
    }
    a {
      color: #fff;
    }
    img {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .item {
    margin: 10px 15px 0 0;
    img {
      vertical-align: middle;
      height: 15px;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      color: #828282;
      margin-left: 6px;
    }
  }
  .group-tags {
    display: inline-block;
    height: 36px;
    overflow: hidden;
    .item {
      font-size: 12px;
      color: #828282;
      text-align: left;
      padding: 4px 6px;
      border-radius: 8px;
      background-color: #e3e3e3;
      margin-top: 10px;
      margin-right: 7px;
    }
  }
}

.group-desc {
  background-color: #fafafa;
  padding: 10px;
  text-align: justify;
  color: #7b7b7b;
  margin: 15px 0px;
  overflow-x: hidden;
  height: 120px;
  pre {
    white-space: pre-wrap;
    word-break: break-all;
  }
}

.group-sm-wrapper {
  border-radius: 8px;
  border: 1px solid #d3d3d3;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  .group-logo {
    width: 76.5px;
    height: 76.5px;
    border-radius: 4.25px;
  }

  .group-info {
    // margin-left: 10px;
    font-size: 13px;
    // width: calc(100% - 90px);


    .name {
      font-size: 18px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  &:hover {
    transform: scale(1.05);
    border: 1px solid #fff;
    box-shadow: 0px 0px 10px #dedede;
  }

  .group-type-wrapper {
    height: 27px;
    overflow-y: hidden;
  }

  .group-type {
    display: inline-block;
    border-radius: 8.5px;
    background-color: #ffc700;
    padding: 5px 7px;
    margin-left: 5px;
    color: white;
    line-height: 0px;
    vertical-align: middle;
    font-size: 0.8em;

    * {
      vertical-align: middle;
    }
    img {
      height: 12px;
      margin-right: 4px;
    }
    &.group-type-pay {
      background-color: #a0de1e;
    }
    &.group-type-new {
      background-color: #ff7a02;
      .anticon {
        width: 12px;
        height: 12px;
      }
    }
  }
}
.group-sm-simple {
  border: none;
  padding: 12px 4px 12px 16px;
  margin: 0;
  &:hover {
    transform: none;
    border: none;
    box-shadow: none;
  }
}

.group-modal {
  color: #7b7b7b;
  .group-desc {
    height: 300px;
  }
  .ant-modal-footer {
    border-top: 0px solid #fff !important;
    text-align: left;
    padding: 0px 50px 40px 50px;
  }
  .group-modal-footer {
    .center {
      text-align: center;
    }
    .qr-code {
      width: 100%;
      margin-top: 10px;
    }
  }
}

.groups-nav {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
  .new-group {
    padding: 0 14px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    * {
      vertical-align: middle;
    }
  }

  .right {
    text-align: right;
  }

  .tag {
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
    white-space: nowrap;
    display: inline-block;
    margin-top: 10px;
  }

  .ant-btn-text {
    border-color: transparent;
  }

  @media screen and (max-width: 600px) {
    .tag {
      border: 1px solid rgba(0, 0, 0, 0.12);
      font-size: 0.8rem;
      background-color: white;
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      border-radius: 20px;
      margin-right: 10px;
      margin-bottom: 5px;
      white-space: nowrap;
      display: inline-block;
    }
  }
}

.recommend-tip {
  text-align: right;
}
.prompt-message-mb {
  display: none;
}
@media screen and (max-width: 600px) {
  .recommend-tip {
    text-align: left;
  }
  .prompt-message-pc {
    display: none;
  }
  .prompt-message-mb {
    display: block;
    padding: 0 15px 20px 15px;
  }
  .groups-nav {
    flex-direction: column;
    align-items: flex-start;
  }
}
