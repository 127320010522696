/**
 * wwads 广告自适应尺寸
 *
 * https://wwads.cn/help/publishers/change-banner-css
 */
.wwads-cn a.block{
  width: 100% !important;
  height: auto !important;
}


.promo-item {
  margin-bottom: 10px;

  &.promo-type-sidebar,
  &.promo-type-post {
    a {
      display: block;
    }

    img {
      width: 100%;
    }
  }

  &.promo-type-post {
    margin-top: 10px;
  }

}


/* Banner */
.promo-banner {
  margin-bottom: 10px;

   // Fix flex container issues
   // https://github.com/nolimits4web/swiper/issues/3599 */
   .swiper {
    display: grid;
  }
  .swiper-wrapper {
    min-width: 0;
  }

  a {
    display: block;
  }

  img {
    width: 100%;
    height: 100px;
  }

  @media (min-width: @screen-md-min) {
    margin-bottom: 20px;
    img {
      width: 100%;
    }
  }
}
