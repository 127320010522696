.container {
  max-width: 1180px;
  margin: 0 auto;
  padding-left: 5px;
  padding-right: 5px;

  @media (min-width: @screen-sm-min) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (min-width: @screen-md-min) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.large-container {
  width: 100%;
  padding: 0;
}

.app-layout {
  margin-top: 20px;
  display: block;

  @media (min-width: @screen-md-min) {
    display: flex;
    column-gap: 20px;
    row-gap: 20px;  
    flex-wrap: nowrap;
  }
}

.app-content {
  @media (min-width: @screen-md-min) {
    flex: 1 1 auto;
    // 桌面版控住主内容区高度，页面内容很少时，尽量让 footer 贴底
    // page height - header - footer - gap
    min-height: calc(100vh - 70px - 270px); 
  }
}

.app-sider {
  @media (min-width: @screen-md-min) {
    flex-shrink: 0;
    flex-grow: 0;
  }
}

.app-sider-md {
  @media (min-width: @screen-md-min) {
    flex-basis: 320px;
  }
}

.app-sider-sm {
  @media (min-width: @screen-md-min) {
    flex-basis: 200px;
  }
}
