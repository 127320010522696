[class^=ant-]::-ms-clear,
[class*= ant-]::-ms-clear,
[class^=ant-] input::-ms-clear,
[class*= ant-] input::-ms-clear,
[class^=ant-] input::-ms-reveal,
[class*= ant-] input::-ms-reveal {
  display: none;
}
/* stylelint-disable property-no-vendor-prefix, at-rule-no-vendor-prefix */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum';
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #f9ba48;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #ffd373;
}
a:active {
  color: #d49633;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  color: #fff;
  background: #f9ba48;
}
.clearfix::before {
  display: table;
  content: '';
}
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.anticon {
  display: inline-flex;
  align-items: center;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon > .anticon {
  line-height: 0;
  vertical-align: 0;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin,
.anticon-spin::before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.ant-fade-enter,
.ant-fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
.ant-fade-leave.ant-fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-fade-enter,
.ant-fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
.ant-fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ant-move-up-enter,
.ant-move-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-up-enter.ant-move-up-enter-active,
.ant-move-up-appear.ant-move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}
.ant-move-up-leave.ant-move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-up-enter,
.ant-move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-down-enter,
.ant-move-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-down-enter.ant-move-down-enter-active,
.ant-move-down-appear.ant-move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}
.ant-move-down-leave.ant-move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-down-enter,
.ant-move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-left-enter,
.ant-move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-left-enter.ant-move-left-enter-active,
.ant-move-left-appear.ant-move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}
.ant-move-left-leave.ant-move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-left-enter,
.ant-move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-right-enter,
.ant-move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-right-enter.ant-move-right-enter-active,
.ant-move-right-appear.ant-move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}
.ant-move-right-leave.ant-move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-right-enter,
.ant-move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #f9ba48;
  --scroll-bar: 0;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #f9ba48;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #f9ba48;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
.ant-slide-down-leave.ant-slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
.ant-slide-left-leave.ant-slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
.ant-slide-right-leave.ant-slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
.ant-zoom-enter,
.ant-zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}
.ant-zoom-leave.ant-zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-enter,
.ant-zoom-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
  transform: none;
}
.ant-zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-enter.ant-zoom-big-enter-active,
.ant-zoom-big-appear.ant-zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.ant-zoom-big-leave.ant-zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-enter-prepare,
.ant-zoom-big-appear-prepare {
  transform: none;
}
.ant-zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-fast-enter-prepare,
.ant-zoom-big-fast-appear-prepare {
  transform: none;
}
.ant-zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-up-enter.ant-zoom-up-enter-active,
.ant-zoom-up-appear.ant-zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}
.ant-zoom-up-leave.ant-zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-up-enter-prepare,
.ant-zoom-up-appear-prepare {
  transform: none;
}
.ant-zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-down-enter.ant-zoom-down-enter-active,
.ant-zoom-down-appear.ant-zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}
.ant-zoom-down-leave.ant-zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-down-enter-prepare,
.ant-zoom-down-appear-prepare {
  transform: none;
}
.ant-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-left-enter.ant-zoom-left-enter-active,
.ant-zoom-left-appear.ant-zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}
.ant-zoom-left-leave.ant-zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-left-enter-prepare,
.ant-zoom-left-appear-prepare {
  transform: none;
}
.ant-zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-right-enter.ant-zoom-right-enter-active,
.ant-zoom-right-appear.ant-zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}
.ant-zoom-right-leave.ant-zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-right-enter-prepare,
.ant-zoom-right-appear-prepare {
  transform: none;
}
.ant-zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-affix {
  position: fixed;
  z-index: 10;
}
.ant-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  word-wrap: break-word;
  border-radius: 2px;
}
.ant-alert-content {
  flex: 1;
  min-width: 0;
}
.ant-alert-icon {
  margin-right: 8px;
}
.ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.ant-alert-success .ant-alert-icon {
  color: #52c41a;
}
.ant-alert-info {
  background-color: #fffcf0;
  border: 1px solid #fff0c4;
}
.ant-alert-info .ant-alert-icon {
  color: #f9ba48;
}
.ant-alert-warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
}
.ant-alert-warning .ant-alert-icon {
  color: #faad14;
}
.ant-alert-error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
}
.ant-alert-error .ant-alert-icon {
  color: #ff4d4f;
}
.ant-alert-error .ant-alert-description > pre {
  margin: 0;
  padding: 0;
}
.ant-alert-action {
  margin-left: 8px;
}
.ant-alert-close-icon {
  margin-left: 8px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.ant-alert-close-icon .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-close-text {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-text:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-with-description {
  align-items: flex-start;
  padding: 15px 15px 15px 24px;
}
.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px 15px;
}
.ant-alert-with-description .ant-alert-icon {
  margin-right: 15px;
  font-size: 24px;
}
.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-alert-message {
  color: rgba(0, 0, 0, 0.85);
}
.ant-alert-with-description .ant-alert-description {
  display: block;
}
.ant-alert.ant-alert-motion-leave {
  overflow: hidden;
  opacity: 1;
  transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-alert.ant-alert-motion-leave-active {
  max-height: 0;
  margin-bottom: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}
.ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
.ant-alert.ant-alert-rtl {
  direction: rtl;
}
.ant-alert-rtl .ant-alert-icon {
  margin-right: auto;
  margin-left: 8px;
}
.ant-alert-rtl .ant-alert-action {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl .ant-alert-close-icon {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl.ant-alert-with-description {
  padding-right: 24px;
  padding-left: 15px;
}
.ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
  margin-right: auto;
  margin-left: 15px;
}
.ant-anchor {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding-left: 2px;
}
.ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: transparent;
}
.ant-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #f0f0f0;
  content: ' ';
}
.ant-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #f9ba48;
  border-radius: 8px;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out;
}
.ant-anchor-ink-ball.ant-anchor-ink-ball-visible {
  display: inline-block;
}
.ant-anchor-fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}
.ant-anchor-link {
  padding: 4px 0 4px 16px;
}
.ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 3px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-anchor-link-title:only-child {
  margin-bottom: 0;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: #f9ba48;
}
.ant-anchor-link .ant-anchor-link {
  padding-top: 2px;
  padding-bottom: 2px;
}
.ant-anchor-rtl {
  direction: rtl;
}
.ant-anchor-rtl.ant-anchor-wrapper {
  margin-right: -4px;
  margin-left: 0;
  padding-right: 4px;
  padding-left: 0;
}
.ant-anchor-rtl .ant-anchor-ink {
  right: 0;
  left: auto;
}
.ant-anchor-rtl .ant-anchor-ink-ball {
  right: 50%;
  left: 0;
  transform: translateX(50%);
}
.ant-anchor-rtl .ant-anchor-link {
  padding: 4px 16px 4px 0;
}
.ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-select-auto-complete .ant-select-clear {
  right: 13px;
}
.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.ant-avatar-image {
  background: transparent;
}
.ant-avatar .ant-image-img {
  display: block;
}
.ant-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
.ant-avatar.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}
.ant-avatar-lg.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
.ant-avatar-sm.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-square {
  border-radius: 2px;
}
.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ant-avatar-group {
  display: inline-flex;
}
.ant-avatar-group .ant-avatar {
  border: 1px solid #fff;
}
.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: -8px;
}
.ant-avatar-group-popover .ant-avatar + .ant-avatar {
  margin-left: 3px;
}
.ant-avatar-group-rtl .ant-avatar:not(:first-child) {
  margin-right: -8px;
  margin-left: 0;
}
.ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
  margin-right: 3px;
  margin-left: 0;
}
.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.ant-back-top:empty {
  display: none;
}
.ant-back-top-rtl {
  right: auto;
  left: 100px;
  direction: rtl;
}
.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  transition: all 0.3s;
}
.ant-back-top-content:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}
.ant-back-top-icon {
  font-size: 24px;
  line-height: 40px;
}
@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
  .ant-back-top-rtl {
    right: auto;
    left: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
  .ant-back-top-rtl {
    right: auto;
    left: 20px;
  }
}
.ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  line-height: 1;
}
.ant-badge-count {
  z-index: auto;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count a,
.ant-badge-count a:hover {
  color: #fff;
}
.ant-badge-count-sm {
  min-width: 14px;
  height: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  border-radius: 7px;
}
.ant-badge-multiple-words {
  padding: 0 8px;
}
.ant-badge-dot {
  z-index: auto;
  width: 6px;
  min-width: 6px;
  height: 6px;
  background: #ff4d4f;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-dot.ant-scroll-number {
  transition: background 1.5s;
}
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}
.ant-badge-count.anticon-spin,
.ant-badge-dot.anticon-spin,
.ant-badge .ant-scroll-number-custom-component.anticon-spin {
  animation: antBadgeLoadingCircle 1s infinite linear;
}
.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.ant-badge-status-success {
  background-color: #52c41a;
}
.ant-badge-status-processing {
  position: relative;
  background-color: #1890ff;
}
.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.ant-badge-status-default {
  background-color: #d9d9d9;
}
.ant-badge-status-error {
  background-color: #ff4d4f;
}
.ant-badge-status-warning {
  background-color: #faad14;
}
.ant-badge-status-pink {
  background: #eb2f96;
}
.ant-badge-status-magenta {
  background: #eb2f96;
}
.ant-badge-status-red {
  background: #f5222d;
}
.ant-badge-status-volcano {
  background: #fa541c;
}
.ant-badge-status-orange {
  background: #fa8c16;
}
.ant-badge-status-yellow {
  background: #fadb14;
}
.ant-badge-status-gold {
  background: #faad14;
}
.ant-badge-status-cyan {
  background: #13c2c2;
}
.ant-badge-status-lime {
  background: #a0d911;
}
.ant-badge-status-green {
  background: #52c41a;
}
.ant-badge-status-blue {
  background: #1890ff;
}
.ant-badge-status-geekblue {
  background: #2f54eb;
}
.ant-badge-status-purple {
  background: #722ed1;
}
.ant-badge-status-text {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation-fill-mode: both;
}
.ant-badge-zoom-leave {
  animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation-fill-mode: both;
}
.ant-badge-not-a-wrapper .ant-badge-zoom-appear,
.ant-badge-not-a-wrapper .ant-badge-zoom-enter {
  animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.ant-badge-not-a-wrapper .ant-badge-zoom-leave {
  animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-badge-count {
  transform: none;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
  transform-origin: 50% 50%;
}
@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
.ant-scroll-number {
  overflow: hidden;
  direction: ltr;
}
.ant-scroll-number-only {
  position: relative;
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  /* stylelint-enable property-no-vendor-prefix */
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  /* stylelint-enable property-no-vendor-prefix */
}
.ant-scroll-number-symbol {
  vertical-align: top;
}
@keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  100% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
@keyframes antNoWrapperZoomBadgeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes antNoWrapperZoomBadgeOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes antBadgeLoadingCircle {
  0% {
    transform-origin: 50%;
  }
  100% {
    transform: translate(50%, -50%) rotate(360deg);
    transform-origin: 50%;
  }
}
.ant-ribbon-wrapper {
  position: relative;
}
.ant-ribbon {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 8px;
  height: 22px;
  padding: 0 8px;
  color: #fff;
  line-height: 22px;
  white-space: nowrap;
  background-color: #f9ba48;
  border-radius: 2px;
}
.ant-ribbon-text {
  color: #fff;
}
.ant-ribbon-corner {
  position: absolute;
  top: 100%;
  width: 8px;
  height: 8px;
  color: currentcolor;
  border: 4px solid;
  transform: scaleY(0.75);
  transform-origin: top;
}
.ant-ribbon-corner::after {
  position: absolute;
  top: -4px;
  left: -4px;
  width: inherit;
  height: inherit;
  color: rgba(0, 0, 0, 0.25);
  border: inherit;
  content: '';
}
.ant-ribbon-color-pink {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-magenta {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-red {
  color: #f5222d;
  background: #f5222d;
}
.ant-ribbon-color-volcano {
  color: #fa541c;
  background: #fa541c;
}
.ant-ribbon-color-orange {
  color: #fa8c16;
  background: #fa8c16;
}
.ant-ribbon-color-yellow {
  color: #fadb14;
  background: #fadb14;
}
.ant-ribbon-color-gold {
  color: #faad14;
  background: #faad14;
}
.ant-ribbon-color-cyan {
  color: #13c2c2;
  background: #13c2c2;
}
.ant-ribbon-color-lime {
  color: #a0d911;
  background: #a0d911;
}
.ant-ribbon-color-green {
  color: #52c41a;
  background: #52c41a;
}
.ant-ribbon-color-blue {
  color: #1890ff;
  background: #1890ff;
}
.ant-ribbon-color-geekblue {
  color: #2f54eb;
  background: #2f54eb;
}
.ant-ribbon-color-purple {
  color: #722ed1;
  background: #722ed1;
}
.ant-ribbon.ant-ribbon-placement-end {
  right: -8px;
  border-bottom-right-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
  right: 0;
  border-color: currentcolor transparent transparent currentcolor;
}
.ant-ribbon.ant-ribbon-placement-start {
  left: -8px;
  border-bottom-left-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
  left: 0;
  border-color: currentcolor currentcolor transparent transparent;
}
.ant-badge-rtl {
  direction: rtl;
}
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-badge-count,
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-badge-dot,
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  direction: ltr;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.ant-badge-rtl .ant-badge-status-text {
  margin-right: 8px;
  margin-left: 0;
}
.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-appear,
.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-enter {
  animation-name: antZoomBadgeInRtl;
}
.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-leave {
  animation-name: antZoomBadgeOutRtl;
}
.ant-ribbon-rtl {
  direction: rtl;
}
.ant-ribbon-rtl.ant-ribbon-placement-end {
  right: unset;
  left: -8px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
  right: unset;
  left: 0;
  border-color: currentcolor currentcolor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
  border-color: currentcolor currentcolor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-start {
  right: -8px;
  left: unset;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
  right: 0;
  left: unset;
  border-color: currentcolor transparent transparent currentcolor;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
  border-color: currentcolor transparent transparent currentcolor;
}
@keyframes antZoomBadgeInRtl {
  0% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
  }
}
@keyframes antZoomBadgeOutRtl {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  100% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
}
.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-breadcrumb .anticon {
  font-size: 14px;
}
.ant-breadcrumb ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-breadcrumb a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-breadcrumb li:last-child {
  color: rgba(0, 0, 0, 0.85);
}
.ant-breadcrumb li:last-child a {
  color: rgba(0, 0, 0, 0.85);
}
li:last-child > .ant-breadcrumb-separator {
  display: none;
}
.ant-breadcrumb-separator {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-link > .anticon + a {
  margin-left: 4px;
}
.ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}
.ant-breadcrumb-rtl {
  direction: rtl;
}
.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.ant-breadcrumb-rtl > span {
  float: right;
}
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + a {
  margin-right: 4px;
  margin-left: 0;
}
.ant-breadcrumb-rtl .ant-breadcrumb-overlay-link > .anticon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 44px;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 2px;
}
.ant-btn-sm {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 2px;
}
.ant-btn > a:only-child {
  color: currentcolor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus {
  color: #ffd373;
  border-color: #ffd373;
  background: #fff;
}
.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentcolor;
}
.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:active {
  color: #d49633;
  border-color: #d49633;
  background: #fff;
}
.ant-btn:active > a:only-child {
  color: currentcolor;
}
.ant-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: #ffffff;
  border-color: #d9d9d9;
  background: #d8d8d8;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn[disabled] > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn[disabled] > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  background: #fff;
}
.ant-btn > span {
  display: inline-block;
}
.ant-btn-primary {
  color: #fff;
  border-color: #f9ba48;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  background: #f9ba48;
}
.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #ffd373;
  background: #ffd373;
}
.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:active {
  color: #fff;
  border-color: #d49633;
  background: #d49633;
}
.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: #ffffff;
  border-color: #d9d9d9;
  background: #d8d8d8;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #ffd373;
  border-left-color: #ffd373;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #ffd373;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #ffd373;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}
.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: transparent;
}
.ant-btn-ghost > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #ffd373;
  border-color: #ffd373;
  background: transparent;
}
.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:active {
  color: #d49633;
  border-color: #d49633;
  background: transparent;
}
.ant-btn-ghost:active > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:active {
  color: #ffffff;
  border-color: #d9d9d9;
  background: #d8d8d8;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #ffd373;
  border-color: #ffd373;
  background: #fff;
}
.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:active {
  color: #d49633;
  border-color: #d49633;
  background: #fff;
}
.ant-btn-dashed:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:active {
  color: #ffffff;
  border-color: #d9d9d9;
  background: #d8d8d8;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-danger > a:only-child {
  color: currentcolor;
}
.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  border-color: #ff7875;
  background: #ff7875;
}
.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:active {
  color: #fff;
  border-color: #d9363e;
  background: #d9363e;
}
.ant-btn-danger:active > a:only-child {
  color: currentcolor;
}
.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:hover,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:active {
  color: #ffffff;
  border-color: #d9d9d9;
  background: #d8d8d8;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link {
  color: #f9ba48;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #ffd373;
  border-color: #ffd373;
  background: transparent;
}
.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:active {
  color: #d49633;
  border-color: #d49633;
  background: transparent;
}
.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: #ffffff;
  border-color: #d9d9d9;
  background: #d8d8d8;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover {
  background: transparent;
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text {
  color: rgba(0, 0, 0, 0.85);
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-text > a:only-child {
  color: currentcolor;
}
.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: #ffd373;
  border-color: #ffd373;
  background: transparent;
}
.ant-btn-text:hover > a:only-child,
.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-text:hover > a:only-child::after,
.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:active {
  color: #d49633;
  border-color: #d49633;
  background: transparent;
}
.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: #ffffff;
  border-color: #d9d9d9;
  background: #d8d8d8;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.ant-btn-text:active {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  background: #fff;
}
.ant-btn-dangerous > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  color: #ff7875;
  border-color: #ff7875;
  background: #fff;
}
.ant-btn-dangerous:hover > a:only-child,
.ant-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:active {
  color: #d9363e;
  border-color: #d9363e;
  background: #fff;
}
.ant-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:hover,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:active {
  color: #ffffff;
  border-color: #d9d9d9;
  background: #d8d8d8;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dangerous.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  border-color: #ff7875;
  background: #ff7875;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  border-color: #d9363e;
  background: #d9363e;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
  color: #ffffff;
  border-color: #d9d9d9;
  background: #d8d8d8;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #ffd373;
  border-color: #ffd373;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #d49633;
  border-color: #d49633;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: #ffffff;
  border-color: #d9d9d9;
  background: #d8d8d8;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  border-color: transparent;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  border-color: transparent;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text {
  color: #ff4d4f;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #ffd373;
  border-color: #ffd373;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #d49633;
  border-color: #d49633;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: #ffffff;
  border-color: #d9d9d9;
  background: #d8d8d8;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #ff7875;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.018);
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #d9363e;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.028);
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-icon-only {
  width: 44px;
  height: 44px;
  padding: 8.4px 0;
  font-size: 16px;
  border-radius: 2px;
  vertical-align: -3px;
}
.ant-btn-icon-only > * {
  font-size: 16px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
  border-radius: 2px;
}
.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0px 0;
  font-size: 14px;
  border-radius: 2px;
}
.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px;
}
.ant-btn-icon-only > .anticon {
  display: flex;
  justify-content: center;
}
.ant-btn-icon-only .anticon-loading {
  padding: 0 !important;
}
a.ant-btn-icon-only {
  vertical-align: -1px;
}
a.ant-btn-icon-only > .anticon {
  display: inline;
}
.ant-btn-round {
  height: 44px;
  padding: 10px 22px;
  font-size: 14px;
  border-radius: 44px;
}
.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0px 12px;
  font-size: 14px;
  border-radius: 24px;
}
.ant-btn-round.ant-btn-icon-only {
  width: auto;
}
.ant-btn-circle {
  min-width: 44px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizespeed;
}
.ant-btn.ant-btn-loading {
  position: relative;
  cursor: default;
}
.ant-btn.ant-btn-loading::before {
  display: block;
}
.ant-btn > .ant-btn-loading-icon {
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 8px;
  animation: none;
}
.ant-btn > .ant-btn-loading-icon .anticon svg {
  animation: loadingCircle 1s infinite linear;
}
.ant-btn-group {
  position: relative;
  display: inline-flex;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active {
  z-index: 2;
}
.ant-btn-group > .ant-btn[disabled],
.ant-btn-group > span > .ant-btn[disabled] {
  z-index: 0;
}
.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: 2px;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 2px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 2px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-rtl.ant-btn + .ant-btn-group,
.ant-btn-rtl.ant-btn-group span + .ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn + span,
.ant-btn-rtl.ant-btn-group > span + span,
.ant-btn-rtl.ant-btn-group + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group-rtl.ant-btn + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span + .ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn + span,
.ant-btn-group-rtl.ant-btn-group > span + span,
.ant-btn-group-rtl.ant-btn-group + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
  margin-right: -1px;
  margin-left: auto;
}
.ant-btn-group.ant-btn-group-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 2px 2px 0;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 2px 0 0 2px;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 2px 2px 0;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 2px 0 0 2px;
}
.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn.ant-btn-background-ghost {
  color: #fff;
  border-color: #fff;
}
.ant-btn.ant-btn-background-ghost,
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:active,
.ant-btn.ant-btn-background-ghost:focus {
  background: transparent;
}
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:focus {
  color: #ffd373;
  border-color: #ffd373;
}
.ant-btn.ant-btn-background-ghost:active {
  color: #d49633;
  border-color: #d49633;
}
.ant-btn.ant-btn-background-ghost[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: #d9d9d9;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #f9ba48;
  border-color: #f9ba48;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #ffd373;
  border-color: #ffd373;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:active {
  color: #d49633;
  border-color: #d49633;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  color: #ffffff;
  border-color: #d9d9d9;
  background: #d8d8d8;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff7875;
  border-color: #ff7875;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:active {
  color: #d9363e;
  border-color: #d9363e;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  color: #ffffff;
  border-color: #d9d9d9;
  background: #d8d8d8;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:hover,
.ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #ff7875;
  border-color: #ff7875;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #d9363e;
  border-color: #d9363e;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  color: #ffffff;
  border-color: #d9d9d9;
  background: #d8d8d8;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  border-color: transparent;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: #ffffff;
  border-color: #d9d9d9;
  background: #d8d8d8;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
a.ant-btn {
  padding-top: 0.01px !important;
  line-height: 42px;
}
a.ant-btn-disabled {
  cursor: not-allowed;
}
a.ant-btn-disabled > * {
  pointer-events: none;
}
a.ant-btn-disabled,
a.ant-btn-disabled:hover,
a.ant-btn-disabled:focus,
a.ant-btn-disabled:active {
  color: #ffffff;
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
a.ant-btn-disabled > a:only-child,
a.ant-btn-disabled:hover > a:only-child,
a.ant-btn-disabled:focus > a:only-child,
a.ant-btn-disabled:active > a:only-child {
  color: currentcolor;
}
a.ant-btn-disabled > a:only-child::after,
a.ant-btn-disabled:hover > a:only-child::after,
a.ant-btn-disabled:focus > a:only-child::after,
a.ant-btn-disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
a.ant-btn-lg {
  line-height: 38px;
}
a.ant-btn-sm {
  line-height: 22px;
}
.ant-btn-compact-item:not(.ant-btn-compact-last-item):not(.ant-btn-compact-item-rtl) {
  margin-right: -1px;
}
.ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-item-rtl {
  margin-left: -1px;
}
.ant-btn-compact-item:hover,
.ant-btn-compact-item:focus,
.ant-btn-compact-item:active {
  z-index: 2;
}
.ant-btn-compact-item[disabled] {
  z-index: 0;
}
.ant-btn-compact-item:not(.ant-btn-compact-first-item):not(.ant-btn-compact-last-item).ant-btn {
  border-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-first-item:not(.ant-btn-compact-last-item):not(.ant-btn-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-last-item:not(.ant-btn-compact-first-item):not(.ant-btn-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-item-rtl.ant-btn-compact-first-item:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-item-rtl.ant-btn-compact-last-item:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-icon-only.ant-btn-compact-item {
  flex: none;
}
.ant-btn-compact-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-item.ant-btn-primary:not([disabled]) {
  position: relative;
}
.ant-btn-compact-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-item.ant-btn-primary:not([disabled])::after {
  position: absolute;
  top: -1px;
  left: -1px;
  display: inline-block;
  width: 1px;
  height: calc(100% + 1px * 2);
  background-color: #ffd373;
  content: ' ';
}
.ant-btn-compact-item-rtl.ant-btn-compact-first-item.ant-btn-compact-item-rtl:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-compact-last-item.ant-btn-compact-item-rtl:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-sm.ant-btn-compact-first-item.ant-btn-compact-item-rtl.ant-btn-sm:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-sm.ant-btn-compact-last-item.ant-btn-compact-item-rtl.ant-btn-sm:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-primary:not([disabled]) + .ant-btn-compact-item-rtl.ant-btn-primary:not([disabled])::after {
  right: -1px;
}
.ant-btn-compact-vertical-item:not(.ant-btn-compact-vertical-last-item) {
  margin-bottom: -1px;
}
.ant-btn-compact-vertical-item:hover,
.ant-btn-compact-vertical-item:focus,
.ant-btn-compact-vertical-item:active {
  z-index: 2;
}
.ant-btn-compact-vertical-item[disabled] {
  z-index: 0;
}
.ant-btn-compact-vertical-item:not(.ant-btn-compact-vertical-first-item):not(.ant-btn-compact-vertical-last-item) {
  border-radius: 0;
}
.ant-btn-compact-vertical-item.ant-btn-compact-vertical-first-item:not(.ant-btn-compact-vertical-last-item) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-vertical-item.ant-btn-compact-vertical-last-item:not(.ant-btn-compact-vertical-first-item) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) {
  position: relative;
}
.ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-vertical-item.ant-btn-primary:not([disabled])::after {
  position: absolute;
  top: -1px;
  left: -1px;
  display: inline-block;
  width: calc(100% + 1px * 2);
  height: 1px;
  background-color: #ffd373;
  content: ' ';
}
.ant-btn-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: #ffd373;
  border-left-color: #d9d9d9;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-right-color: #d9d9d9;
  border-left-color: #ffd373;
}
.ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 8px;
}
.ant-btn-rtl.ant-btn > .anticon + span,
.ant-btn-rtl.ant-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
}
.ant-picker-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}
.ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 80px;
}
.ant-picker-calendar-header .ant-picker-calendar-month-select {
  min-width: 70px;
  margin-left: 8px;
}
.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 8px;
}
.ant-picker-calendar .ant-picker-panel {
  background: #fff;
  border: 0;
  border-top: 1px solid #f0f0f0;
  border-radius: 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-month-panel,
.ant-picker-calendar .ant-picker-panel .ant-picker-date-panel {
  width: auto;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 8px 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-content {
  width: 100%;
}
.ant-picker-calendar-mini {
  border-radius: 2px;
}
.ant-picker-calendar-mini .ant-picker-calendar-header {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-picker-calendar-mini .ant-picker-panel {
  border-radius: 0 0 2px 2px;
}
.ant-picker-calendar-mini .ant-picker-content {
  height: 256px;
}
.ant-picker-calendar-mini .ant-picker-content th {
  height: auto;
  padding: 0;
  line-height: 18px;
}
.ant-picker-calendar-mini .ant-picker-cell::before {
  pointer-events: none;
}
.ant-picker-calendar-full .ant-picker-panel {
  display: block;
  width: 100%;
  text-align: right;
  background: #fff;
  border: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
  padding: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
  background: #f5f5f5;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: #fffcf0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #f9ba48;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  display: block;
  width: auto;
  height: auto;
  margin: 0 4px;
  padding: 4px 8px 0;
  border: 0;
  border-top: 2px solid #f0f0f0;
  border-radius: 0;
  transition: background 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  line-height: 24px;
  transition: color 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 86px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  text-align: left;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: #f9ba48;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: rgba(0, 0, 0, 0.85);
}
@media only screen and (max-width: 480px) {
  .ant-picker-calendar-header {
    display: block;
  }
  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    width: 50%;
  }
  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    width: calc(50% - 8px);
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch > label {
    width: 50%;
    text-align: center;
  }
}
.ant-picker-calendar-rtl {
  direction: rtl;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
  text-align: left;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  padding: 0 0 5px 12px;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  text-align: right;
}
.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  background: #fff;
  border-radius: 2px;
}
.ant-card-rtl {
  direction: rtl;
}
.ant-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.ant-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-bordered {
  border: 1px solid #f0f0f0;
}
.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-card-head::before {
  display: table;
  content: '';
}
.ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-head::before {
  display: table;
  content: '';
}
.ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-head-wrapper {
  display: flex;
  align-items: center;
}
.ant-card-head-title {
  display: inline-block;
  flex: 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-head-title > .ant-typography,
.ant-card-head-title > .ant-typography-edit-content {
  left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.ant-card-head .ant-tabs-top {
  clear: both;
  margin-bottom: -17px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-head .ant-tabs-top-bar {
  border-bottom: 1px solid #f0f0f0;
}
.ant-card-extra {
  margin-left: auto;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-rtl .ant-card-extra {
  margin-right: auto;
  margin-left: 0;
}
.ant-card-body {
  padding: 24px;
}
.ant-card-body::before {
  display: table;
  content: '';
}
.ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-body::before {
  display: table;
  content: '';
}
.ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-contain-grid .ant-card-body {
  display: flex;
  flex-wrap: wrap;
}
.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.ant-card-grid {
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  transition: all 0.3s;
}
.ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
  padding-bottom: 0;
}
.ant-card-bordered .ant-card-cover {
  margin-top: -1px;
  margin-right: -1px;
  margin-left: -1px;
}
.ant-card-cover > * {
  display: block;
  width: 100%;
}
.ant-card-cover img {
  border-radius: 2px 2px 0 0;
}
.ant-card-actions {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}
.ant-card-actions::before {
  display: table;
  content: '';
}
.ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-actions::before {
  display: table;
  content: '';
}
.ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-actions > li {
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 1.5715;
  cursor: pointer;
}
.ant-card-actions > li > span:hover {
  color: #f9ba48;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn),
.ant-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: #f9ba48;
}
.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}
.ant-card-rtl .ant-card-actions > li:not(:last-child) {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}
.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}
.ant-card-meta {
  display: flex;
  margin: -4px 0;
}
.ant-card-meta::before {
  display: table;
  content: '';
}
.ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-meta::before {
  display: table;
  content: '';
}
.ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-meta-avatar {
  padding-right: 16px;
}
.ant-card-rtl .ant-card-meta-avatar {
  padding-right: 0;
  padding-left: 16px;
}
.ant-card-meta-detail {
  flex: 1;
  overflow: hidden;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-card-loading {
  overflow: hidden;
}
.ant-card-loading .ant-card-body {
  user-select: none;
}
.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
.ant-card-small > .ant-card-body {
  padding: 12px;
}
.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ant-carousel .slick-list:focus {
  outline: none;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible;
}
.ant-carousel .slick-list .slick-slide > div > div {
  vertical-align: bottom;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  touch-action: pan-y;
}
.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}
.ant-carousel .slick-track::after {
  clear: both;
}
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.ant-carousel .slick-slide img {
  display: block;
}
.ant-carousel .slick-slide.slick-loading img {
  display: none;
}
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev::before {
  content: '←';
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next::before {
  content: '→';
}
.ant-carousel .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex !important;
  justify-content: center;
  margin-right: 15%;
  margin-bottom: 0;
  margin-left: 15%;
  padding-left: 0;
  list-style: none;
}
.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}
.ant-carousel .slick-dots-top {
  top: 12px;
  bottom: auto;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 16px;
  height: 3px;
  margin: 0 4px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button {
  position: relative;
  display: block;
  width: 100%;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.ant-carousel .slick-dots li button::after {
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  content: '';
}
.ant-carousel .slick-dots li.slick-active {
  width: 24px;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active:hover,
.ant-carousel .slick-dots li.slick-active:focus {
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  flex-direction: column;
  width: 3px;
  height: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
  right: auto;
  left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
  right: 12px;
  left: auto;
}
.ant-carousel-vertical .slick-dots li {
  width: 3px;
  height: 16px;
  margin: 4px 0;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active {
  width: 3px;
  height: 24px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
.ant-carousel-rtl {
  direction: rtl;
}
.ant-carousel-rtl .ant-carousel .slick-track {
  right: 0;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev {
  right: -25px;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev::before {
  content: '→';
}
.ant-carousel-rtl .ant-carousel .slick-next {
  right: auto;
  left: -25px;
}
.ant-carousel-rtl .ant-carousel .slick-next::before {
  content: '←';
}
.ant-carousel-rtl.ant-carousel .slick-dots {
  flex-direction: row-reverse;
}
.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
  flex-direction: column;
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-cascader-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-inner,
.ant-cascader-checkbox:hover .ant-cascader-checkbox-inner,
.ant-cascader-checkbox-input:focus + .ant-cascader-checkbox-inner {
  border-color: #f9ba48;
}
.ant-cascader-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #f9ba48;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-cascader-checkbox:hover::after,
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox::after {
  visibility: visible;
}
.ant-cascader-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-cascader-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-cascader-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner {
  background-color: #f9ba48;
  border-color: #f9ba48;
}
.ant-cascader-checkbox-disabled {
  cursor: not-allowed;
}
.ant-cascader-checkbox-disabled.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-cascader-checkbox-disabled .ant-cascader-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner {
  background-color: #d8d8d8;
  border-color: #d9d9d9 !important;
}
.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner::after {
  border-color: #d8d8d8;
  border-collapse: separate;
  animation-name: none;
}
.ant-cascader-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-cascader-checkbox-disabled:hover::after,
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-disabled::after {
  visibility: hidden;
}
.ant-cascader-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-cascader-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-cascader-checkbox-wrapper.ant-cascader-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-cascader-checkbox-wrapper + .ant-cascader-checkbox-wrapper {
  margin-left: 8px;
}
.ant-cascader-checkbox-wrapper.ant-cascader-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-cascader-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-cascader-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-cascader-checkbox-group-item {
  margin-right: 8px;
}
.ant-cascader-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-cascader-checkbox-group-item + .ant-cascader-checkbox-group-item {
  margin-left: 0;
}
.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #f9ba48;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-cascader-checkbox-indeterminate.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-checkbox-rtl {
  direction: rtl;
}
.ant-cascader-checkbox-group-rtl .ant-cascader-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-cascader-checkbox-group-rtl .ant-cascader-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-cascader-checkbox-group-rtl .ant-cascader-checkbox-group-item + .ant-cascader-checkbox-group-item {
  margin-left: 8px;
}
.ant-cascader {
  width: 184px;
}
.ant-cascader-checkbox {
  top: 0;
  margin-right: 8px;
}
.ant-cascader-menus {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.ant-cascader-menus.ant-cascader-menu-empty .ant-cascader-menu {
  width: 100%;
  height: auto;
}
.ant-cascader-menu {
  flex-grow: 1;
  min-width: 111px;
  height: 180px;
  margin: 0;
  margin: -4px 0;
  padding: 4px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #f0f0f0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ant-cascader-menu-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 12px;
  overflow: hidden;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-cascader-menu-item:hover {
  background: #f5f5f5;
}
.ant-cascader-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-cascader-menu-item-disabled:hover {
  background: transparent;
}
.ant-cascader-menu-empty .ant-cascader-menu-item {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
  pointer-events: none;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #fffcf0;
}
.ant-cascader-menu-item-content {
  flex: auto;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
}
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-menu-item-keyword {
  color: #ff4d4f;
}
.ant-cascader-compact-item:not(.ant-cascader-compact-last-item):not(.ant-cascader-compact-item-rtl) {
  margin-right: -1px;
}
.ant-cascader-compact-item:not(.ant-cascader-compact-last-item).ant-cascader-compact-item-rtl {
  margin-left: -1px;
}
.ant-cascader-compact-item:hover,
.ant-cascader-compact-item:focus,
.ant-cascader-compact-item:active {
  z-index: 2;
}
.ant-cascader-compact-item[disabled] {
  z-index: 0;
}
.ant-cascader-compact-item:not(.ant-cascader-compact-first-item):not(.ant-cascader-compact-last-item).ant-cascader {
  border-radius: 0;
}
.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-first-item:not(.ant-cascader-compact-last-item):not(.ant-cascader-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-last-item:not(.ant-cascader-compact-first-item):not(.ant-cascader-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-item-rtl.ant-cascader-compact-first-item:not(.ant-cascader-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-item-rtl.ant-cascader-compact-last-item:not(.ant-cascader-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-cascader-rtl .ant-cascader-menu-item-expand-icon,
.ant-cascader-rtl .ant-cascader-menu-item-loading-icon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-cascader-rtl .ant-cascader-checkbox {
  top: 0;
  margin-right: 0;
  margin-left: 8px;
}
.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #f9ba48;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #f9ba48;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f9ba48;
  border-color: #f9ba48;
}
.ant-checkbox-disabled {
  cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #d8d8d8;
  border-color: #d9d9d9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #d8d8d8;
  border-collapse: separate;
  animation-name: none;
}
.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}
.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-checkbox-group-item {
  margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #f9ba48;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-checkbox-rtl {
  direction: rtl;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 8px;
}
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 2px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 2px 2px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: inline-block;
  margin-right: 12px;
  font-size: 12px;
  vertical-align: -1px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
  flex: auto;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-left: auto;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
  cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  flex: none;
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item .ant-collapse-icon-collapsible-only {
  cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-icon-collapsible-only .ant-collapse-expand-icon {
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  left: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-collapse-content {
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content-hidden {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 2px 2px;
}
.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item:last-child {
  border-bottom: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse-ghost {
  background-color: transparent;
  border: 0;
}
.ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-collapse-rtl {
  direction: rtl;
}
.ant-collapse-rtl.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
}
.ant-collapse-rtl.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 50%;
  right: auto;
  left: 16px;
  margin: 0;
  transform: translateY(-50%);
}
.ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 0;
  margin-left: 12px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transform: rotate(180deg);
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-right: auto;
  margin-left: 0;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-right: 12px;
  padding-left: 0;
}
.ant-comment {
  position: relative;
  background-color: inherit;
}
.ant-comment-inner {
  display: flex;
  padding: 16px 0;
}
.ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
.ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
.ant-comment-content-author {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
.ant-comment-content-author > a,
.ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}
.ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  transition: color 0.3s;
}
.ant-comment-content-author-name > * {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-name > *:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}
.ant-comment-content-detail p {
  margin-bottom: inherit;
  white-space: pre-wrap;
}
.ant-comment-actions {
  margin-top: 12px;
  margin-bottom: inherit;
  padding-left: 0;
}
.ant-comment-actions > li {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-actions > li > span {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  user-select: none;
}
.ant-comment-actions > li > span:hover {
  color: #595959;
}
.ant-comment-nested {
  margin-left: 44px;
}
.ant-comment-rtl {
  direction: rtl;
}
.ant-comment-rtl .ant-comment-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-comment-rtl .ant-comment-content-author > a,
.ant-comment-rtl .ant-comment-content-author > span {
  padding-right: 0;
  padding-left: 8px;
}
.ant-comment-rtl .ant-comment-actions {
  padding-right: 0;
}
.ant-comment-rtl .ant-comment-actions > li > span {
  margin-right: 0;
  margin-left: 10px;
}
.ant-comment-rtl .ant-comment-nested {
  margin-right: 44px;
  margin-left: 0;
}
.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not(.ant-picker-disabled):hover {
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-picker-status-error.ant-picker-focused,
.ant-picker-status-error.ant-picker:focus {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-picker-status-error.ant-picker .ant-picker-active-bar {
  background: #ff7875;
}
.ant-picker-status-warning.ant-picker,
.ant-picker-status-warning.ant-picker:not(.ant-picker-disabled):hover {
  background-color: #fff;
  border-color: #faad14;
}
.ant-picker-status-warning.ant-picker-focused,
.ant-picker-status-warning.ant-picker:focus {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-picker-status-warning.ant-picker .ant-picker-active-bar {
  background: #ffc53d;
}
.ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  padding: 4px 11px 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: border 0.3s, box-shadow 0.3s;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #ffd373;
  border-right-width: 1px;
}
.ant-input-rtl .ant-picker:hover,
.ant-input-rtl .ant-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-focused {
  border-color: #ffd373;
  box-shadow: 0 0 0 2px rgba(249, 186, 72, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker.ant-picker-disabled {
  background: #d8d8d8;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: rgba(0, 0, 0, 0.25);
}
.ant-picker.ant-picker-borderless {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-picker-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
}
.ant-picker-input > input::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-picker-input > input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-picker-input > input:hover {
  border-color: #ffd373;
  border-right-width: 1px;
}
.ant-input-rtl .ant-picker-input > input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: #ffd373;
  box-shadow: 0 0 0 2px rgba(249, 186, 72, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-picker-input > input:focus,
.ant-input-rtl .ant-picker-input > input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-input > input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d8d8d8;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d8d8d8;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-picker-input > input-borderless,
.ant-picker-input > input-borderless:hover,
.ant-picker-input > input-borderless:focus,
.ant-picker-input > input-borderless-focused,
.ant-picker-input > input-borderless-disabled,
.ant-picker-input > input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-picker-input > input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-picker-input > input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-picker-input > input-sm {
  padding: 0px 7px;
}
.ant-picker-input > input-rtl {
  direction: rtl;
}
.ant-picker-input > input:focus {
  box-shadow: none;
}
.ant-picker-input > input[disabled] {
  background: transparent;
}
.ant-picker-input:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-input-placeholder > input {
  color: #bfbfbf;
}
.ant-picker-large {
  padding: 6.5px 11px 6.5px;
}
.ant-picker-large .ant-picker-input > input {
  font-size: 16px;
}
.ant-picker-small {
  padding: 0px 7px 0px;
}
.ant-picker-suffix {
  display: flex;
  flex: none;
  align-self: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none;
}
.ant-picker-suffix > * {
  vertical-align: top;
}
.ant-picker-suffix > *:not(:last-child) {
  margin-right: 8px;
}
.ant-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  background: #fff;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
}
.ant-picker-clear > * {
  vertical-align: top;
}
.ant-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-separator {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  vertical-align: top;
  cursor: default;
}
.ant-picker-focused .ant-picker-separator {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
  cursor: not-allowed;
}
.ant-picker-range {
  position: relative;
  display: inline-flex;
}
.ant-picker-range .ant-picker-clear {
  right: 11px;
}
.ant-picker-range:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #f9ba48;
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}
.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 1;
}
.ant-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1;
}
.ant-picker-range.ant-picker-small .ant-picker-clear {
  right: 7px;
}
.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-left: 7px;
}
.ant-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
}
.ant-picker-dropdown-hidden {
  display: none;
}
.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  top: 2.58561808px;
  display: block;
  transform: rotate(-135deg) translateY(1px);
}
.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
  bottom: 2.58561808px;
  display: block;
  transform: rotate(45deg);
}
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-picker-dropdown-range {
  padding: 7.54247233px 0;
}
.ant-picker-dropdown-range-hidden {
  display: none;
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 4px;
}
.ant-picker-ranges {
  margin-bottom: 0;
  padding: 4px 12px;
  overflow: hidden;
  line-height: 34px;
  text-align: left;
  list-style: none;
}
.ant-picker-ranges > li {
  display: inline-block;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #f9ba48;
  background: #fffcf0;
  border-color: #fff0c4;
  cursor: pointer;
}
.ant-picker-ranges .ant-picker-ok {
  float: right;
  margin-left: 8px;
}
.ant-picker-range-wrapper {
  display: flex;
}
.ant-picker-range-arrow {
  position: absolute;
  z-index: 1;
  width: 11.3137085px;
  height: 11.3137085px;
  margin-left: 16.5px;
  box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease-out;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-picker-range-arrow::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: margin 0.3s;
}
.ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  flex-wrap: nowrap;
  direction: ltr;
}
.ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.ant-picker-panel-container .ant-picker-panel .ant-picker-content,
.ant-picker-panel-container .ant-picker-panel table {
  text-align: center;
}
.ant-picker-panel-container .ant-picker-panel-focused {
  border-color: #f0f0f0;
}
.ant-picker-compact-item:not(.ant-picker-compact-last-item):not(.ant-picker-compact-item-rtl) {
  margin-right: -1px;
}
.ant-picker-compact-item:not(.ant-picker-compact-last-item).ant-picker-compact-item-rtl {
  margin-left: -1px;
}
.ant-picker-compact-item:hover,
.ant-picker-compact-item:focus,
.ant-picker-compact-item:active {
  z-index: 2;
}
.ant-picker-compact-item.ant-picker-focused {
  z-index: 2;
}
.ant-picker-compact-item[disabled] {
  z-index: 0;
}
.ant-picker-compact-item:not(.ant-picker-compact-first-item):not(.ant-picker-compact-last-item).ant-picker {
  border-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-first-item:not(.ant-picker-compact-last-item):not(.ant-picker-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-last-item:not(.ant-picker-compact-first-item):not(.ant-picker-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-item-rtl.ant-picker-compact-first-item:not(.ant-picker-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-item-rtl.ant-picker-compact-last-item:not(.ant-picker-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-picker-panel {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
}
.ant-picker-panel-focused {
  border-color: #f9ba48;
}
.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
  display: flex;
  flex-direction: column;
  width: 280px;
}
.ant-picker-header {
  display: flex;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid #f0f0f0;
}
.ant-picker-header > * {
  flex: none;
}
.ant-picker-header button {
  padding: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
}
.ant-picker-header > button:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}
.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}
.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}
.ant-picker-header-view button:hover {
  color: #f9ba48;
}
.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
}
.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
  transform: rotate(-45deg);
}
.ant-picker-next-icon,
.ant-picker-super-next-icon {
  transform: rotate(135deg);
}
.ant-picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.ant-picker-content th,
.ant-picker-content td {
  position: relative;
  min-width: 24px;
  font-weight: 400;
}
.ant-picker-content th {
  height: 30px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 30px;
}
.ant-picker-cell {
  padding: 3px 0;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.ant-picker-cell-in-view {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 24px;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #f5f5f5;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #f9ba48;
  border-radius: 2px;
  content: '';
}
.ant-picker-cell-in-view.ant-picker-cell-in-range {
  position: relative;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #fffcf0;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #f9ba48;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #fffcf0;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #fcdfab;
  border-bottom: 1px dashed #fcdfab;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-range-hover::after {
  right: 0;
  left: 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #fffbf5;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #fffbf5;
  transition: all 0.3s;
  content: '';
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 50%;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  left: 50%;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  left: 6px;
  border-left: 1px dashed #fcdfab;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed #fcdfab;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-picker-cell-disabled {
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}
.ant-picker-cell-disabled .ant-picker-cell-inner {
  background: transparent;
}
.ant-picker-cell-disabled::before {
  background: rgba(0, 0, 0, 0.04);
}
.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-picker-decade-panel .ant-picker-content,
.ant-picker-year-panel .ant-picker-content,
.ant-picker-quarter-panel .ant-picker-content,
.ant-picker-month-panel .ant-picker-content {
  height: 264px;
}
.ant-picker-decade-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  padding: 0 8px;
}
.ant-picker-quarter-panel .ant-picker-content {
  height: 56px;
}
.ant-picker-footer {
  width: min-content;
  min-width: 100%;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid transparent;
}
.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-picker-footer-extra {
  padding: 0 12px;
  line-height: 38px;
  text-align: left;
}
.ant-picker-footer-extra:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}
.ant-picker-now {
  text-align: left;
}
.ant-picker-today-btn {
  color: #f9ba48;
}
.ant-picker-today-btn:hover {
  color: #ffd373;
}
.ant-picker-today-btn:active {
  color: #d49633;
}
.ant-picker-today-btn.ant-picker-today-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-picker-decade-panel .ant-picker-cell-inner {
  padding: 0 4px;
}
.ant-picker-decade-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-year-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-month-panel .ant-picker-body {
  padding: 0 8px;
}
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  width: 60px;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  left: 14px;
  border-left: 1px dashed #fcdfab;
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  right: 14px;
  border-right: 1px dashed #fcdfab;
  border-radius: 0 2px 2px 0;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  right: 14px;
  border-right: 1px dashed #fcdfab;
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  left: 14px;
  border-left: 1px dashed #fcdfab;
  border-radius: 2px 0 0 2px;
}
.ant-picker-week-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
  background: transparent !important;
}
.ant-picker-week-panel-row td {
  transition: background 0.3s;
}
.ant-picker-week-panel-row:hover td {
  background: #f5f5f5;
}
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: #f9ba48;
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: rgba(255, 255, 255, 0.5);
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #fff;
}
.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #fff;
}
.ant-picker-date-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-date-panel .ant-picker-content {
  width: 252px;
}
.ant-picker-date-panel .ant-picker-content th {
  width: 36px;
}
.ant-picker-datetime-panel {
  display: flex;
}
.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid #f0f0f0;
}
.ant-picker-datetime-panel .ant-picker-date-panel,
.ant-picker-datetime-panel .ant-picker-time-panel {
  transition: opacity 0.3s;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel,
.ant-picker-datetime-panel-active .ant-picker-time-panel {
  opacity: 0.3;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel-active,
.ant-picker-datetime-panel-active .ant-picker-time-panel-active {
  opacity: 1;
}
.ant-picker-time-panel {
  width: auto;
  min-width: auto;
}
.ant-picker-time-panel .ant-picker-content {
  display: flex;
  flex: auto;
  height: 224px;
}
.ant-picker-time-panel-column {
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  text-align: left;
  list-style: none;
  transition: background 0.3s;
}
.ant-picker-time-panel-column::after {
  display: block;
  height: 196px;
  content: '';
}
.ant-picker-datetime-panel .ant-picker-time-panel-column::after {
  height: 198px;
}
.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #f0f0f0;
}
.ant-picker-time-panel-column-active {
  background: rgba(255, 252, 240, 0.2);
}
.ant-picker-time-panel-column:hover {
  overflow-y: auto;
}
.ant-picker-time-panel-column > li {
  margin: 0;
  padding: 0;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  display: block;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  border-radius: 0;
  cursor: pointer;
  transition: background 0.3s;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #f5f5f5;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #fffcf0;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
/* stylelint-disable selector-type-no-unknown,selector-no-vendor-prefix */
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
  padding: 21px 0;
}
.ant-picker-rtl {
  direction: rtl;
}
.ant-picker-rtl .ant-picker-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-picker-rtl .ant-picker-clear {
  right: auto;
  left: 0;
}
.ant-picker-rtl .ant-picker-separator {
  transform: rotate(180deg);
}
.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range .ant-picker-clear {
  right: auto;
  left: 11px;
}
.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
  margin-right: 11px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-right: 7px;
}
.ant-picker-dropdown-rtl .ant-picker-ranges {
  text-align: right;
}
.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-panel-rtl {
  direction: rtl;
}
.ant-picker-panel-rtl .ant-picker-prev-icon,
.ant-picker-panel-rtl .ant-picker-super-prev-icon {
  transform: rotate(135deg);
}
.ant-picker-panel-rtl .ant-picker-next-icon,
.ant-picker-panel-rtl .ant-picker-super-next-icon {
  transform: rotate(-45deg);
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end::before {
  right: 50%;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  right: 6px;
  left: 0;
  border-right: 1px dashed #fcdfab;
  border-left: none;
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 0;
  left: 6px;
  border-right: none;
  border-left: 1px dashed #fcdfab;
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after {
  right: 6px;
  left: 6px;
  border-right: 1px dashed #fcdfab;
  border-left: 1px dashed #fcdfab;
  border-radius: 2px;
}
.ant-picker-dropdown-rtl .ant-picker-footer-extra {
  direction: rtl;
  text-align: right;
}
.ant-picker-panel-rtl .ant-picker-time-panel {
  direction: ltr;
}
.ant-descriptions-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.ant-descriptions-title {
  flex: auto;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-descriptions-extra {
  margin-left: auto;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-descriptions-view {
  width: 100%;
  border-radius: 2px;
}
.ant-descriptions-view table {
  width: 100%;
  table-layout: fixed;
}
.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 16px;
}
.ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5715;
  text-align: start;
}
.ant-descriptions-item-label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-descriptions-item-label.ant-descriptions-item-no-colon::after {
  content: ' ';
}
.ant-descriptions-item-no-label::after {
  margin: 0;
  content: '';
}
.ant-descriptions-item-content {
  display: table-cell;
  flex: 1;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  word-break: break-word;
  overflow-wrap: break-word;
}
.ant-descriptions-item {
  padding-bottom: 0;
  vertical-align: top;
}
.ant-descriptions-item-container {
  display: flex;
}
.ant-descriptions-item-container .ant-descriptions-item-label,
.ant-descriptions-item-container .ant-descriptions-item-content {
  display: inline-flex;
  align-items: baseline;
}
.ant-descriptions-middle .ant-descriptions-row > th,
.ant-descriptions-middle .ant-descriptions-row > td {
  padding-bottom: 12px;
}
.ant-descriptions-small .ant-descriptions-row > th,
.ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 8px;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto;
  border-collapse: collapse;
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-right: none;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}
.ant-descriptions-bordered .ant-descriptions-item-label::after {
  display: none;
}
.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 12px 24px;
}
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 8px 16px;
}
.ant-descriptions-rtl {
  direction: rtl;
}
.ant-descriptions-rtl .ant-descriptions-item-label::after {
  margin: 0 2px 0 8px;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-left: none;
}
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
}
.ant-divider-horizontal.ant-divider-with-text {
  display: flex;
  align-items: center;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  position: relative;
  width: 50%;
  border-top: 1px solid transparent;
  border-top-color: inherit;
  border-bottom: 0;
  transform: translateY(50%);
  content: '';
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 5%;
}
.ant-divider-horizontal.ant-divider-with-text-left::after {
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::before {
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::after {
  width: 5%;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em;
}
.ant-divider-dashed {
  background: none;
  border-color: rgba(0, 0, 0, 0.06);
  border-style: dashed;
  border-width: 1px 0 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
  border-style: dashed none none;
}
.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}
.ant-divider-plain.ant-divider-with-text {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left::before {
  width: 0;
}
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left::after {
  width: 100%;
}
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left .ant-divider-inner-text {
  padding-left: 0;
}
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right::before {
  width: 100%;
}
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right::after {
  width: 0;
}
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right .ant-divider-inner-text {
  padding-right: 0;
}
.ant-divider-rtl {
  direction: rtl;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 95%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::after {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::before {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::after {
  width: 95%;
}
.ant-drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
}
.ant-drawer-inline {
  position: absolute;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.45);
  pointer-events: auto;
}
.ant-drawer-content-wrapper {
  position: absolute;
  z-index: 1000;
  transition: all 0.3s;
}
.ant-drawer-content-wrapper-hidden {
  display: none;
}
.ant-drawer-left > .ant-drawer-content-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right > .ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-top > .ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom > .ant-drawer-content-wrapper {
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
  pointer-events: auto;
}
.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.ant-drawer-header {
  display: flex;
  flex: 0;
  align-items: center;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 1px solid #f0f0f0;
}
.ant-drawer-header-title {
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.ant-drawer-extra {
  flex: none;
}
.ant-drawer-close {
  display: inline-block;
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-drawer-title {
  flex: 1;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 24px;
  overflow: auto;
}
.ant-drawer-footer {
  flex-shrink: 0;
  padding: 10px 16px;
  border-top: 1px solid #f0f0f0;
}
.panel-motion-enter-start,
.panel-motion-appear-start,
.panel-motion-leave-start {
  transition: none;
}
.panel-motion-enter-active,
.panel-motion-appear-active,
.panel-motion-leave-active {
  transition: all 0.3s;
}
.ant-drawer-mask-motion-enter-active,
.ant-drawer-mask-motion-appear-active,
.ant-drawer-mask-motion-leave-active {
  transition: all 0.3s;
}
.ant-drawer-mask-motion-enter,
.ant-drawer-mask-motion-appear {
  opacity: 0;
}
.ant-drawer-mask-motion-enter-active,
.ant-drawer-mask-motion-appear-active {
  opacity: 1;
}
.ant-drawer-mask-motion-leave {
  opacity: 1;
}
.ant-drawer-mask-motion-leave-active {
  opacity: 0;
}
.ant-drawer-panel-motion-left-enter-start,
.ant-drawer-panel-motion-left-appear-start,
.ant-drawer-panel-motion-left-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-left-enter-active,
.ant-drawer-panel-motion-left-appear-active,
.ant-drawer-panel-motion-left-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-left-enter-start,
.ant-drawer-panel-motion-left-appear-start {
  transform: translateX(-100%) !important;
}
.ant-drawer-panel-motion-left-enter-active,
.ant-drawer-panel-motion-left-appear-active {
  transform: translateX(0);
}
.ant-drawer-panel-motion-left-leave {
  transform: translateX(0);
}
.ant-drawer-panel-motion-left-leave-active {
  transform: translateX(-100%);
}
.ant-drawer-panel-motion-right-enter-start,
.ant-drawer-panel-motion-right-appear-start,
.ant-drawer-panel-motion-right-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-right-enter-active,
.ant-drawer-panel-motion-right-appear-active,
.ant-drawer-panel-motion-right-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-right-enter-start,
.ant-drawer-panel-motion-right-appear-start {
  transform: translateX(100%) !important;
}
.ant-drawer-panel-motion-right-enter-active,
.ant-drawer-panel-motion-right-appear-active {
  transform: translateX(0);
}
.ant-drawer-panel-motion-right-leave {
  transform: translateX(0);
}
.ant-drawer-panel-motion-right-leave-active {
  transform: translateX(100%);
}
.ant-drawer-panel-motion-top-enter-start,
.ant-drawer-panel-motion-top-appear-start,
.ant-drawer-panel-motion-top-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-top-enter-active,
.ant-drawer-panel-motion-top-appear-active,
.ant-drawer-panel-motion-top-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-top-enter-start,
.ant-drawer-panel-motion-top-appear-start {
  transform: translateY(-100%) !important;
}
.ant-drawer-panel-motion-top-enter-active,
.ant-drawer-panel-motion-top-appear-active {
  transform: translateY(0);
}
.ant-drawer-panel-motion-top-leave {
  transform: translateY(0);
}
.ant-drawer-panel-motion-top-leave-active {
  transform: translateY(-100%);
}
.ant-drawer-panel-motion-bottom-enter-start,
.ant-drawer-panel-motion-bottom-appear-start,
.ant-drawer-panel-motion-bottom-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-bottom-enter-active,
.ant-drawer-panel-motion-bottom-appear-active,
.ant-drawer-panel-motion-bottom-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-bottom-enter-start,
.ant-drawer-panel-motion-bottom-appear-start {
  transform: translateY(100%) !important;
}
.ant-drawer-panel-motion-bottom-enter-active,
.ant-drawer-panel-motion-bottom-appear-active {
  transform: translateY(0);
}
.ant-drawer-panel-motion-bottom-leave {
  transform: translateY(0);
}
.ant-drawer-panel-motion-bottom-leave-active {
  transform: translateY(100%);
}
.ant-drawer-rtl {
  direction: rtl;
}
.ant-drawer-rtl .ant-drawer-close {
  margin-right: 0;
  margin-left: 12px;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #ff4d4f;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #ff4d4f;
}
.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown::before {
  position: absolute;
  top: -4px;
  right: 0;
  bottom: -4px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 10px;
}
.ant-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden,
.ant-dropdown-menu-submenu-hidden {
  display: none;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-top,
.ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
  padding-bottom: 15.3137085px;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottom,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding-top: 15.3137085px;
}
.ant-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-dropdown-arrow::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-dropdown-placement-top > .ant-dropdown-arrow,
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  bottom: 10px;
  box-shadow: 3px 3px 7px -3px rgba(0, 0, 0, 0.1);
  transform: rotate(45deg);
}
.ant-dropdown-placement-top > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-placement-bottom > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 9.41421356px;
  box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.1);
  transform: rotate(-135deg) translateY(-0.5px);
}
.ant-dropdown-placement-bottom > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(-135deg) translateY(-0.5px);
}
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}
.ant-dropdown-menu-item {
  position: relative;
  display: flex;
  align-items: center;
}
.ant-dropdown-menu-item-icon {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}
.ant-dropdown-menu-title-content {
  flex: auto;
}
.ant-dropdown-menu-title-content > a {
  color: inherit;
  transition: all 0.3s;
}
.ant-dropdown-menu-title-content > a:hover {
  color: inherit;
}
.ant-dropdown-menu-title-content > a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  color: #f9ba48;
  background-color: #fffcf0;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background-color: #f5f5f5;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled:hover,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled a,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled a,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled a,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled a {
  pointer-events: none;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  font-style: normal;
}
.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 24px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #f9ba48;
}
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn-loading,
.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn {
  cursor: default;
  pointer-events: none;
}
.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn::before {
  display: block;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
  color: #fff;
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #f9ba48;
}
.ant-dropdown-rtl {
  direction: rtl;
}
.ant-dropdown-rtl.ant-dropdown::before {
  right: -7px;
  left: 0;
}
.ant-dropdown-menu.ant-dropdown-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item-group-title,
.ant-dropdown-menu-submenu-rtl .ant-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
  transform-origin: 100% 0;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
  margin-left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  right: auto;
  left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  transform: scaleX(-1);
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  padding-right: 12px;
  padding-left: 24px;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0;
}
.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.ant-empty-image img {
  height: 100%;
}
.ant-empty-image svg {
  height: 100%;
  margin: auto;
}
.ant-empty-footer {
  margin-top: 16px;
}
.ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
}
.ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-small .ant-empty-image {
  height: 35px;
}
.ant-empty-img-default-ellipse {
  fill: #f5f5f5;
  fill-opacity: 0.8;
}
.ant-empty-img-default-path-1 {
  fill: #aeb8c2;
}
.ant-empty-img-default-path-2 {
  fill: url('#linearGradient-1');
}
.ant-empty-img-default-path-3 {
  fill: #f5f5f7;
}
.ant-empty-img-default-path-4 {
  fill: #dce0e6;
}
.ant-empty-img-default-path-5 {
  fill: #dce0e6;
}
.ant-empty-img-default-g {
  fill: #fff;
}
.ant-empty-img-simple-ellipse {
  fill: #f5f5f5;
}
.ant-empty-img-simple-g {
  stroke: #d9d9d9;
}
.ant-empty-img-simple-path {
  fill: #fafafa;
}
.ant-empty-rtl {
  direction: rtl;
}
.ant-form-item .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
.ant-form-inline {
  display: flex;
  flex-wrap: wrap;
}
.ant-form-inline .ant-form-item {
  flex: none;
  flex-wrap: nowrap;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > .ant-form-item-label,
.ant-form-inline .ant-form-item > .ant-form-item-control {
  display: inline-block;
  vertical-align: top;
}
.ant-form-inline .ant-form-item > .ant-form-item-label {
  flex: none;
}
.ant-form-inline .ant-form-item .ant-form-text {
  display: inline-block;
}
.ant-form-inline .ant-form-item .ant-form-item-has-feedback {
  display: inline-block;
}
.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
}
.ant-form-horizontal .ant-form-item-control {
  flex: 1 1 0;
  min-width: 0;
}
.ant-form-horizontal .ant-form-item-label[class$='-24'] + .ant-form-item-control,
.ant-form-horizontal .ant-form-item-label[class*='-24 '] + .ant-form-item-control {
  min-width: unset;
}
.ant-form-vertical .ant-form-item-row {
  flex-direction: column;
}
.ant-form-vertical .ant-form-item-label > label {
  height: auto;
}
.ant-form-vertical .ant-form-item .ant-form-item-control {
  width: 100%;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
}
.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
}
.ant-form-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
  display: none;
}
.ant-form-rtl.ant-form-vertical .ant-form-item-label,
.ant-form-rtl.ant-col-24.ant-form-item-label,
.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
  text-align: right;
}
@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-form-item .ant-form-item-label > label {
    margin: 0;
  }
  .ant-form-item .ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-form-item .ant-form-item-label {
    text-align: right;
  }
  .ant-form .ant-form-item {
    flex-wrap: wrap;
  }
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xs-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xs-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-sm-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-sm-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-md-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-md-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-md-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-lg-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-lg-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xl-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xl-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
    text-align: right;
  }
}
.ant-form-item {
  /* Some non-status related component style is in `components.less` */
  /* To support leave along ErrorList. We add additional className to handle explain style */
}
.ant-form-item-explain-error {
  color: #ff4d4f;
}
.ant-form-item-explain-warning {
  color: #faad14;
}
.ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px;
}
.ant-form-item-has-warning .ant-form-item-split {
  color: #faad14;
}
.ant-form-item-has-error .ant-form-item-split {
  color: #ff4d4f;
}
.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}
.ant-form label {
  font-size: 14px;
}
.ant-form input[type='search'] {
  box-sizing: border-box;
}
.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal;
}
.ant-form input[type='file'] {
  display: block;
}
.ant-form input[type='range'] {
  display: block;
  width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ant-form output {
  display: block;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-form .ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-small .ant-form-item-label > label {
  height: 24px;
}
.ant-form-small .ant-form-item-control-input {
  min-height: 24px;
}
.ant-form-large .ant-form-item-label > label {
  height: 40px;
}
.ant-form-large .ant-form-item-control-input {
  min-height: 40px;
}
.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  margin-bottom: 24px;
  vertical-align: top;
}
.ant-form-item-with-help {
  transition: none;
}
.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
  display: none;
}
.ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.ant-form-item-label-left {
  text-align: left;
}
.ant-form-item-label-wrap {
  overflow: unset;
  line-height: 1.3215em;
  white-space: unset;
}
.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-form-item-label > label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.ant-form-item-label > label .ant-form-item-optional {
  display: inline-block;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional {
  display: none;
}
.ant-form-item-label > label .ant-form-item-tooltip {
  color: rgba(0, 0, 0, 0.45);
  cursor: help;
  writing-mode: horizontal-tb;
  margin-inline-start: 4px;
}
.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
}
.ant-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 100%;
}
.ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
}
.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}
.ant-form-item-explain,
.ant-form-item-extra {
  clear: both;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  padding-top: 0px;
}
.ant-form-item-explain-connected {
  width: 100%;
}
.ant-form-item-extra {
  min-height: 24px;
}
.ant-form-item-with-help .ant-form-item-explain {
  height: auto;
  opacity: 1;
}
.ant-form-item-feedback-icon {
  font-size: 14px;
  text-align: center;
  visibility: visible;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.ant-form-item-feedback-icon-success {
  color: #52c41a;
}
.ant-form-item-feedback-icon-error {
  color: #ff4d4f;
}
.ant-form-item-feedback-icon-warning {
  color: #faad14;
}
.ant-form-item-feedback-icon-validating {
  color: #f9ba48;
}
.ant-show-help {
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-show-help-appear,
.ant-show-help-enter {
  opacity: 0;
}
.ant-show-help-appear-active,
.ant-show-help-enter-active {
  opacity: 1;
}
.ant-show-help-leave {
  opacity: 1;
}
.ant-show-help-leave-active {
  opacity: 0;
}
.ant-show-help-item {
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-show-help-item-appear,
.ant-show-help-item-enter {
  transform: translateY(-5px);
  opacity: 0;
}
.ant-show-help-item-appear-active,
.ant-show-help-item-enter-active {
  transform: translateY(0);
  opacity: 1;
}
.ant-show-help-item-leave {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-show-help-item-leave-active {
  transform: translateY(-5px);
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.ant-form-rtl {
  direction: rtl;
}
.ant-form-rtl .ant-form-item-label {
  text-align: left;
}
.ant-form-rtl .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  margin-left: 4px;
}
.ant-form-rtl .ant-form-item-label > label::after {
  margin: 0 2px 0 8px;
}
.ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
  margin-right: 4px;
  margin-left: 0;
}
.ant-col-rtl .ant-form-item-control:first-child {
  width: 100%;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input {
  padding-right: 11px;
  padding-left: 24px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 11px;
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number-affix-wrapper .ant-input-number {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: auto;
  left: 28px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 0;
  padding-left: 42px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 0;
  margin-left: 19px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
  padding-right: 7px;
  padding-left: 25.2px;
}
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  right: auto;
  left: 0;
}
.ant-form-rtl.ant-form-inline .ant-form-item {
  margin-right: 0;
  margin-left: 16px;
}
.ant-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
}
.ant-row::before,
.ant-row::after {
  display: flex;
}
.ant-row-no-wrap {
  flex-wrap: nowrap;
}
.ant-row-start {
  justify-content: flex-start;
}
.ant-row-center {
  justify-content: center;
}
.ant-row-end {
  justify-content: flex-end;
}
.ant-row-space-between {
  justify-content: space-between;
}
.ant-row-space-around {
  justify-content: space-around;
}
.ant-row-space-evenly {
  justify-content: space-evenly;
}
.ant-row-top {
  align-items: flex-start;
}
.ant-row-middle {
  align-items: center;
}
.ant-row-bottom {
  align-items: flex-end;
}
.ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}
.ant-col-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
.ant-col-xs-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-xs-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-xs-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-xs-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-xs-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-xs-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-xs-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-xs-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-xs-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-xs-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-xs-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-xs-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-xs-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-xs-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-xs-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-xs-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-xs-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-xs-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-xs-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-xs-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-xs-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-xs-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-xs-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-xs-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-xs-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-xs-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-xs-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-xs-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-xs-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-xs-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-xs-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-xs-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-xs-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-xs-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-xs-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-xs-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-xs-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-xs-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-xs-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-xs-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-xs-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-xs-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-xs-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-xs-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-xs-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-xs-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-xs-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-xs-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-xs-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-xs-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-xs-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-xs-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-xs-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-xs-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-xs-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-xs-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-xs-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-xs-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-sm-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-sm-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-sm-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-sm-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-sm-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-sm-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-sm-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-sm-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-sm-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-sm-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-sm-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-sm-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-sm-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-sm-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-sm-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-sm-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-sm-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-sm-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-sm-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-sm-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-sm-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-sm-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-sm-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-sm-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-sm-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-sm-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-sm-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-sm-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-sm-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-sm-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-sm-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-sm-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-sm-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-sm-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-sm-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-sm-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-sm-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-pull-0 {
    right: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-md-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-md-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-md-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-md-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-md-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-md-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-md-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-md-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-md-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-md-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-md-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-md-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-md-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-md-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-md-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-md-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-md-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-md-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-md-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-md-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-md-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-md-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-md-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-md-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-md-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-md-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-md-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-md-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-md-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-md-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-md-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-md-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-md-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-md-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-md-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-md-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-md-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-md-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-md-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-md-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-md-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-md-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-md-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-md-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-md-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-md-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-md-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-md-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-md-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-md-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-md-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-md-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-md-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-md-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-md-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-md-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-md-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-pull-0 {
    right: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-lg-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-lg-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-lg-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-lg-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-lg-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-lg-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-lg-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-lg-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-lg-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-lg-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-lg-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-lg-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-lg-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-lg-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-lg-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-lg-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-lg-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-lg-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-lg-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-lg-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-lg-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-lg-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-lg-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-lg-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-lg-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-lg-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-lg-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-lg-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-lg-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-lg-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-lg-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-lg-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-lg-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-lg-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-lg-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-lg-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-lg-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xxl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xxl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xxl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xxl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xxl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xxl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xxl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xxl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xxl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xxl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xxl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xxl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xxl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xxl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xxl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xxl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xxl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xxl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xxl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xxl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xxl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xxl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xxl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xxl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xxl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xxl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xxl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xxl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xxl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xxl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xxl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xxl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xxl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xxl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xxl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xxl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xxl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
.ant-row-rtl {
  direction: rtl;
}
.ant-image {
  position: relative;
  display: inline-block;
}
.ant-image-img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
.ant-image-img-placeholder {
  background-color: #f5f5f5;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30%;
}
.ant-image-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}
.ant-image-mask-info {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-image-mask-info .anticon {
  margin-inline-end: 4px;
}
.ant-image-mask:hover {
  opacity: 1;
}
.ant-image-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-image-preview {
  pointer-events: none;
  height: 100%;
  text-align: center;
}
.ant-image-preview.ant-zoom-enter,
.ant-image-preview.ant-zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  user-select: none;
}
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-image-preview-mask-hidden {
  display: none;
}
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
}
.ant-image-preview-body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.ant-image-preview-img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  transform: scale3d(1, 1, 1);
  cursor: grab;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  user-select: none;
  pointer-events: auto;
}
.ant-image-preview-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
.ant-image-preview-img-wrapper::before {
  display: inline-block;
  width: 1px;
  height: 50%;
  margin-right: -1px;
  content: '';
}
.ant-image-preview-moving .ant-image-preview-img {
  cursor: grabbing;
}
.ant-image-preview-moving .ant-image-preview-img-wrapper {
  transition-duration: 0s;
}
.ant-image-preview-wrap {
  z-index: 1080;
}
.ant-image-preview-operations-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1081;
  width: 100%;
}
.ant-image-preview-operations {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  color: rgba(255, 255, 255, 0.85);
  list-style: none;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: auto;
}
.ant-image-preview-operations-operation {
  margin-left: 12px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-image-preview-operations-operation:hover {
  background: rgba(0, 0, 0, 0.2);
}
.ant-image-preview-operations-operation-disabled {
  color: rgba(255, 255, 255, 0.25);
  pointer-events: none;
}
.ant-image-preview-operations-operation:last-of-type {
  margin-left: 0;
}
.ant-image-preview-operations-progress {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.ant-image-preview-operations-icon {
  font-size: 18px;
}
.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  position: fixed;
  top: 50%;
  right: 8px;
  z-index: 1081;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  color: rgba(255, 255, 255, 0.85);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s;
  pointer-events: auto;
}
.ant-image-preview-switch-left:hover,
.ant-image-preview-switch-right:hover {
  background: rgba(0, 0, 0, 0.2);
}
.ant-image-preview-switch-left-disabled,
.ant-image-preview-switch-right-disabled,
.ant-image-preview-switch-left-disabled:hover,
.ant-image-preview-switch-right-disabled:hover {
  color: rgba(255, 255, 255, 0.25);
  background: rgba(0, 0, 0, 0.1);
  cursor: not-allowed;
}
.ant-image-preview-switch-left-disabled > .anticon,
.ant-image-preview-switch-right-disabled > .anticon,
.ant-image-preview-switch-left-disabled:hover > .anticon,
.ant-image-preview-switch-right-disabled:hover > .anticon {
  cursor: not-allowed;
}
.ant-image-preview-switch-left > .anticon,
.ant-image-preview-switch-right > .anticon {
  font-size: 18px;
}
.ant-image-preview-switch-left {
  left: 8px;
}
.ant-image-preview-switch-right {
  right: 8px;
}
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
}
.ant-input-affix-wrapper::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-affix-wrapper:hover {
  border-color: #ffd373;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #ffd373;
  box-shadow: 0 0 0 2px rgba(249, 186, 72, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-affix-wrapper:focus,
.ant-input-rtl .ant-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d8d8d8;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d8d8d8;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-affix-wrapper-sm {
  padding: 0px 7px;
}
.ant-input-affix-wrapper-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #ffd373;
  border-right-width: 1px;
  z-index: 1;
}
.ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 0;
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  z-index: 1;
}
.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: rgba(255, 255, 255, 0);
}
.ant-input-affix-wrapper > .ant-input {
  font-size: inherit;
  border: none;
  outline: none;
}
.ant-input-affix-wrapper > .ant-input:focus {
  box-shadow: none !important;
}
.ant-input-affix-wrapper > .ant-input:not(textarea) {
  padding: 0;
}
.ant-input-affix-wrapper::before {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-input-prefix,
.ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}
.ant-input-prefix > *:not(:last-child),
.ant-input-suffix > *:not(:last-child) {
  margin-right: 8px;
}
.ant-input-show-count-suffix {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-show-count-has-suffix {
  margin-right: 2px;
}
.ant-input-prefix {
  margin-right: 4px;
}
.ant-input-suffix {
  margin-left: 4px;
}
.anticon.ant-input-clear-icon,
.ant-input-clear-icon {
  margin: 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  vertical-align: -1px;
  cursor: pointer;
  transition: color 0.3s;
}
.anticon.ant-input-clear-icon:hover,
.ant-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}
.anticon.ant-input-clear-icon:active,
.ant-input-clear-icon:active {
  color: rgba(0, 0, 0, 0.85);
}
.anticon.ant-input-clear-icon-hidden,
.ant-input-clear-icon-hidden {
  visibility: hidden;
}
.anticon.ant-input-clear-icon-has-suffix,
.ant-input-clear-icon-has-suffix {
  margin: 0 4px;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-textarea-status-error.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-warning.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-success.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-validating.ant-input-textarea-has-feedback .ant-input {
  padding-right: 24px;
}
.ant-input-group-wrapper-status-error .ant-input-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-input-group-wrapper-status-warning .ant-input-group-addon {
  color: #faad14;
  border-color: #faad14;
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:hover {
  border-color: #ffd373;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #ffd373;
  box-shadow: 0 0 0 2px rgba(249, 186, 72, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input:focus,
.ant-input-rtl .ant-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d8d8d8;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d8d8d8;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-sm {
  padding: 0px 7px;
}
.ant-input-rtl {
  direction: rtl;
}
.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-group .ant-input:hover {
  z-index: 0;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #f9ba48;
}
.ant-input-group-addon .ant-cascader-picker {
  margin: -9px -12px;
  background-color: transparent;
}
.ant-input-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
.ant-input-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
.ant-input-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 0px 7px;
}
.ant-input-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}
.ant-input-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}
.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group.ant-input-group-compact {
  display: block;
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper,
.ant-input-group.ant-input-group-compact > .ant-input-number-affix-wrapper,
.ant-input-group.ant-input-group-compact > .ant-picker-range {
  display: inline-flex;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select-focused {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 2px 0 0 2px;
}
.ant-input-group > .ant-input-rtl:first-child,
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group > .ant-input:last-child,
.ant-input-group-rtl.ant-input-group-addon:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-group > .ant-input-rtl:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-group > .ant-input-rtl:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.ant-input-password-icon.anticon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-password-icon.anticon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-input[type='color'] {
  height: 32px;
}
.ant-input[type='color'].ant-input-lg {
  height: 40px;
}
.ant-input[type='color'].ant-input-sm {
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.ant-input-textarea-show-count > .ant-input {
  height: 100%;
}
.ant-input-textarea-show-count::after {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  content: attr(data-count);
  pointer-events: none;
}
.ant-input-textarea-show-count.ant-input-textarea-in-form-item::after {
  margin-bottom: -22px;
}
.ant-input-textarea-suffix {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: auto;
}
.ant-input-compact-item:not(.ant-input-compact-last-item):not(.ant-input-compact-item-rtl) {
  margin-right: -1px;
}
.ant-input-compact-item:not(.ant-input-compact-last-item).ant-input-compact-item-rtl {
  margin-left: -1px;
}
.ant-input-compact-item:hover,
.ant-input-compact-item:focus,
.ant-input-compact-item:active {
  z-index: 2;
}
.ant-input-compact-item[disabled] {
  z-index: 0;
}
.ant-input-compact-item:not(.ant-input-compact-first-item):not(.ant-input-compact-last-item).ant-input {
  border-radius: 0;
}
.ant-input-compact-item.ant-input.ant-input-compact-first-item:not(.ant-input-compact-last-item):not(.ant-input-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-compact-item.ant-input.ant-input-compact-last-item:not(.ant-input-compact-first-item):not(.ant-input-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-compact-item.ant-input.ant-input-compact-item-rtl.ant-input-compact-first-item:not(.ant-input-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-compact-item.ant-input.ant-input-compact-item-rtl.ant-input-compact-last-item:not(.ant-input-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #ffd373;
}
.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #ffd373;
}
.ant-input-search .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-search .ant-input-lg {
  line-height: 1.5713;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  left: -1px;
  padding: 0;
  border: 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 2px 2px 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-input-search-button {
  height: 32px;
}
.ant-input-search-button:hover,
.ant-input-search-button:focus {
  z-index: 1;
}
.ant-input-search-large .ant-input-search-button {
  height: 40px;
}
.ant-input-search-small .ant-input-search-button {
  height: 24px;
}
.ant-input-search.ant-input-compact-item:not(.ant-input-compact-item-rtl):not(.ant-input-compact-last-item) .ant-input-group-addon .ant-input-search-button {
  margin-right: -1px;
  border-radius: 0;
}
.ant-input-search.ant-input-compact-item:not(.ant-input-compact-first-item) .ant-input,
.ant-input-search.ant-input-compact-item:not(.ant-input-compact-first-item) .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:hover,
.ant-input-search.ant-input-compact-item > .ant-input:hover,
.ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:hover,
.ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:focus,
.ant-input-search.ant-input-compact-item > .ant-input:focus,
.ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:focus,
.ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:active,
.ant-input-search.ant-input-compact-item > .ant-input:active,
.ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:active {
  z-index: 2;
}
.ant-input-search.ant-input-compact-item > .ant-input-affix-wrapper-focused {
  z-index: 2;
}
.ant-input-search.ant-input-compact-item-rtl:not(.ant-input-compact-last-item) .ant-input-group-addon:last-child .ant-input-search-button {
  margin-left: -1px;
  border-radius: 0;
}
.ant-input-group-wrapper-rtl {
  direction: rtl;
}
.ant-input-group-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
  border: none;
  outline: none;
}
.ant-input-affix-wrapper-rtl .ant-input-prefix {
  margin: 0 0 0 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-suffix {
  margin: 0 4px 0 0;
}
.ant-input-textarea-rtl {
  direction: rtl;
}
.ant-input-textarea-rtl.ant-input-textarea-show-count::after {
  text-align: left;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon-has-suffix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon {
  right: auto;
  left: 8px;
}
.ant-input-search-rtl {
  direction: rtl;
}
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #d9d9d9;
}
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary):hover,
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary):hover {
  border-left-color: #ffd373;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
  border-right-color: #ffd373;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon:last-child {
  right: -1px;
  left: auto;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 2px 0 0 2px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-input {
    height: 32px;
  }
  .ant-input-lg {
    height: 40px;
  }
  .ant-input-sm {
    height: 24px;
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: auto;
  }
}
.ant-input-number-affix-wrapper {
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  position: relative;
  display: inline-flex;
  width: 90px;
  padding: 0;
  padding-inline-start: 11px;
}
.ant-input-number-affix-wrapper::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-input-number-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-affix-wrapper:hover {
  border-color: #ffd373;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input-number-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-focused {
  border-color: #ffd373;
  box-shadow: 0 0 0 2px rgba(249, 186, 72, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-number-affix-wrapper:focus,
.ant-input-rtl .ant-input-number-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d8d8d8;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d8d8d8;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-affix-wrapper-borderless,
.ant-input-number-affix-wrapper-borderless:hover,
.ant-input-number-affix-wrapper-borderless:focus,
.ant-input-number-affix-wrapper-borderless-focused,
.ant-input-number-affix-wrapper-borderless-disabled,
.ant-input-number-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-number-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-number-affix-wrapper-sm {
  padding: 0px 7px;
}
.ant-input-number-affix-wrapper-rtl {
  direction: rtl;
}
.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
  border-color: #ffd373;
  border-right-width: 1px;
  z-index: 1;
}
.ant-input-rtl .ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-affix-wrapper-focused,
.ant-input-number-affix-wrapper:focus {
  z-index: 1;
}
.ant-input-number-affix-wrapper-disabled .ant-input-number[disabled] {
  background: transparent;
}
.ant-input-number-affix-wrapper > div.ant-input-number {
  width: 100%;
  border: none;
  outline: none;
}
.ant-input-number-affix-wrapper > div.ant-input-number.ant-input-number-focused {
  box-shadow: none !important;
}
.ant-input-number-affix-wrapper input.ant-input-number-input {
  padding: 0;
}
.ant-input-number-affix-wrapper::before {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-input-number-affix-wrapper .ant-input-number-handler-wrap {
  z-index: 2;
}
.ant-input-number-prefix,
.ant-input-number-suffix {
  display: flex;
  flex: none;
  align-items: center;
  pointer-events: none;
}
.ant-input-number-prefix {
  margin-inline-end: 4px;
}
.ant-input-number-suffix {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  height: 100%;
  margin-right: 11px;
  margin-left: 4px;
}
.ant-input-number-group-wrapper .ant-input-number-affix-wrapper {
  width: 100%;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-group-wrapper-status-error .ant-input-number-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-input-number-group-wrapper-status-warning .ant-input-number-group-addon {
  color: #faad14;
  border-color: #faad14;
}
.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-input-number::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number:hover {
  border-color: #ffd373;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input-number:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #ffd373;
  box-shadow: 0 0 0 2px rgba(249, 186, 72, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-number:focus,
.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d8d8d8;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d8d8d8;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-borderless,
.ant-input-number-borderless:hover,
.ant-input-number-borderless:focus,
.ant-input-number-borderless-focused,
.ant-input-number-borderless-disabled,
.ant-input-number-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-number-sm {
  padding: 0px 7px;
}
.ant-input-number-rtl {
  direction: rtl;
}
.ant-input-number-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-number-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-number-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-number-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-number-group-addon,
.ant-input-number-group-wrap,
.ant-input-number-group > .ant-input-number {
  display: table-cell;
}
.ant-input-number-group-addon:not(:first-child):not(:last-child),
.ant-input-number-group-wrap:not(:first-child):not(:last-child),
.ant-input-number-group > .ant-input-number:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-number-group-addon,
.ant-input-number-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-number-group-wrap > * {
  display: block !important;
}
.ant-input-number-group .ant-input-number {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-number-group .ant-input-number:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-number-group .ant-input-number:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-number-group .ant-input-number:hover {
  z-index: 0;
}
.ant-input-number-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input-number-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-number-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-number-group-addon .ant-select-open .ant-select-selector,
.ant-input-number-group-addon .ant-select-focused .ant-select-selector {
  color: #f9ba48;
}
.ant-input-number-group-addon .ant-cascader-picker {
  margin: -9px -12px;
  background-color: transparent;
}
.ant-input-number-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.ant-input-number-group > .ant-input-number:first-child,
.ant-input-number-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group > .ant-input-number:first-child .ant-select .ant-select-selector,
.ant-input-number-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group > .ant-input-number-affix-wrapper:not(:first-child) .ant-input-number {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group > .ant-input-number-affix-wrapper:not(:last-child) .ant-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group-addon:first-child {
  border-right: 0;
}
.ant-input-number-group-addon:last-child {
  border-left: 0;
}
.ant-input-number-group > .ant-input-number:last-child,
.ant-input-number-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group > .ant-input-number:last-child .ant-select .ant-select-selector,
.ant-input-number-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group-lg .ant-input-number,
.ant-input-number-group-lg > .ant-input-number-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-number-group-sm .ant-input-number,
.ant-input-number-group-sm > .ant-input-number-group-addon {
  padding: 0px 7px;
}
.ant-input-number-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}
.ant-input-number-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}
.ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact {
  display: block;
}
.ant-input-number-group.ant-input-number-group-compact::before {
  display: table;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::before {
  display: table;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number-affix-wrapper,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number-number-affix-wrapper,
.ant-input-number-group.ant-input-number-group-compact > .ant-picker-range {
  display: inline-flex;
}
.ant-input-number-group.ant-input-number-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-number {
  float: none;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select-focused {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > *:first-child,
.ant-input-number-group.ant-input-number-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-number-group.ant-input-number-group-compact > *:last-child,
.ant-input-number-group.ant-input-number-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group > .ant-input-number-rtl:first-child,
.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-rtl.ant-input-number-group > .ant-input-number:last-child,
.ant-input-number-group-rtl.ant-input-number-group-addon:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-rtl.ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-rtl.ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:first-child,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:last-child,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group > .ant-input-number-rtl:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group > .ant-input-number-rtl:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-wrapper {
  display: inline-block;
  text-align: start;
  vertical-align: top;
}
.ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  border-left: 1px solid #d9d9d9;
  transition: all 0.1s linear;
}
.ant-input-number-handler:active {
  background: #f4f4f4;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #ffd373;
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: inline-flex;
  align-items: center;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 12px;
  transition: all 0.1s linear;
  user-select: none;
}
.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
  line-height: 1;
}
.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: inline-block;
}
.ant-input-number-handler-up-inner::before,
.ant-input-number-handler-down-inner::before {
  display: none;
}
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
.ant-input-number:hover {
  border-color: #ffd373;
  border-right-width: 1px;
}
.ant-input-number:hover + .ant-form-item-children-icon {
  opacity: 0;
  transition: opacity 0.24s linear 0.24s;
}
.ant-input-number-focused {
  border-color: #ffd373;
  box-shadow: 0 0 0 2px rgba(249, 186, 72, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d8d8d8;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-readonly .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  outline: 0;
  transition: all 0.3s linear;
  appearance: textfield !important;
}
.ant-input-number-input::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input[type='number']::-webkit-inner-spin-button,
.ant-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  appearance: none;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.ant-input-number-lg input {
  height: 38px;
}
.ant-input-number-sm {
  padding: 0;
}
.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-radius: 0 2px 2px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  margin-right: 0;
  font-size: 7px;
}
.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-left-width: 0;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap,
.ant-input-number-focused .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-number-handler-up {
  border-top-right-radius: 2px;
  cursor: pointer;
}
.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.ant-input-number-handler-up:hover {
  height: 60% !important;
}
.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: 2px;
  cursor: pointer;
}
.ant-input-number-handler-down-inner {
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}
.ant-input-number-handler-down:hover {
  height: 60% !important;
}
.ant-input-number-borderless .ant-input-number-handler-down {
  border-top-width: 0;
}
.ant-input-number:hover:not(.ant-input-number-borderless) .ant-input-number-handler-down,
.ant-input-number-focused:not(.ant-input-number-borderless) .ant-input-number-handler-down {
  border-top: 1px solid #d9d9d9;
}
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}
.ant-input-number-borderless {
  box-shadow: none;
}
.ant-input-number-out-of-range input {
  color: #ff4d4f;
}
.ant-input-number-compact-item:not(.ant-input-number-compact-last-item):not(.ant-input-number-compact-item-rtl) {
  margin-right: -1px;
}
.ant-input-number-compact-item:not(.ant-input-number-compact-last-item).ant-input-number-compact-item-rtl {
  margin-left: -1px;
}
.ant-input-number-compact-item:hover,
.ant-input-number-compact-item:focus,
.ant-input-number-compact-item:active {
  z-index: 2;
}
.ant-input-number-compact-item.ant-input-number-focused {
  z-index: 2;
}
.ant-input-number-compact-item[disabled] {
  z-index: 0;
}
.ant-input-number-compact-item:not(.ant-input-number-compact-first-item):not(.ant-input-number-compact-last-item).ant-input-number {
  border-radius: 0;
}
.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-first-item:not(.ant-input-number-compact-last-item):not(.ant-input-number-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-last-item:not(.ant-input-number-compact-first-item):not(.ant-input-number-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-item-rtl.ant-input-number-compact-first-item:not(.ant-input-number-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-item-rtl.ant-input-number-compact-last-item:not(.ant-input-number-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-rtl {
  direction: rtl;
}
.ant-input-number-rtl .ant-input-number-handler {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-number-rtl .ant-input-number-handler-wrap {
  right: auto;
  left: 0;
}
.ant-input-number-rtl.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-right-width: 0;
}
.ant-input-number-rtl .ant-input-number-handler-up {
  border-top-right-radius: 0;
}
.ant-input-number-rtl .ant-input-number-handler-down {
  border-bottom-right-radius: 0;
}
.ant-input-number-rtl .ant-input-number-input {
  direction: ltr;
  text-align: right;
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 0;
}
.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #001529;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5;
}
.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: auto;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.ant-layout-sider-zero-width-trigger:hover::after {
  background: rgba(255, 255, 255, 0.1);
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 2px 0 0 2px;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-rtl {
  direction: rtl;
}
.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
}
.ant-list * {
  outline: none;
}
.ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.ant-list-pagination .ant-pagination-options {
  text-align: left;
}
.ant-list-more {
  margin-top: 12px;
  text-align: center;
}
.ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-list-spin {
  min-height: 40px;
  text-align: center;
}
.ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
}
.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.85);
}
.ant-list-item-meta {
  display: flex;
  flex: 1;
  align-items: flex-start;
  max-width: 100%;
}
.ant-list-item-meta-avatar {
  margin-right: 16px;
}
.ant-list-item-meta-content {
  flex: 1 0;
  width: 0;
  color: rgba(0, 0, 0, 0.85);
}
.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}
.ant-list-item-meta-title > a:hover {
  color: #f9ba48;
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #f0f0f0;
}
.ant-list-header {
  background: transparent;
}
.ant-list-footer {
  background: transparent;
}
.ant-list-header,
.ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-list-empty {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-split.ant-list-empty .ant-list-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
.ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}
.ant-list-vertical .ant-list-item {
  align-items: initial;
}
.ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1;
}
.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}
.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}
.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ant-list-item-no-flex {
  display: block;
}
.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}
.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .ant-list-item-action {
    margin-left: 24px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-item {
    flex-wrap: wrap;
  }
  .ant-list-item-action {
    margin-left: 12px;
  }
  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }
  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-list-rtl {
  direction: rtl;
  text-align: right;
}
.ant-list-rtl .ReactVirtualized__List .ant-list-item {
  direction: rtl;
}
.ant-list-rtl .ant-list-pagination {
  text-align: left;
}
.ant-list-rtl .ant-list-item-meta-avatar {
  margin-right: 0;
  margin-left: 16px;
}
.ant-list-rtl .ant-list-item-action {
  margin-right: 48px;
  margin-left: 0;
}
.ant-list.ant-list-rtl .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.ant-list-rtl .ant-list-item-action-split {
  right: auto;
  left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-extra {
  margin-right: 40px;
  margin-left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-action {
  margin-right: auto;
}
.ant-list-rtl .ant-list-vertical .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.ant-list-rtl .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: left;
}
@media screen and (max-width: 768px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 24px;
    margin-left: 0;
  }
  .ant-list-rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 24px;
    margin-left: 0;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 22px;
    margin-left: 0;
  }
  .ant-list-rtl.ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions,
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:focus,
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-mentions-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions,
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:focus,
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-mentions-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-mentions {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  height: auto;
  padding: 0;
  overflow: hidden;
  line-height: 1.5715;
  white-space: pre-wrap;
  vertical-align: bottom;
}
.ant-mentions::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-mentions:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions:hover {
  border-color: #ffd373;
  border-right-width: 1px;
}
.ant-input-rtl .ant-mentions:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions:focus,
.ant-mentions-focused {
  border-color: #ffd373;
  box-shadow: 0 0 0 2px rgba(249, 186, 72, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-mentions:focus,
.ant-input-rtl .ant-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d8d8d8;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-mentions[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d8d8d8;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-mentions-borderless,
.ant-mentions-borderless:hover,
.ant-mentions-borderless:focus,
.ant-mentions-borderless-focused,
.ant-mentions-borderless-disabled,
.ant-mentions-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-mentions {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-mentions-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-mentions-sm {
  padding: 0px 7px;
}
.ant-mentions-rtl {
  direction: rtl;
}
.ant-mentions-disabled > textarea {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d8d8d8;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled > textarea:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-mentions-focused {
  border-color: #ffd373;
  box-shadow: 0 0 0 2px rgba(249, 186, 72, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions > textarea,
.ant-mentions-measure {
  min-height: 30px;
  margin: 0;
  padding: 4px 11px;
  overflow: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  /* stylelint-disable declaration-block-no-redundant-longhand-properties */
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  /* stylelint-enable declaration-block-no-redundant-longhand-properties */
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  tab-size: inherit;
}
.ant-mentions > textarea {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}
.ant-mentions > textarea::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none;
}
.ant-mentions-measure > span {
  display: inline-block;
  min-height: 1em;
}
.ant-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-mentions-dropdown-hidden {
  display: none;
}
.ant-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-mentions-dropdown-menu-item:hover {
  background-color: #f5f5f5;
}
.ant-mentions-dropdown-menu-item:first-child {
  border-radius: 2px 2px 0 0;
}
.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 2px 2px;
}
.ant-mentions-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-selected {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #fafafa;
}
.ant-mentions-dropdown-menu-item-active {
  background-color: #f5f5f5;
}
.ant-mentions-suffix {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: auto;
}
.ant-mentions-rtl {
  direction: rtl;
}
.ant-menu-item-danger.ant-menu-item {
  color: #ff4d4f;
}
.ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-item-danger.ant-menu-item-active {
  color: #ff4d4f;
}
.ant-menu-item-danger.ant-menu-item:active {
  background: #fff1f0;
}
.ant-menu-item-danger.ant-menu-item-selected {
  color: #ff4d4f;
}
.ant-menu-item-danger.ant-menu-item-selected > a,
.ant-menu-item-danger.ant-menu-item-selected > a:hover {
  color: #ff4d4f;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background-color: #fff1f0;
}
.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: #ff4d4f;
}
.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
  color: #ff4d4f;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: #fff;
  background-color: #ff4d4f;
}
.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu.ant-menu-root:focus-visible {
  box-shadow: 0 0 0 2px #fffbed;
}
.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-menu-overflow {
  display: flex;
}
.ant-menu-overflow-item {
  flex: none;
}
.ant-menu-hidden,
.ant-menu-submenu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  height: 1.5715;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: all 0.3s;
}
.ant-menu-horizontal .ant-menu-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-selected {
  color: #f9ba48;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #fffcf0;
}
.ant-menu-submenu .ant-menu-sub {
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-title-content {
  transition: color 0.3s;
}
.ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-item a:hover {
  color: #f9ba48;
}
.ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.ant-menu-item > .ant-badge a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-item > .ant-badge a:hover {
  color: #f9ba48;
}
.ant-menu-item-divider {
  overflow: hidden;
  line-height: 0;
  border-color: #f0f0f0;
  border-style: solid;
  border-width: 1px 0 0;
}
.ant-menu-item-divider-dashed {
  border-style: dashed;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #f9ba48;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #f9ba48;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fffcf0;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}
.ant-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  transition: border-color 0.3s, background 0.3s;
}
.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}
.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  margin-left: 10px;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}
.ant-menu-item .ant-menu-item-icon.svg,
.ant-menu-submenu-title .ant-menu-item-icon.svg {
  vertical-align: -0.125em;
}
.ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-submenu-title.ant-menu-item-only-child > .anticon,
.ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon {
  margin-right: 0;
}
.ant-menu-item:not(.ant-menu-item-disabled):focus-visible,
.ant-menu-submenu-title:not(.ant-menu-item-disabled):focus-visible {
  box-shadow: 0 0 0 2px #fffbed;
}
.ant-menu > .ant-menu-item-divider {
  margin: 1px 0;
  padding: 0;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  border-radius: 2px;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: ' ';
}
.ant-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px;
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 2px;
}
.ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: rgba(0, 0, 0, 0.85);
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #f9ba48;
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2.5px);
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2.5px);
}
.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2.5px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2.5px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #f9ba48;
}
.ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: none;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #f9ba48;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #f9ba48;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  position: absolute;
  right: 20px;
  bottom: 0;
  left: 20px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
}
.ant-menu-horizontal > .ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #f9ba48;
}
.ant-menu-horizontal > .ant-menu-item a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #f9ba48;
}
.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #f9ba48;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
.ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  display: flex;
  align-items: center;
  transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > *,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
  flex: none;
}
.ant-menu.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed .anticon {
  display: inline-block;
}
.ant-menu.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  background: #fafafa;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  cursor: not-allowed;
}
.ant-menu-item-disabled::after,
.ant-menu-submenu-disabled::after {
  border-color: transparent !important;
}
.ant-menu-item-disabled a,
.ant-menu-submenu-disabled a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ant-layout-header .ant-menu {
  line-height: inherit;
}
.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip a:hover {
  color: #fff;
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #f9ba48;
}
.ant-menu.ant-menu-root:focus-visible {
  box-shadow: 0 0 0 2px #d49633;
}
.ant-menu-dark .ant-menu-item:focus-visible,
.ant-menu-dark .ant-menu-submenu-title:focus-visible {
  box-shadow: 0 0 0 2px #d49633;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #001529;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: #001529;
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: #f9ba48;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: #fff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f9ba48;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #f9ba48;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-item-disabled > span > a,
.ant-menu-dark .ant-menu-submenu-disabled > span > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu.ant-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-menu-rtl .ant-menu-item-group-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
  border-left: none;
}
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: top right;
}
.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-right: auto;
  margin-left: 10px;
}
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
  margin-left: 0;
}
.ant-menu-submenu-rtl.ant-menu-submenu-popup {
  transform-origin: 100% 0;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateY(-2px);
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateY(2px);
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
.ant-menu-rtl.ant-menu-inline .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 16px;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 calc(50% - 16px / 2);
}
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 28px 0 16px;
}
.ant-menu-sub.ant-menu-inline {
  border: 0;
}
.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-right: 32px;
  padding-left: 0;
}
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}
.ant-message-success .anticon {
  color: #52c41a;
}
.ant-message-error .anticon {
  color: #ff4d4f;
}
.ant-message-warning .anticon {
  color: #faad14;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #f9ba48;
}
.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.ant-message-notice.ant-move-up-leave.ant-move-up-leave-active {
  animation-name: MessageMoveOut;
  animation-duration: 0.3s;
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-message-rtl {
  direction: rtl;
}
.ant-message-rtl span {
  direction: rtl;
}
.ant-message-rtl .anticon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}
.ant-modal.ant-zoom-enter,
.ant-modal.ant-zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
}
.ant-modal-wrap {
  z-index: 1000;
}
.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 54px;
  height: 54px;
  font-size: 16px;
  font-style: normal;
  line-height: 54px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}
.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  padding-bottom: 0;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    flex: 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 24px;
  text-align: right;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #ff4d4f;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #f9ba48;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}
.ant-modal-confirm .ant-zoom-leave .ant-modal-confirm-btns {
  pointer-events: none;
}
.ant-modal-wrap-rtl {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-close {
  right: initial;
  left: 0;
}
.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}
.ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  z-index: 1010;
  margin-right: 24px;
}
.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.ant-notification-hook-holder {
  position: relative;
}
.ant-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  margin-bottom: 16px;
  margin-left: auto;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5715;
  word-wrap: break-word;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-notification-top .ant-notification-notice,
.ant-notification-bottom .ant-notification-notice {
  margin-right: auto;
  margin-left: auto;
}
.ant-notification-topLeft .ant-notification-notice,
.ant-notification-bottomLeft .ant-notification-notice {
  margin-right: auto;
  margin-left: 0;
}
.ant-notification-notice-message {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
.ant-notification-notice-description {
  font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
  color: #52c41a;
}
.anticon.ant-notification-notice-icon-info {
  color: #f9ba48;
}
.anticon.ant-notification-notice-icon-warning {
  color: #faad14;
}
.anticon.ant-notification-notice-icon-error {
  color: #ff4d4f;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
.ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  opacity: 0;
  animation-play-state: paused;
}
.ant-notification-fade-leave {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-duration: 0.2s;
  animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationFadeIn;
  animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  animation-name: NotificationFadeOut;
  animation-play-state: running;
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
.ant-notification-rtl {
  direction: rtl;
}
.ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 0;
  padding-left: 24px;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-icon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-close {
  right: auto;
  left: 22px;
}
.ant-notification-rtl .ant-notification-notice-btn {
  float: left;
}
.ant-notification-top,
.ant-notification-bottom {
  margin-right: 0;
  margin-left: 0;
}
.ant-notification-top .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-top .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationTopFadeIn;
}
.ant-notification-bottom .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottom .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationBottomFadeIn;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationLeftFadeIn;
}
@keyframes NotificationTopFadeIn {
  0% {
    margin-top: -100%;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes NotificationBottomFadeIn {
  0% {
    margin-bottom: -100%;
    opacity: 0;
  }
  100% {
    margin-bottom: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}
.ant-page-header-ghost {
  background-color: inherit;
}
.ant-page-header.has-breadcrumb {
  padding-top: 12px;
}
.ant-page-header.has-footer {
  padding-bottom: 0;
}
.ant-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.ant-page-header-back-button {
  color: #f9ba48;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  color: #000;
}
.ant-page-header-back-button:focus-visible,
.ant-page-header-back-button:hover {
  color: #ffd373;
}
.ant-page-header-back-button:active {
  color: #d49633;
}
.ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}
.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}
.ant-page-header-heading {
  display: flex;
  justify-content: space-between;
}
.ant-page-header-heading-left {
  display: flex;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
}
.ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading .ant-avatar {
  margin-right: 12px;
}
.ant-page-header-heading-sub-title {
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading-extra {
  margin: 4px 0;
  white-space: nowrap;
}
.ant-page-header-heading-extra > * {
  white-space: unset;
}
.ant-page-header-content {
  padding-top: 12px;
}
.ant-page-header-footer {
  margin-top: 16px;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav {
  margin: 0;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
  border: none;
}
.ant-page-header-footer .ant-tabs .ant-tabs-tab {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}
.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: wrap;
}
.ant-page-header-rtl {
  direction: rtl;
}
.ant-page-header-rtl .ant-page-header-back {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-page-header-rtl .ant-page-header-heading-title {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading .ant-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-sub-title {
  float: right;
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-tags {
  float: right;
}
.ant-page-header-rtl .ant-page-header-heading-extra {
  float: left;
}
.ant-page-header-rtl .ant-page-header-heading-extra > * {
  margin-right: 12px;
  margin-left: 0;
}
.ant-page-header-rtl .ant-page-header-heading-extra > *:first-child {
  margin-right: 0;
}
.ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav {
  float: right;
}
.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  user-select: none;
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.85);
  transition: none;
}
.ant-pagination-item a:hover {
  text-decoration: none;
}
.ant-pagination-item:hover {
  border-color: #f9ba48;
  transition: all 0.3s;
}
.ant-pagination-item:hover a {
  color: #f9ba48;
}
.ant-pagination-item:focus-visible {
  border-color: #f9ba48;
  transition: all 0.3s;
}
.ant-pagination-item:focus-visible a {
  color: #f9ba48;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #f9ba48;
}
.ant-pagination-item-active a {
  color: #f9ba48;
}
.ant-pagination-item-active:hover {
  border-color: #ffd373;
}
.ant-pagination-item-active:focus-visible {
  border-color: #ffd373;
}
.ant-pagination-item-active:hover a {
  color: #ffd373;
}
.ant-pagination-item-active:focus-visible a {
  color: #ffd373;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #f9ba48;
  font-size: 12px;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
}
.ant-pagination-prev button,
.ant-pagination-next button {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  user-select: none;
}
.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: #ffd373;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link {
  color: #f9ba48;
  border-color: #f9ba48;
}
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #f9ba48;
  border-color: #f9ba48;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover {
  cursor: not-allowed;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-disabled:focus-visible {
  cursor: not-allowed;
}
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .ant-pagination-options *::-ms-backdrop,
  .ant-pagination-options {
    vertical-align: top;
  }
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  margin-left: 8px;
  line-height: 32px;
  vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  width: 50px;
  height: 32px;
  margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #ffd373;
  border-right-width: 1px;
}
.ant-input-rtl .ant-pagination-options-quick-jumper input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-options-quick-jumper input-focused {
  border-color: #ffd373;
  box-shadow: 0 0 0 2px rgba(249, 186, 72, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-pagination-options-quick-jumper input:focus,
.ant-input-rtl .ant-pagination-options-quick-jumper input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d8d8d8;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d8d8d8;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-pagination-options-quick-jumper input-borderless,
.ant-pagination-options-quick-jumper input-borderless:hover,
.ant-pagination-options-quick-jumper input-borderless:focus,
.ant-pagination-options-quick-jumper input-borderless-focused,
.ant-pagination-options-quick-jumper input-borderless-disabled,
.ant-pagination-options-quick-jumper input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
  padding: 0px 7px;
}
.ant-pagination-options-quick-jumper input-rtl {
  direction: rtl;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #f9ba48;
}
.ant-pagination-simple .ant-pagination-simple-pager input:focus {
  border-color: #ffd373;
  box-shadow: 0 0 0 2px rgba(249, 186, 72, 0.2);
}
.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #d8d8d8;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-mini .ant-pagination-total-text,
.ant-pagination.ant-pagination-mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev,
.ant-pagination.ant-pagination-mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-jump-prev,
.ant-pagination.ant-pagination-mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options-size-changer {
  top: 0px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper input {
  padding: 0px 7px;
  width: 44px;
  height: 24px;
}
.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #d8d8d8;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #e6e6e6;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: rgba(0, 0, 0, 0.25);
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  background: #d8d8d8;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  background: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
  opacity: 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
  opacity: 1;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}
.ant-pagination-rtl .ant-pagination-total-text {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-item,
.ant-pagination-rtl .ant-pagination-prev,
.ant-pagination-rtl .ant-pagination-jump-prev,
.ant-pagination-rtl .ant-pagination-jump-next {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-slash {
  margin: 0 5px 0 10px;
}
.ant-pagination-rtl .ant-pagination-options {
  margin-right: 16px;
  margin-left: 0;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-size-changer.ant-select {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-quick-jumper {
  margin-left: 0;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
  margin-right: 2px;
  margin-left: 0;
}
.ant-popconfirm {
  z-index: 1060;
}
.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  max-width: 100vw;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  user-select: text;
}
.ant-popover-content {
  position: relative;
}
.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 15.3137085px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 15.3137085px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 15.3137085px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 15.3137085px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }
  .ant-popover-inner {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}
.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}
.ant-popover-inner-content {
  width: max-content;
  max-width: 100%;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
}
.ant-popover-message {
  display: flex;
  padding: 4px 0 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-popover-message-icon {
  display: inline-block;
  margin-right: 8px;
  color: #faad14;
  font-size: 14px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.ant-popover-buttons button:not(:first-child) {
  margin-left: 8px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-popover-arrow-content {
  --antd-arrow-background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-popover-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-popover-placement-top .ant-popover-arrow,
.ant-popover-placement-topLeft .ant-popover-arrow,
.ant-popover-placement-topRight .ant-popover-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.ant-popover-placement-top .ant-popover-arrow-content,
.ant-popover-placement-topLeft .ant-popover-arrow-content,
.ant-popover-placement-topRight .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.ant-popover-placement-top .ant-popover-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.ant-popover-placement-topLeft .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right .ant-popover-arrow,
.ant-popover-placement-rightTop .ant-popover-arrow,
.ant-popover-placement-rightBottom .ant-popover-arrow {
  left: 0;
  transform: translateX(-100%);
}
.ant-popover-placement-right .ant-popover-arrow-content,
.ant-popover-placement-rightTop .ant-popover-arrow-content,
.ant-popover-placement-rightBottom .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.ant-popover-placement-right .ant-popover-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.ant-popover-placement-rightTop .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom .ant-popover-arrow,
.ant-popover-placement-bottomLeft .ant-popover-arrow,
.ant-popover-placement-bottomRight .ant-popover-arrow {
  top: 0;
  transform: translateY(-100%);
}
.ant-popover-placement-bottom .ant-popover-arrow-content,
.ant-popover-placement-bottomLeft .ant-popover-arrow-content,
.ant-popover-placement-bottomRight .ant-popover-arrow-content {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
  transform: translateY(11px) rotate(-135deg);
}
.ant-popover-placement-bottom .ant-popover-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.ant-popover-placement-bottomLeft .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left .ant-popover-arrow,
.ant-popover-placement-leftTop .ant-popover-arrow,
.ant-popover-placement-leftBottom .ant-popover-arrow {
  right: 0;
  transform: translateX(100%);
}
.ant-popover-placement-left .ant-popover-arrow-content,
.ant-popover-placement-leftTop .ant-popover-arrow-content,
.ant-popover-placement-leftBottom .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(-45deg);
}
.ant-popover-placement-left .ant-popover-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.ant-popover-placement-leftTop .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-pink .ant-popover-inner {
  background-color: #eb2f96;
}
.ant-popover-pink .ant-popover-arrow-content {
  background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-inner {
  background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-arrow-content {
  background-color: #eb2f96;
}
.ant-popover-red .ant-popover-inner {
  background-color: #f5222d;
}
.ant-popover-red .ant-popover-arrow-content {
  background-color: #f5222d;
}
.ant-popover-volcano .ant-popover-inner {
  background-color: #fa541c;
}
.ant-popover-volcano .ant-popover-arrow-content {
  background-color: #fa541c;
}
.ant-popover-orange .ant-popover-inner {
  background-color: #fa8c16;
}
.ant-popover-orange .ant-popover-arrow-content {
  background-color: #fa8c16;
}
.ant-popover-yellow .ant-popover-inner {
  background-color: #fadb14;
}
.ant-popover-yellow .ant-popover-arrow-content {
  background-color: #fadb14;
}
.ant-popover-gold .ant-popover-inner {
  background-color: #faad14;
}
.ant-popover-gold .ant-popover-arrow-content {
  background-color: #faad14;
}
.ant-popover-cyan .ant-popover-inner {
  background-color: #13c2c2;
}
.ant-popover-cyan .ant-popover-arrow-content {
  background-color: #13c2c2;
}
.ant-popover-lime .ant-popover-inner {
  background-color: #a0d911;
}
.ant-popover-lime .ant-popover-arrow-content {
  background-color: #a0d911;
}
.ant-popover-green .ant-popover-inner {
  background-color: #52c41a;
}
.ant-popover-green .ant-popover-arrow-content {
  background-color: #52c41a;
}
.ant-popover-blue .ant-popover-inner {
  background-color: #1890ff;
}
.ant-popover-blue .ant-popover-arrow-content {
  background-color: #1890ff;
}
.ant-popover-geekblue .ant-popover-inner {
  background-color: #2f54eb;
}
.ant-popover-geekblue .ant-popover-arrow-content {
  background-color: #2f54eb;
}
.ant-popover-purple .ant-popover-inner {
  background-color: #722ed1;
}
.ant-popover-purple .ant-popover-arrow-content {
  background-color: #722ed1;
}
.ant-popover-rtl {
  direction: rtl;
  text-align: right;
}
.ant-popover-rtl .ant-popover-message-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-popover-rtl .ant-popover-message-title {
  padding-left: 16px;
}
.ant-popover-rtl .ant-popover-buttons {
  text-align: left;
}
.ant-popover-rtl .ant-popover-buttons button {
  margin-right: 8px;
  margin-left: 0;
}
.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.ant-progress-steps {
  display: inline-block;
}
.ant-progress-steps-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ant-progress-steps-item {
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  transition: all 0.3s;
}
.ant-progress-steps-item-active {
  background: #1890ff;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.ant-progress-circle-trail {
  stroke: #f5f5f5;
}
.ant-progress-circle-path {
  animation: ant-progress-appear 0.3s;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff;
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #52c41a;
}
.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-text .anticon {
  font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #ff4d4f;
}
.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #ff4d4f;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #52c41a;
}
.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #52c41a;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
@keyframes ant-progress-active {
  0% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.1;
  }
  20% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0) scaleX(1);
    opacity: 0;
  }
}
.ant-progress-rtl {
  direction: rtl;
}
.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px);
}
.ant-progress-rtl .ant-progress-success-bg {
  right: 0;
  left: auto;
}
.ant-progress-rtl.ant-progress-line .ant-progress-text,
.ant-progress-rtl.ant-progress-steps .ant-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right;
}
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  font-size: 0;
}
.ant-radio-group .ant-badge-count {
  z-index: 1;
}
.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
  border-left: none;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 8px;
  cursor: pointer;
}
.ant-radio-wrapper-disabled {
  cursor: not-allowed;
}
.ant-radio-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-radio-wrapper.ant-radio-wrapper-in-form-item input[type='radio'] {
  width: 14px;
  height: 14px;
}
.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  display: inline-block;
  outline: none;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #f9ba48;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(249, 186, 72, 0.12);
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #f9ba48;
  border-radius: 50%;
  visibility: hidden;
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  background-color: #f9ba48;
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio.ant-radio-disabled .ant-radio-inner {
  border-color: #d9d9d9;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #f9ba48;
}
.ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.5);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled {
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #d8d8d8;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 42px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.ant-radio-button-wrapper a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: #d9d9d9;
  transition: background-color 0.3s;
  content: '';
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 2px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #f9ba48;
}
.ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px rgba(249, 186, 72, 0.12);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #f9ba48;
  background: #fff;
  border-color: #f9ba48;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #f9ba48;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #f9ba48;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #ffd373;
  border-color: #ffd373;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #ffd373;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #d49633;
  border-color: #d49633;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #d49633;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(249, 186, 72, 0.12);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #f9ba48;
  border-color: #f9ba48;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #ffd373;
  border-color: #ffd373;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #d49633;
  border-color: #d49633;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(249, 186, 72, 0.12);
}
.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d8d8d8;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d8d8d8;
  border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: rgba(0, 0, 0, 0.25);
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  box-shadow: none;
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-radio-group.ant-radio-group-rtl {
  direction: rtl;
}
.ant-radio-wrapper.ant-radio-wrapper-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
  border-right-width: 0;
  border-left-width: 1px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
  right: -1px;
  left: 0;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #ffd373;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #d9d9d9;
}
.ant-rate {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
.ant-rate-disabled .ant-rate-star {
  cursor: default;
}
.ant-rate-disabled .ant-rate-star > div:hover {
  transform: scale(1);
}
.ant-rate-star {
  position: relative;
  display: inline-block;
  color: inherit;
  cursor: pointer;
}
.ant-rate-star:not(:last-child) {
  margin-right: 8px;
}
.ant-rate-star > div {
  transition: all 0.3s, outline 0s;
}
.ant-rate-star > div:hover {
  transform: scale(1.1);
}
.ant-rate-star > div:focus {
  outline: 0;
}
.ant-rate-star > div:focus-visible {
  outline: 1px dashed #fadb14;
  transform: scale(1.1);
}
.ant-rate-star-first,
.ant-rate-star-second {
  color: #f0f0f0;
  transition: all 0.3s;
  user-select: none;
}
.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle;
}
.ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}
.ant-rate-text {
  display: inline-block;
  margin: 0 8px;
  font-size: 14px;
}
.ant-rate-rtl {
  direction: rtl;
}
.ant-rate-rtl .ant-rate-star:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-rate-rtl .ant-rate-star-first {
  right: 0;
  left: auto;
}
.ant-result {
  padding: 48px 32px;
}
.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
}
.ant-result-error .ant-result-icon > .anticon {
  color: #ff4d4f;
}
.ant-result-info .ant-result-icon > .anticon {
  color: #f9ba48;
}
.ant-result-warning .ant-result-icon > .anticon {
  color: #faad14;
}
.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}
.ant-result-icon {
  margin-bottom: 24px;
  text-align: center;
}
.ant-result-icon > .anticon {
  font-size: 72px;
}
.ant-result-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.ant-result-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}
.ant-result-extra {
  margin: 24px 0 0 0;
  text-align: center;
}
.ant-result-extra > * {
  margin-right: 8px;
}
.ant-result-extra > *:last-child {
  margin-right: 0;
}
.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}
.ant-result-rtl {
  direction: rtl;
}
.ant-result-rtl .ant-result-extra > * {
  margin-right: 0;
  margin-left: 8px;
}
.ant-result-rtl .ant-result-extra > *:last-child {
  margin-left: 0;
}
.segmented-disabled-item,
.segmented-disabled-item:hover,
.segmented-disabled-item:focus {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.segmented-item-selected {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
}
.segmented-text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-segmented {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  padding: 2px;
  color: rgba(0, 0, 0, 0.65);
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-segmented-group {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-items: flex-start;
  width: 100%;
}
.ant-segmented.ant-segmented-block {
  display: flex;
}
.ant-segmented.ant-segmented-block .ant-segmented-item {
  flex: 1;
  min-width: 0;
}
.ant-segmented:not(.ant-segmented-disabled):hover,
.ant-segmented:not(.ant-segmented-disabled):focus {
  background-color: rgba(0, 0, 0, 0.06);
}
.ant-segmented-item {
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-segmented-item-selected {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  color: #262626;
}
.ant-segmented-item:hover,
.ant-segmented-item:focus {
  color: #262626;
}
.ant-segmented-item-label {
  min-height: 28px;
  padding: 0 11px;
  line-height: 28px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-segmented-item-icon + * {
  margin-left: 6px;
}
.ant-segmented-item-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-segmented.ant-segmented-lg .ant-segmented-item-label {
  min-height: 36px;
  padding: 0 11px;
  font-size: 16px;
  line-height: 36px;
}
.ant-segmented.ant-segmented-sm .ant-segmented-item-label {
  min-height: 20px;
  padding: 0 7px;
  line-height: 20px;
}
.ant-segmented-item-disabled,
.ant-segmented-item-disabled:hover,
.ant-segmented-item-disabled:focus {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-segmented-thumb {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  padding: 4px 0;
}
.ant-segmented-thumb-motion-appear-active {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform, width;
}
.ant-segmented.ant-segmented-rtl {
  direction: rtl;
}
.ant-segmented.ant-segmented-rtl .ant-segmented-item-icon {
  margin-right: 0;
  margin-left: 6px;
}
.ant-select-single .ant-select-selector {
  display: flex;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 100%;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 30px;
  transition: all 0.3s, visibility 0s;
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  position: relative;
  user-select: none;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  transition: none;
  pointer-events: none;
}
.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #bfbfbf;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 11px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 30px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
  line-height: 30px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector::after {
  display: none;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
  position: static;
  width: 100%;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 11px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
  display: none;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 38px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 24px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 22px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 22px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
  right: 7px;
  left: 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 28px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 21px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
}
/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
}
.ant-select-selection-overflow-item {
  flex: none;
  align-self: center;
  max-width: 100%;
}
.ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
}
.ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text;
}
.ant-select-disabled.ant-select-multiple .ant-select-selector {
  background: #d8d8d8;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 2px 0;
  line-height: 24px;
  visibility: hidden;
  content: '\a0';
}
.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 24px;
}
.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 22px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  user-select: none;
  margin-inline-end: 4px;
  padding-inline-start: 8px;
  padding-inline-end: 4px;
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #bfbfbf;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.ant-select-multiple .ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer;
}
.ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block;
}
.ant-select-multiple .ant-select-selection-item-remove::before {
  display: none;
}
.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: middle;
}
.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
  margin-inline-start: 0;
}
.ant-select-multiple .ant-select-selection-search {
  position: relative;
  max-width: 100%;
  margin-inline-start: 7px;
}
.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 24px;
  transition: all 0.3s;
}
.ant-select-multiple .ant-select-selection-search-input {
  width: 100%;
  min-width: 4.1px;
}
.ant-select-multiple .ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre;
  visibility: hidden;
}
.ant-select-multiple .ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 11px;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 30px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 32px;
  line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 32px;
  line-height: 30px;
}
.ant-select-multiple.ant-select-sm .ant-select-selector::after {
  line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 16px;
  line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 16px;
  line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 16px;
  line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 7px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  margin-inline-start: 3px;
}
.ant-select-disabled .ant-select-selection-item-remove {
  display: none;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  background-color: #fff;
  border-color: #ff4d4f !important;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open .ant-select-selector,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  background-color: #fff;
  border-color: #faad14 !important;
}
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open .ant-select-selector,
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-select-status-error.ant-select-has-feedback .ant-select-clear,
.ant-select-status-warning.ant-select-has-feedback .ant-select-clear,
.ant-select-status-success.ant-select-has-feedback .ant-select-clear,
.ant-select-status-validating.ant-select-has-feedback .ant-select-clear {
  right: 32px;
}
.ant-select-status-error.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-warning.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-success.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-validating.ant-select-has-feedback .ant-select-selection-selected-value {
  padding-right: 42px;
}
/* Reset search input style */
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #ffd373;
  box-shadow: 0 0 0 2px rgba(249, 186, 72, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #d8d8d8;
  cursor: not-allowed;
}
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #d8d8d8;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  appearance: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #ffd373;
  border-right-width: 1px;
}
.ant-input-rtl .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-select-selection-item {
  flex: 1;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-item *::-ms-backdrop,
  .ant-select-selection-item {
    flex: auto;
  }
}
.ant-select-selection-placeholder {
  flex: 1;
  overflow: hidden;
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-placeholder *::-ms-backdrop,
  .ant-select-selection-placeholder {
    flex: auto;
  }
}
.ant-select-arrow {
  display: inline-flex;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow svg {
  display: inline-block;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .anticon {
  vertical-align: top;
  transition: transform 0.3s;
}
.ant-select-arrow .anticon > svg {
  vertical-align: top;
}
.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: auto;
}
.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
}
.ant-select-arrow > *:not(:last-child) {
  margin-inline-end: 8px;
}
.ant-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-clear::before {
  display: block;
}
.ant-select-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-select:hover .ant-select-clear {
  opacity: 1;
}
.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-empty {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-select-item-group {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: default;
}
.ant-select-item-option {
  display: flex;
}
.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-item-option-state {
  flex: none;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #fffcf0;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #f9ba48;
}
.ant-select-item-option-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-item-option-disabled.ant-select-item-option-selected {
  background-color: #d8d8d8;
}
.ant-select-item-option-grouped {
  padding-left: 24px;
}
.ant-select-lg {
  font-size: 16px;
}
.ant-select-borderless .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-select.ant-select-in-form-item {
  width: 100%;
}
.ant-select-compact-item:not(.ant-select-compact-last-item) {
  margin-right: -1px;
}
.ant-select-compact-item:not(.ant-select-compact-last-item).ant-select-compact-item-rtl {
  margin-right: 0;
  margin-left: -1px;
}
.ant-select-compact-item:hover > *,
.ant-select-compact-item:focus > *,
.ant-select-compact-item:active > * {
  z-index: 2;
}
.ant-select-compact-item.ant-select-focused > * {
  z-index: 2;
}
.ant-select-compact-item[disabled] > * {
  z-index: 0;
}
.ant-select-compact-item:not(.ant-select-compact-first-item):not(.ant-select-compact-last-item).ant-select > .ant-select-selector {
  border-radius: 0;
}
.ant-select-compact-item.ant-select-compact-first-item.ant-select:not(.ant-select-compact-last-item):not(.ant-select-compact-item-rtl) > .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-select-compact-item.ant-select-compact-last-item.ant-select:not(.ant-select-compact-first-item):not(.ant-select-compact-item-rtl) > .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-select-compact-item.ant-select.ant-select-compact-first-item.ant-select-compact-item-rtl:not(.ant-select-compact-last-item) > .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-select-compact-item.ant-select.ant-select-compact-last-item.ant-select-compact-item-rtl:not(.ant-select-compact-first-item) > .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-select-rtl {
  direction: rtl;
}
.ant-select-rtl .ant-select-arrow {
  right: initial;
  left: 11px;
}
.ant-select-rtl .ant-select-clear {
  right: initial;
  left: 11px;
}
.ant-select-dropdown-rtl {
  direction: rtl;
}
.ant-select-dropdown-rtl .ant-select-item-option-grouped {
  padding-right: 24px;
  padding-left: 12px;
}
.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 4px;
  padding-left: 24px;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
  right: 0;
  left: auto;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
  right: 11px;
  left: auto;
}
.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  right: 7px;
}
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 11px;
  left: 25px;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 6px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px;
}
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
  border-radius: 100px;
}
.ant-skeleton-active .ant-skeleton-title,
.ant-skeleton-active .ant-skeleton-paragraph > li,
.ant-skeleton-active .ant-skeleton-avatar,
.ant-skeleton-active .ant-skeleton-button,
.ant-skeleton-active .ant-skeleton-input,
.ant-skeleton-active .ant-skeleton-image {
  position: relative;
  /* stylelint-disable-next-line property-no-vendor-prefix,value-no-vendor-prefix */
  z-index: 0;
  overflow: hidden;
  background: transparent;
}
.ant-skeleton-active .ant-skeleton-title::after,
.ant-skeleton-active .ant-skeleton-paragraph > li::after,
.ant-skeleton-active .ant-skeleton-avatar::after,
.ant-skeleton-active .ant-skeleton-button::after,
.ant-skeleton-active .ant-skeleton-input::after,
.ant-skeleton-active .ant-skeleton-image::after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
  content: '';
}
.ant-skeleton.ant-skeleton-block {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-button {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-input {
  width: 100%;
}
.ant-skeleton-element {
  display: inline-block;
  width: auto;
}
.ant-skeleton-element .ant-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
  width: 88px;
  min-width: 88px;
  height: 44px;
  line-height: 44px;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-square {
  width: 44px;
  min-width: 44px;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
  width: 44px;
  min-width: 44px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 44px;
}
.ant-skeleton-element .ant-skeleton-button-lg {
  width: 80px;
  min-width: 80px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-square {
  width: 40px;
  min-width: 40px;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
  border-radius: 40px;
}
.ant-skeleton-element .ant-skeleton-button-sm {
  width: 48px;
  min-width: 48px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-square {
  width: 24px;
  min-width: 24px;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
  width: 24px;
  min-width: 24px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
  border-radius: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 160px;
  min-width: 160px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-input-lg {
  width: 200px;
  min-width: 200px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-input-sm {
  width: 120px;
  min-width: 120px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-image {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 96px;
  height: 96px;
  line-height: 96px;
}
.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-image-path {
  fill: #bfbfbf;
}
.ant-skeleton-element .ant-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}
.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
  border-radius: 50%;
}
@keyframes ant-skeleton-loading {
  0% {
    transform: translateX(-37.5%);
  }
  100% {
    transform: translateX(37.5%);
  }
}
.ant-skeleton-rtl {
  direction: rtl;
}
.ant-skeleton-rtl .ant-skeleton-header {
  padding-right: 0;
  padding-left: 16px;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  animation-name: ant-skeleton-loading-rtl;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  animation-name: ant-skeleton-loading-rtl;
}
@keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  height: 12px;
  margin: 10px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-top: -6px;
  margin-left: -5px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  margin-left: -2px;
}
.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
  margin-right: -5px;
  margin-left: 0;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
  right: 12px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
  right: 4px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
  right: 2px;
  left: auto;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #fff0c4;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #fff0c4;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle-dragging {
  z-index: 1;
}
.ant-slider-handle:focus {
  border-color: #fac86d;
  outline: none;
  box-shadow: 0 0 0 5px rgba(249, 186, 72, 0.12);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #f9ba48;
}
.ant-slider-handle::after {
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
  content: '';
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #ffe39c;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #ffe39c;
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  user-select: none;
}
.ant-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.85);
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
  pointer-events: none;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  cursor: pointer;
}
.ant-slider-dot-active {
  border-color: #fcdda4;
}
.ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-rail {
  background-color: #f5f5f5 !important;
}
.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}
.ant-slider-rtl {
  direction: rtl;
}
.ant-slider-rtl .ant-slider-mark {
  right: 0;
  left: auto;
}
.ant-space {
  display: inline-flex;
}
.ant-space-vertical {
  flex-direction: column;
}
.ant-space-align-center {
  align-items: center;
}
.ant-space-align-start {
  align-items: flex-start;
}
.ant-space-align-end {
  align-items: flex-end;
}
.ant-space-align-baseline {
  align-items: baseline;
}
.ant-space-item:empty {
  display: none;
}
.ant-space-compact {
  display: inline-flex;
}
.ant-space-compact-block {
  display: flex;
  width: 100%;
}
.ant-space-compact-vertical {
  flex-direction: column;
}
.ant-space-rtl {
  direction: rtl;
}
.ant-space-compact-rtl {
  direction: rtl;
}
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  display: none;
  color: #f9ba48;
  font-size: 0;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  font-size: 14px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-spin-blur {
  clear: both;
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}
.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #f9ba48;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  animation-delay: 0.4s;
}
.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  animation-delay: 0.8s;
}
.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  transform: rotate(0deg);
  animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antRotate {
  to {
    transform: rotate(360deg);
  }
}
.ant-spin-rtl {
  direction: rtl;
}
.ant-spin-rtl .ant-spin-dot-spin {
  transform: rotate(-45deg);
  animation-name: antRotateRtl;
}
@keyframes antRotateRtl {
  to {
    transform: rotate(-405deg);
  }
}
.ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-statistic-skeleton {
  padding-top: 16px;
}
.ant-statistic-content {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.ant-statistic-content-value {
  display: inline-block;
  direction: ltr;
}
.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block;
}
.ant-statistic-content-prefix {
  margin-right: 4px;
}
.ant-statistic-content-suffix {
  margin-left: 4px;
}
.ant-statistic-rtl {
  direction: rtl;
}
.ant-statistic-rtl .ant-statistic-content-prefix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-statistic-rtl .ant-statistic-content-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: flex;
  width: 100%;
  font-size: 0;
  text-align: initial;
}
.ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  vertical-align: top;
}
.ant-steps-item-container {
  outline: none;
}
.ant-steps-item:last-child {
  flex: none;
}
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -0.5px;
  color: #f9ba48;
  line-height: 1;
}
.ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  border-radius: 1px;
  transition: background 0.3s;
  content: '';
}
.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 32px;
}
.ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #f0f0f0;
  content: '';
}
.ant-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
}
.ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #f9ba48;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #f9ba48;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #f9ba48;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #f9ba48;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #f9ba48;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #f9ba48;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #f9ba48;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f9ba48;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f9ba48;
}
.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #ff4d4f;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #ff4d4f;
}
.ant-steps-item-disabled {
  cursor: not-allowed;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon {
  transition: color 0.3s;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #f9ba48;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #f9ba48;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #f9ba48;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 16px;
  white-space: nowrap;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
  top: 0px;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #f9ba48;
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
  background: none;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 12px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
.ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}
.ant-steps-small .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}
.ant-steps-vertical {
  display: flex;
  flex-direction: column;
}
.ant-steps-vertical > .ant-steps-item {
  display: block;
  flex: 1 0 auto;
  padding-left: 0;
  overflow: visible;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
  line-height: 32px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  padding-bottom: 12px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 15px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 11px;
  padding: 30px 0 6px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}
.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
}
.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
  padding-left: 0;
}
.ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}
.ant-steps-label-vertical .ant-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.5715;
}
.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 46px;
}
.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5715;
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
  /* expand hover area */
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  position: relative;
  top: -1px;
  width: 10px;
  height: 10px;
  line-height: 10px;
  background: none;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 13px;
  margin-left: 0;
  background: none;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 6.5px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-top: 10px;
}
.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 3.5px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
  width: inherit;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot {
  top: -1px;
  left: -1px;
}
.ant-steps-navigation {
  padding-top: 12px;
}
.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px;
}
.ant-steps-navigation .ant-steps-item {
  overflow: visible;
  text-align: center;
}
.ant-steps-navigation .ant-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  transition: opacity 0.3s;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
  opacity: 0.85;
}
.ant-steps-navigation .ant-steps-item:last-child {
  flex: 1;
}
.ant-steps-navigation .ant-steps-item:last-child::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  content: '';
}
.ant-steps-navigation .ant-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 2px;
  background-color: #f9ba48;
  transition: width 0.3s, left 0.3s;
  transition-timing-function: ease-out;
  content: '';
}
.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  left: 0;
  width: 100%;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item {
  margin-right: 0 !important;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::before {
  display: none;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item.ant-steps-item-active::before {
  top: 0;
  right: 0;
  left: unset;
  display: block;
  width: 3px;
  height: calc(100% - 24px);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::after {
  position: relative;
  top: -2px;
  left: 50%;
  display: block;
  width: 8px;
  height: 8px;
  margin-bottom: 8px;
  text-align: center;
  transform: rotate(135deg);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  visibility: hidden;
}
.ant-steps-navigation.ant-steps-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  visibility: hidden;
}
.ant-steps-rtl {
  direction: rtl;
}
.ant-steps.ant-steps-rtl .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-steps-rtl .ant-steps-item-tail {
  right: 0;
  left: auto;
}
.ant-steps-rtl .ant-steps-item-title {
  padding-right: 0;
  padding-left: 16px;
}
.ant-steps-rtl .ant-steps-item-title .ant-steps-item-subtitle {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.ant-steps-rtl .ant-steps-item-title::after {
  right: 100%;
  left: auto;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 16px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  right: 0.5px;
  left: auto;
}
.ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-right: -12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
  margin-right: -16px;
  margin-left: 0;
  text-align: right;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item::after {
  right: 100%;
  left: auto;
  margin-right: -2px;
  margin-left: 0;
  transform: rotate(225deg);
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 12px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-small .ant-steps-item-title {
  padding-right: 0;
  padding-left: 12px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 16px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  right: 12px;
  left: auto;
}
.ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  margin: 0 70px 0 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  margin-right: 12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 2px;
  left: auto;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-right: 67px;
  margin-left: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  /* expand hover area */
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  float: right;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  right: -26px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: -9px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 0;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  right: -2px;
  left: auto;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-vertical > .ant-steps-item {
  padding-right: 4px;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 19px;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-small.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 15px;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child {
  padding-right: 4px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child.ant-steps-item-active {
  padding-right: 4px;
}
.ant-steps-with-progress .ant-steps-item {
  padding-top: 4px;
}
.ant-steps-with-progress .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 4px;
  left: 19px;
}
.ant-steps-with-progress.ant-steps-horizontal .ant-steps-item:first-child,
.ant-steps-with-progress.ant-steps-small.ant-steps-horizontal .ant-steps-item:first-child {
  padding-bottom: 4px;
  padding-left: 4px;
}
.ant-steps-with-progress.ant-steps-small > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  left: 15px;
}
.ant-steps-with-progress.ant-steps-vertical .ant-steps-item {
  padding-left: 4px;
}
.ant-steps-with-progress.ant-steps-label-vertical .ant-steps-item .ant-steps-item-tail {
  top: 14px !important;
}
.ant-steps-with-progress .ant-steps-item-icon {
  position: relative;
}
.ant-steps-with-progress .ant-steps-item-icon .ant-progress {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
}
.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
}
.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.ant-switch-checked:focus {
  box-shadow: 0 0 0 2px #fffcf0;
}
.ant-switch:focus:hover {
  box-shadow: none;
}
.ant-switch-checked {
  background-color: #f9ba48;
}
.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.ant-switch-loading *,
.ant-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}
.ant-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #fff;
  font-size: 12px;
  transition: margin 0.2s;
}
.ant-switch-checked .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}
.ant-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: '';
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 2px);
}
.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-loading-icon.anticon {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.65);
  vertical-align: top;
}
.ant-switch-checked .ant-switch-loading-icon {
  color: #f9ba48;
}
.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;
}
.ant-switch-small .ant-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px;
}
.ant-switch-small .ant-switch-handle {
  width: 12px;
  height: 12px;
}
.ant-switch-small .ant-switch-loading-icon {
  top: 1.5px;
  font-size: 9px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin: 0 18px 0 5px;
}
.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 12px - 2px);
}
.ant-switch-rtl {
  direction: rtl;
}
.ant-switch-rtl .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-rtl .ant-switch-handle {
  right: 2px;
  left: auto;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-inner {
  margin: 0 7px 0 25px;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 18px - 2px);
}
.ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 12px - 2px);
}
.ant-table.ant-table-middle {
  font-size: 14px;
}
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
  padding: 12px 8px;
}
.ant-table.ant-table-middle .ant-table-filter-trigger {
  margin-right: -4px;
}
.ant-table.ant-table-middle .ant-table-expanded-row-fixed {
  margin: -12px -8px;
}
.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -12px -8px -12px 40px;
}
.ant-table.ant-table-middle .ant-table-selection-column {
  padding-inline-start: 2px;
}
.ant-table.ant-table-small {
  font-size: 14px;
}
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 8px 8px;
}
.ant-table.ant-table-small .ant-table-filter-trigger {
  margin-right: -4px;
}
.ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: -8px -8px;
}
.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -8px -8px -8px 40px;
}
.ant-table.ant-table-small .ant-table-selection-column {
  padding-inline-start: 2px;
}
.ant-table.ant-table-bordered > .ant-table-title {
  border: 1px solid #f0f0f0;
  border-bottom: 0;
}
.ant-table.ant-table-bordered > .ant-table-container {
  border-left: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th::before {
  background-color: transparent !important;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -16px -17px;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid #f0f0f0;
  content: '';
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  border-top: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
  border-right: 0;
}
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -12px -9px;
}
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -8px -9px;
}
.ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid #f0f0f0;
  border-top: 0;
}
.ant-table-cell .ant-table-container:first-child {
  border-top: 0;
}
.ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: 0 1px 0 1px #fafafa;
}
.ant-table-wrapper {
  clear: both;
  max-width: 100%;
}
.ant-table-wrapper::before {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-table-wrapper::before {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.ant-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
  overflow: visible;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-table-cell-ellipsis .ant-table-column-title {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-title {
  padding: 16px 16px;
}
.ant-table-footer {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: #fafafa;
}
.ant-table-thead > tr > th {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.06);
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: '';
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
  margin: -16px -16px -16px 32px;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
  border-radius: 0;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #fafafa;
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #fffcf0;
  border-color: rgba(0, 0, 0, 0.03);
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #fffae6;
}
.ant-table-summary {
  position: relative;
  z-index: 2;
  background: #fff;
}
div.ant-table-summary {
  box-shadow: 0 -1px 0 #f0f0f0;
}
.ant-table-summary > tr > th,
.ant-table-summary > tr > td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 0;
}
.ant-table-pagination {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
}
.ant-table-pagination > * {
  flex: none;
}
.ant-table-pagination-left {
  justify-content: flex-start;
}
.ant-table-pagination-center {
  justify-content: center;
}
.ant-table-pagination-right {
  justify-content: flex-end;
}
.ant-table-thead th.ant-table-column-has-sorters {
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead th.ant-table-column-has-sorters:hover::before {
  background-color: transparent !important;
}
.ant-table-thead th.ant-table-column-has-sorters:focus-visible {
  color: #f9ba48;
}
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
  background: #f5f5f5;
}
.ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5;
}
.ant-table-thead th.ant-table-column-sort::before {
  background-color: transparent !important;
}
td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-column-title {
  position: relative;
  z-index: 1;
  flex: 1;
}
.ant-table-column-sorters {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: space-between;
}
.ant-table-column-sorters::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}
.ant-table-column-sorter {
  margin-left: 4px;
  color: #bfbfbf;
  font-size: 0;
  transition: color 0.3s;
}
.ant-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  font-size: 11px;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #f9ba48;
}
.ant-table-column-sorter-up + .ant-table-column-sorter-down {
  margin-top: -0.3em;
}
.ant-table-column-sorters:hover .ant-table-column-sorter {
  color: #a6a6a6;
}
.ant-table-filter-column {
  display: flex;
  justify-content: space-between;
}
.ant-table-filter-trigger {
  position: relative;
  display: flex;
  align-items: center;
  margin: -4px -8px -4px 4px;
  padding: 0 4px;
  color: #bfbfbf;
  font-size: 12px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-filter-trigger:hover {
  color: rgba(0, 0, 0, 0.45);
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-filter-trigger.active {
  color: #f9ba48;
}
.ant-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  min-width: 120px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
}
.ant-table-filter-dropdown .ant-dropdown-menu:empty::after {
  display: block;
  padding: 8px 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  text-align: center;
  content: 'Not Found';
}
.ant-table-filter-dropdown-tree {
  padding: 8px 8px 0;
}
.ant-table-filter-dropdown-tree .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper,
.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper:hover {
  background-color: #fffbed;
}
.ant-table-filter-dropdown-search {
  padding: 8px;
  border-bottom: 1px #f0f0f0 solid;
}
.ant-table-filter-dropdown-search-input input {
  min-width: 140px;
}
.ant-table-filter-dropdown-search-input .anticon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-table-filter-dropdown-checkall {
  width: 100%;
  margin-bottom: 4px;
  margin-left: 4px;
}
.ant-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
.ant-table-filter-dropdown-btns {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid #f0f0f0;
}
.ant-table-selection-col {
  width: 32px;
}
.ant-table-bordered .ant-table-selection-col {
  width: 50px;
}
table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}
table tr th.ant-table-selection-column .ant-radio-wrapper,
table tr td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
table tr th.ant-table-selection-column.ant-table-cell-fix-left {
  z-index: 3;
}
table tr th.ant-table-selection-column::after {
  background-color: transparent !important;
}
.ant-table-selection {
  position: relative;
  display: inline-flex;
  flex-direction: column;
}
.ant-table-selection-extra {
  position: absolute;
  top: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s;
  margin-inline-start: 100%;
  padding-inline-start: 4px;
}
.ant-table-selection-extra .anticon {
  color: #bfbfbf;
  font-size: 10px;
}
.ant-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}
.ant-table-expand-icon-col {
  width: 48px;
}
.ant-table-row-expand-icon-cell {
  text-align: center;
}
.ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  display: inline-flex;
  float: none;
  vertical-align: sub;
}
.ant-table-row-indent {
  float: left;
  height: 1px;
}
.ant-table-row-expand-icon {
  color: #f9ba48;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  float: left;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  transform: scale(0.94117647);
  transition: all 0.3s;
  user-select: none;
}
.ant-table-row-expand-icon:focus-visible,
.ant-table-row-expand-icon:hover {
  color: #ffd373;
}
.ant-table-row-expand-icon:active {
  color: #d49633;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: currentcolor;
}
.ant-table-row-expand-icon::before,
.ant-table-row-expand-icon::after {
  position: absolute;
  background: currentcolor;
  transition: transform 0.3s ease-out;
  content: '';
}
.ant-table-row-expand-icon::before {
  top: 7px;
  right: 3px;
  left: 3px;
  height: 1px;
}
.ant-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 7px;
  width: 1px;
  transform: rotate(90deg);
}
.ant-table-row-expand-icon-collapsed::before {
  transform: rotate(-180deg);
}
.ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.ant-table-row-expand-icon-spaced {
  background: transparent;
  border: 0;
  visibility: hidden;
}
.ant-table-row-expand-icon-spaced::before,
.ant-table-row-expand-icon-spaced::after {
  display: none;
  content: none;
}
.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 2.5005px;
  margin-right: 8px;
}
tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}
tr.ant-table-expanded-row .ant-descriptions-view {
  display: flex;
}
tr.ant-table-expanded-row .ant-descriptions-view table {
  flex: auto;
  width: auto;
}
.ant-table .ant-table-expanded-row-fixed {
  position: relative;
  margin: -16px -16px;
  padding: 16px 16px;
}
.ant-table-tbody > tr.ant-table-placeholder {
  text-align: center;
}
.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #fff;
}
.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  position: sticky !important;
  z-index: 2;
  background: #fff;
}
.ant-table-cell-fix-left-first::after,
.ant-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table-cell-fix-left-all::after {
  display: none;
}
.ant-table-cell-fix-right-first::after,
.ant-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before,
.ant-table .ant-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: calc(calc(2 + 1) + 1);
  width: 30px;
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before {
  left: 0;
}
.ant-table .ant-table-container::after {
  right: 0;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container {
  position: relative;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-left .ant-table-cell-fix-left-last::before {
  background-color: transparent !important;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container {
  position: relative;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-sticky-holder {
  position: sticky;
  z-index: calc(2 + 1);
  background: #fff;
}
.ant-table-sticky-scroll {
  position: sticky;
  bottom: 0;
  z-index: calc(2 + 1);
  display: flex;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #f0f0f0;
  opacity: 0.6;
}
.ant-table-sticky-scroll:hover {
  transform-origin: center bottom;
}
.ant-table-sticky-scroll-bar {
  height: 8px;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.ant-table-sticky-scroll-bar:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.ant-table-sticky-scroll-bar-active {
  background-color: rgba(0, 0, 0, 0.8);
}
@media all and (-ms-high-contrast: none) {
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: none !important;
  }
  .ant-table-ping-right .ant-table-cell-fix-right-first::after {
    box-shadow: none !important;
  }
}
.ant-table {
  /* title + table */
  /* table */
  /* table + footer */
}
.ant-table-title {
  border-radius: 2px 2px 0 0;
}
.ant-table-title + .ant-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-title + .ant-table-container table {
  border-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}
.ant-table-container {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 2px;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 2px;
}
.ant-table-footer {
  border-radius: 0 0 2px 2px;
}
.ant-table-wrapper-rtl {
  direction: rtl;
}
.ant-table-rtl {
  direction: rtl;
}
.ant-table-wrapper-rtl .ant-table table {
  text-align: right;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  right: auto;
  left: 0;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: right;
}
.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
  margin: -16px 33px -16px -16px;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: flex-end;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
  justify-content: flex-start;
}
.ant-table-wrapper-rtl .ant-table-column-sorter {
  margin-right: 4px;
  margin-left: 0;
}
.ant-table-wrapper-rtl .ant-table-filter-column-title {
  padding: 16px 16px 16px 2.3em;
}
.ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 0 0 2.3em;
}
.ant-table-wrapper-rtl .ant-table-filter-trigger {
  margin: -4px 4px -4px -8px;
}
.ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-right: 8px;
  padding-left: 0;
}
.ant-table-wrapper-rtl .ant-table-selection {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-row-indent {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon::after {
  transform: rotate(-90deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::before {
  transform: rotate(180deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
  font-size: 14px;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 16px 0;
  font-size: 16px;
}
.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 16px;
}
.ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 7px 16px 6px;
}
.ant-tabs-rtl {
  direction: rtl;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 32px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-left: 0;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 0;
  margin-left: 12px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
  margin-right: 8px;
  margin-left: -4px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-right: 2px;
  margin-left: 0;
}
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-add {
  margin-right: 2px;
  margin-left: 0;
}
.ant-tabs-dropdown-rtl {
  direction: rtl;
}
.ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
  text-align: right;
}
.ant-tabs-top,
.ant-tabs-bottom {
  flex-direction: column;
}
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 16px 0;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #f0f0f0;
  content: '';
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: width 0.3s, left 0.3s, right 0.3s;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  top: 0;
  bottom: 0;
  width: 30px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  left: 0;
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  bottom: 0;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  order: 1;
  margin-top: 16px;
  margin-bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-content-holder,
.ant-tabs-bottom > div > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-left > .ant-tabs-nav,
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-left > div > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 24px;
  text-align: center;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 16px 0 0 0;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  left: 0;
  height: 30px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  top: 0;
  box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  bottom: 0;
  box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 2px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: height 0.3s, top 0.3s;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
  flex: 1 0 auto;
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
  right: 0;
}
.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
  margin-left: -1px;
  border-left: 1px solid #f0f0f0;
}
.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 24px;
}
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
}
.ant-tabs-right > .ant-tabs-content-holder,
.ant-tabs-right > div > .ant-tabs-content-holder {
  order: 0;
  margin-right: -1px;
  border-right: 1px solid #f0f0f0;
}
.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-right: 24px;
}
.ant-tabs-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-tabs-dropdown-hidden {
  display: none;
}
.ant-tabs-dropdown-menu {
  max-height: 200px;
  margin: 0;
  padding: 4px 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tabs-dropdown-menu-item {
  display: flex;
  align-items: center;
  min-width: 120px;
  margin: 0;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-dropdown-menu-item > span {
  flex: 1;
  white-space: nowrap;
}
.ant-tabs-dropdown-menu-item-remove {
  flex: none;
  margin-left: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: 0;
  cursor: pointer;
}
.ant-tabs-dropdown-menu-item-remove:hover {
  color: #ffd373;
}
.ant-tabs-dropdown-menu-item:hover {
  background: #f5f5f5;
}
.ant-tabs-dropdown-menu-item-disabled,
.ant-tabs-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #f9ba48;
  background: #fff;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
  visibility: hidden;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 2px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 2px 2px 0 0;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #fff;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0 2px 2px;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: #fff;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-top: 2px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 2px 0 0 2px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-right-color: #fff;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 2px 2px 0;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-left-color: #fff;
}
.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: flex;
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: transform 0.3s;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
  display: flex;
  align-self: stretch;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  position: relative;
  padding: 8px 16px;
  background: transparent;
  border: 0;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  transform: translateY(100%);
  content: '';
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
  min-width: 40px;
  margin-left: 2px;
  padding: 0 8px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  outline: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: #ffd373;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
  color: #d49633;
}
.ant-tabs-extra-content {
  flex: none;
}
.ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']),
.ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']) {
  justify-content: center;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: #f9ba48;
  pointer-events: none;
}
.ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 12px 0;
  font-size: 14px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #d49633;
}
.ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
}
.ant-tabs-tab-remove {
  flex: none;
  margin-right: -4px;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-tab-remove:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tabs-tab:hover {
  color: #ffd373;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f9ba48;
  text-shadow: 0 0 0.25px currentcolor;
}
.ant-tabs-tab.ant-tabs-tab-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}
.ant-tabs-tab .anticon {
  margin-right: 12px;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 32px;
}
.ant-tabs-content {
  position: relative;
  width: 100%;
}
.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}
.ant-tabs-tabpane {
  outline: none;
}
.ant-tabs-tabpane-hidden {
  display: none;
}
.ant-tabs-switch-appear,
.ant-tabs-switch-enter {
  transition: none;
}
.ant-tabs-switch-appear-start,
.ant-tabs-switch-enter-start {
  opacity: 0;
}
.ant-tabs-switch-appear-active,
.ant-tabs-switch-enter-active {
  opacity: 1;
  transition: opacity 0.3s;
}
.ant-tabs-switch-leave {
  position: absolute;
  transition: none;
  inset: 0;
}
.ant-tabs-switch-leave-start {
  opacity: 1;
}
.ant-tabs-switch-leave-active {
  opacity: 0;
  transition: opacity 0.3s;
}
.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
  transition: all 0.3s;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag-close-icon {
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tag-close-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #f9ba48;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #f9ba48;
}
.ant-tag-checkable:active {
  background-color: #d49633;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag-pink {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-magenta {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
.ant-tag-volcano {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-tag-orange {
  color: #d46b08;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.ant-tag-yellow {
  color: #d4b106;
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.ant-tag-gold {
  color: #d48806;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.ant-tag-cyan {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-lime {
  color: #7cb305;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.ant-tag-blue {
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-tag-geekblue {
  color: #1d39c4;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.ant-tag-purple {
  color: #531dab;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}
.ant-tag-success {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-processing {
  color: #f9ba48;
  background: #fffcf0;
  border-color: #fff0c4;
}
.ant-tag-error {
  color: #ff4d4f;
  background: #fff2f0;
  border-color: #ffccc7;
}
.ant-tag-warning {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag > .anticon + span,
.ant-tag > span + .anticon {
  margin-left: 7px;
}
.ant-tag.ant-tag-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
  text-align: right;
}
.ant-tag-rtl .ant-tag-close-icon {
  margin-right: 3px;
  margin-left: 0;
}
.ant-tag-rtl.ant-tag > .anticon + span,
.ant-tag-rtl.ant-tag > span + .anticon {
  margin-right: 7px;
  margin-left: 0;
}
.ant-timeline {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-timeline-item {
  position: relative;
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  list-style: none;
}
.ant-timeline-item-tail {
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% - 10px);
  border-left: 2px solid #f0f0f0;
}
.ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 12px;
  background-color: transparent;
}
.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
}
.ant-timeline-item-head-blue {
  color: #f9ba48;
  border-color: #f9ba48;
}
.ant-timeline-item-head-red {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-timeline-item-head-green {
  color: #52c41a;
  border-color: #52c41a;
}
.ant-timeline-item-head-gray {
  color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  transform: translate(-50%, -50%);
}
.ant-timeline-item-content {
  position: relative;
  top: -7.001px;
  margin: 0 0 0 26px;
  word-break: break-word;
}
.ant-timeline-item-last > .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  left: 50%;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-left: -4px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-left: 1px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(50% - 4px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(50% - 12px);
  margin: 0;
  text-align: right;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: calc(100% - 4px - 2px);
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(100% - 18px);
}
.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  display: block;
  height: calc(100% - 14px);
  border-left: 2px dotted #f0f0f0;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  top: 15px;
  display: block;
  height: calc(100% - 15px);
  border-left: 2px dotted #f0f0f0;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  position: absolute;
  top: -7.001px;
  width: calc(50% - 12px);
  text-align: right;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  left: calc(50% + 14px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline-rtl {
  direction: rtl;
}
.ant-timeline-rtl .ant-timeline-item-tail {
  right: 4px;
  left: auto;
  border-right: 2px solid #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl .ant-timeline-item-head-custom {
  right: 5px;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-timeline-rtl .ant-timeline-item-content {
  margin: 0 18px 0 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  right: 50%;
  left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-right: -4px;
  margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-right: 1px;
  margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  right: calc(50% - 4px);
  left: auto;
  text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  right: 0;
  left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: 100%;
  margin-right: 18px;
  text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  right: calc(50% + 14px);
  text-align: right;
}
.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1070;
  display: block;
  width: max-content;
  width: intrinsic;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-content {
  position: relative;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 14.3137085px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 14.3137085px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 14.3137085px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 14.3137085px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tooltip-arrow {
  position: absolute;
  z-index: 2;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(to right bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.75));
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-tooltip-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: 0;
  transform: translateX(-100%);
}
.ant-tooltip-placement-right .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: 0;
  transform: translateX(100%);
}
.ant-tooltip-placement-left .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(315deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: 0;
  transform: translateY(-100%);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(11px) rotate(225deg);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-pink .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-pink .ant-tooltip-arrow-content::before {
  background: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-arrow-content::before {
  background: #eb2f96;
}
.ant-tooltip-red .ant-tooltip-inner {
  background-color: #f5222d;
}
.ant-tooltip-red .ant-tooltip-arrow-content::before {
  background: #f5222d;
}
.ant-tooltip-volcano .ant-tooltip-inner {
  background-color: #fa541c;
}
.ant-tooltip-volcano .ant-tooltip-arrow-content::before {
  background: #fa541c;
}
.ant-tooltip-orange .ant-tooltip-inner {
  background-color: #fa8c16;
}
.ant-tooltip-orange .ant-tooltip-arrow-content::before {
  background: #fa8c16;
}
.ant-tooltip-yellow .ant-tooltip-inner {
  background-color: #fadb14;
}
.ant-tooltip-yellow .ant-tooltip-arrow-content::before {
  background: #fadb14;
}
.ant-tooltip-gold .ant-tooltip-inner {
  background-color: #faad14;
}
.ant-tooltip-gold .ant-tooltip-arrow-content::before {
  background: #faad14;
}
.ant-tooltip-cyan .ant-tooltip-inner {
  background-color: #13c2c2;
}
.ant-tooltip-cyan .ant-tooltip-arrow-content::before {
  background: #13c2c2;
}
.ant-tooltip-lime .ant-tooltip-inner {
  background-color: #a0d911;
}
.ant-tooltip-lime .ant-tooltip-arrow-content::before {
  background: #a0d911;
}
.ant-tooltip-green .ant-tooltip-inner {
  background-color: #52c41a;
}
.ant-tooltip-green .ant-tooltip-arrow-content::before {
  background: #52c41a;
}
.ant-tooltip-blue .ant-tooltip-inner {
  background-color: #1890ff;
}
.ant-tooltip-blue .ant-tooltip-arrow-content::before {
  background: #1890ff;
}
.ant-tooltip-geekblue .ant-tooltip-inner {
  background-color: #2f54eb;
}
.ant-tooltip-geekblue .ant-tooltip-arrow-content::before {
  background: #2f54eb;
}
.ant-tooltip-purple .ant-tooltip-inner {
  background-color: #722ed1;
}
.ant-tooltip-purple .ant-tooltip-arrow-content::before {
  background: #722ed1;
}
.ant-tooltip-rtl {
  direction: rtl;
}
.ant-tooltip-rtl .ant-tooltip-inner {
  text-align: right;
}
.ant-transfer-customize-list .ant-transfer-list {
  flex: 1 1 50%;
  width: auto;
  height: auto;
  min-height: 200px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-selection-column {
  width: 40px;
  min-width: 40px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #fafafa;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}
.ant-transfer-customize-list .ant-input[disabled] {
  background-color: transparent;
}
.ant-transfer-status-error .ant-transfer-list {
  border-color: #ff4d4f;
}
.ant-transfer-status-error .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}
.ant-transfer-status-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #ffd373;
  border-right-width: 1px;
}
.ant-input-rtl .ant-transfer-status-error .ant-transfer-list-search:not([disabled]):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer-status-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #ffd373;
  box-shadow: 0 0 0 2px rgba(249, 186, 72, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-transfer-status-error .ant-transfer-list-search:not([disabled]):focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer-status-warning .ant-transfer-list {
  border-color: #faad14;
}
.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}
.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):hover {
  border-color: #ffd373;
  border-right-width: 1px;
}
.ant-input-rtl .ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):focus {
  border-color: #ffd373;
  box-shadow: 0 0 0 2px rgba(249, 186, 72, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: flex;
  align-items: stretch;
}
.ant-transfer-disabled .ant-transfer-list {
  background: #d8d8d8;
}
.ant-transfer-list {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-transfer-list-with-pagination {
  width: 250px;
  height: auto;
}
.ant-transfer-list-search .anticon-search {
  color: rgba(0, 0, 0, 0.25);
}
.ant-transfer-list-header {
  display: flex;
  flex: none;
  align-items: center;
  height: 40px;
  padding: 8px 12px 9px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-transfer-list-header > *:not(:last-child) {
  margin-right: 4px;
}
.ant-transfer-list-header > * {
  flex: none;
}
.ant-transfer-list-header-title {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
}
.ant-transfer-list-header-dropdown {
  font-size: 10px;
  transform: translateY(10%);
  cursor: pointer;
}
.ant-transfer-list-header-dropdown[disabled] {
  cursor: not-allowed;
}
.ant-transfer-list-body {
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
}
.ant-transfer-list-body-search-wrapper {
  position: relative;
  flex: none;
  padding: 12px;
}
.ant-transfer-list-content {
  flex: auto;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
.ant-transfer-list-content-item {
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 6px 12px;
  line-height: 20px;
  transition: all 0.3s;
}
.ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 8px;
}
.ant-transfer-list-content-item > * {
  flex: none;
}
.ant-transfer-list-content-item-text {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-transfer-list-content-item-remove {
  position: relative;
  color: #d9d9d9;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-transfer-list-content-item-remove:hover {
  color: #ffd373;
}
.ant-transfer-list-content-item-remove::after {
  position: absolute;
  top: -6px;
  right: -50%;
  bottom: -6px;
  left: -50%;
  content: '';
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
  background-color: #fffae6;
}
.ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background: transparent;
  cursor: default;
}
.ant-transfer-list-content-item-checked {
  background-color: #fffcf0;
}
.ant-transfer-list-content-item-disabled {
  color: #ffffff;
  cursor: not-allowed;
}
.ant-transfer-list-pagination {
  padding: 8px 0;
  text-align: right;
  border-top: 1px solid #f0f0f0;
}
.ant-transfer-list-body-not-found {
  flex: none;
  width: 100%;
  margin: auto 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
}
.ant-transfer-list-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-transfer-operation {
  display: flex;
  flex: none;
  flex-direction: column;
  align-self: center;
  margin: 0 8px;
  vertical-align: middle;
}
.ant-transfer-operation .ant-btn {
  display: block;
}
.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
.ant-transfer .ant-empty-image {
  max-height: -2px;
}
.ant-transfer-rtl {
  direction: rtl;
}
.ant-transfer-rtl .ant-transfer-list-search {
  padding-right: 8px;
  padding-left: 24px;
}
.ant-transfer-rtl .ant-transfer-list-search-action {
  right: auto;
  left: 12px;
}
.ant-transfer-rtl .ant-transfer-list-header > *:not(:last-child) {
  margin-right: 0;
  margin-left: 4px;
}
.ant-transfer-rtl .ant-transfer-list-header {
  right: 0;
  left: auto;
}
.ant-transfer-rtl .ant-transfer-list-header-title {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-transfer-rtl .ant-transfer-list-pagination {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-footer {
  right: 0;
  left: auto;
}
@keyframes ant-tree-node-fx-do-not-use {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-tree.ant-tree-directory .ant-tree-treenode {
  position: relative;
}
.ant-tree.ant-tree-directory .ant-tree-treenode::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  transition: background-color 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
  background: #f5f5f5;
}
.ant-tree.ant-tree-directory .ant-tree-treenode > * {
  z-index: 1;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
  transition: color 0.3s;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
  border-radius: 0;
  user-select: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: #f9ba48;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
  color: #fff;
  background: transparent;
}
.ant-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #f9ba48;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #f9ba48;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #f9ba48;
  border-color: #f9ba48;
}
.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #d8d8d8;
  border-color: #d9d9d9 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #d8d8d8;
  border-collapse: separate;
  animation-name: none;
}
.ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-tree-checkbox-group-item {
  margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #f9ba48;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree-checkbox-rtl {
  direction: rtl;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 8px;
}
.ant-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
  background: #fffcf0;
}
.ant-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  flex: auto;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging {
  position: relative;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  border: 1px solid #f9ba48;
  opacity: 0;
  animation: ant-tree-node-fx-do-not-use 0.3s;
  animation-play-state: running;
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
.ant-tree .ant-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
  color: inherit;
  font-weight: 500;
}
.ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
  width: 24px;
  line-height: 24px;
  text-align: center;
  visibility: visible;
  opacity: 0.2;
  transition: opacity 0.3s;
}
.ant-tree-treenode:hover .ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
  opacity: 0.45;
}
.ant-tree .ant-tree-treenode-draggable.ant-tree-treenode-disabled .ant-tree-draggable-icon {
  visibility: hidden;
}
.ant-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  user-select: none;
}
.ant-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-tree-draggable-icon {
  visibility: hidden;
}
.ant-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.ant-tree-switcher .ant-tree-switcher-icon,
.ant-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-tree-switcher .ant-tree-switcher-icon svg,
.ant-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-tree-switcher-loading-icon {
  color: #f9ba48;
}
.ant-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  margin-left: -1px;
  border-right: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-tree .ant-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #fffbed;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
  display: none;
}
.ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-tree-node-content-wrapper {
  line-height: 24px;
  user-select: none;
}
.ant-tree-node-content-wrapper .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #f9ba48;
  border-radius: 1px;
  pointer-events: none;
}
.ant-tree-node-content-wrapper .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #f9ba48;
  border-radius: 50%;
  content: '';
}
.ant-tree .ant-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #f9ba48;
}
.ant-tree-show-line .ant-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-tree-show-line .ant-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}
.ant-tree-show-line .ant-tree-indent-unit-end::before {
  display: none;
}
.ant-tree-show-line .ant-tree-switcher {
  background: #fff;
}
.ant-tree-show-line .ant-tree-switcher-line-icon {
  vertical-align: -0.15em;
}
.ant-tree .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.ant-tree-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  right: -6px;
  left: unset;
}
.ant-tree .ant-tree-treenode-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
  right: auto;
  left: -13px;
  border-right: none;
  border-left: 1px solid #d9d9d9;
}
.ant-tree-rtl .ant-tree-checkbox {
  margin: 4px 0 0 8px;
}
.ant-tree-select-dropdown-rtl .ant-select-tree-checkbox {
  margin: 4px 0 0 8px;
}
.ant-select-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #f9ba48;
}
.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #f9ba48;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible;
}
.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #f9ba48;
  border-color: #f9ba48;
}
.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #d8d8d8;
  border-color: #d9d9d9 !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #d8d8d8;
  border-collapse: separate;
  animation-name: none;
}
.ant-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-select-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-select-tree-checkbox-group-item {
  margin-right: 8px;
}
.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #f9ba48;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-select-tree-checkbox-rtl {
  direction: rtl;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 8px;
}
.ant-tree-select-dropdown {
  padding: 8px 4px;
}
.ant-tree-select-dropdown-rtl {
  direction: rtl;
}
.ant-tree-select-dropdown .ant-select-tree {
  border-radius: 0;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode .ant-select-tree-node-content-wrapper {
  flex: auto;
}
.ant-select-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
  background: #fffcf0;
}
.ant-select-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
  align-items: stretch;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-node-content-wrapper {
  flex: auto;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-treenode.dragging {
  position: relative;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-treenode.dragging::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  border: 1px solid #f9ba48;
  opacity: 0;
  animation: ant-tree-node-fx-do-not-use 0.3s;
  animation-play-state: running;
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
.ant-select-tree .ant-select-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-select-tree .ant-select-tree-treenode:not(.ant-select-tree .ant-select-tree-treenode-disabled).filter-node .ant-select-tree-title {
  color: inherit;
  font-weight: 500;
}
.ant-select-tree .ant-select-tree-treenode-draggable .ant-select-tree-draggable-icon {
  width: 24px;
  line-height: 24px;
  text-align: center;
  visibility: visible;
  opacity: 0.2;
  transition: opacity 0.3s;
}
.ant-select-tree-treenode:hover .ant-select-tree .ant-select-tree-treenode-draggable .ant-select-tree-draggable-icon {
  opacity: 0.45;
}
.ant-select-tree .ant-select-tree-treenode-draggable.ant-select-tree-treenode-disabled .ant-select-tree-draggable-icon {
  visibility: hidden;
}
.ant-select-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  user-select: none;
}
.ant-select-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-select-tree-draggable-icon {
  visibility: hidden;
}
.ant-select-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.ant-select-tree-switcher .ant-tree-switcher-icon,
.ant-select-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-select-tree-switcher .ant-tree-switcher-icon svg,
.ant-select-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-select-tree-switcher-noop {
  cursor: default;
}
.ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-select-tree-switcher-loading-icon {
  color: #f9ba48;
}
.ant-select-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-select-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  margin-left: -1px;
  border-right: 1px solid #d9d9d9;
  content: ' ';
}
.ant-select-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}
.ant-select-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-select-tree .ant-select-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #fffbed;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle:empty {
  display: none;
}
.ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-select-tree-node-content-wrapper {
  line-height: 24px;
  user-select: none;
}
.ant-select-tree-node-content-wrapper .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #f9ba48;
  border-radius: 1px;
  pointer-events: none;
}
.ant-select-tree-node-content-wrapper .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #f9ba48;
  border-radius: 50%;
  content: '';
}
.ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #f9ba48;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}
.ant-select-tree-show-line .ant-select-tree-indent-unit-end::before {
  display: none;
}
.ant-select-tree-show-line .ant-select-tree-switcher {
  background: #fff;
}
.ant-select-tree-show-line .ant-select-tree-switcher-line-icon {
  vertical-align: -0.15em;
}
.ant-select-tree .ant-select-tree-treenode-leaf-last .ant-select-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher-loading-icon {
  transform: scaleY(-1);
}
.ant-typography {
  color: rgba(0, 0, 0, 0.85);
  word-break: break-word;
}
.ant-typography.ant-typography-secondary {
  color: rgba(0, 0, 0, 0.45);
}
.ant-typography.ant-typography-success {
  color: #52c41a;
}
.ant-typography.ant-typography-warning {
  color: #faad14;
}
.ant-typography.ant-typography-danger {
  color: #ff4d4f;
}
a.ant-typography.ant-typography-danger:active,
a.ant-typography.ant-typography-danger:focus {
  color: #d9363e;
}
a.ant-typography.ant-typography-danger:hover {
  color: #ff7875;
}
.ant-typography.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  user-select: none;
}
div.ant-typography,
.ant-typography p {
  margin-bottom: 1em;
}
h1.ant-typography,
div.ant-typography-h1,
div.ant-typography-h1 > textarea,
.ant-typography h1 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}
h2.ant-typography,
div.ant-typography-h2,
div.ant-typography-h2 > textarea,
.ant-typography h2 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
h3.ant-typography,
div.ant-typography-h3,
div.ant-typography-h3 > textarea,
.ant-typography h3 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}
h4.ant-typography,
div.ant-typography-h4,
div.ant-typography-h4 > textarea,
.ant-typography h4 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
h5.ant-typography,
div.ant-typography-h5,
div.ant-typography-h5 > textarea,
.ant-typography h5 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography,
.ant-typography + h5.ant-typography {
  margin-top: 1.2em;
}
.ant-typography div + h1,
.ant-typography ul + h1,
.ant-typography li + h1,
.ant-typography p + h1,
.ant-typography h1 + h1,
.ant-typography h2 + h1,
.ant-typography h3 + h1,
.ant-typography h4 + h1,
.ant-typography h5 + h1,
.ant-typography div + h2,
.ant-typography ul + h2,
.ant-typography li + h2,
.ant-typography p + h2,
.ant-typography h1 + h2,
.ant-typography h2 + h2,
.ant-typography h3 + h2,
.ant-typography h4 + h2,
.ant-typography h5 + h2,
.ant-typography div + h3,
.ant-typography ul + h3,
.ant-typography li + h3,
.ant-typography p + h3,
.ant-typography h1 + h3,
.ant-typography h2 + h3,
.ant-typography h3 + h3,
.ant-typography h4 + h3,
.ant-typography h5 + h3,
.ant-typography div + h4,
.ant-typography ul + h4,
.ant-typography li + h4,
.ant-typography p + h4,
.ant-typography h1 + h4,
.ant-typography h2 + h4,
.ant-typography h3 + h4,
.ant-typography h4 + h4,
.ant-typography h5 + h4,
.ant-typography div + h5,
.ant-typography ul + h5,
.ant-typography li + h5,
.ant-typography p + h5,
.ant-typography h1 + h5,
.ant-typography h2 + h5,
.ant-typography h3 + h5,
.ant-typography h4 + h5,
.ant-typography h5 + h5 {
  margin-top: 1.2em;
}
a.ant-typography-ellipsis,
span.ant-typography-ellipsis {
  display: inline-block;
  max-width: 100%;
}
a.ant-typography,
.ant-typography a {
  color: #f9ba48;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
}
a.ant-typography:focus-visible,
.ant-typography a:focus-visible,
a.ant-typography:hover,
.ant-typography a:hover {
  color: #ffd373;
}
a.ant-typography:active,
.ant-typography a:active {
  color: #d49633;
}
a.ant-typography:active,
.ant-typography a:active,
a.ant-typography:hover,
.ant-typography a:hover {
  text-decoration: none;
}
a.ant-typography[disabled],
.ant-typography a[disabled],
a.ant-typography.ant-typography-disabled,
.ant-typography a.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active,
a.ant-typography[disabled]:hover,
.ant-typography a[disabled]:hover,
a.ant-typography.ant-typography-disabled:hover,
.ant-typography a.ant-typography-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}
a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active {
  pointer-events: none;
}
.ant-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.ant-typography kbd {
  margin: 0 0.2em;
  padding: 0.15em 0.4em 0.1em;
  font-size: 90%;
  background: rgba(150, 150, 150, 0.06);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-width: 2px;
  border-radius: 3px;
}
.ant-typography mark {
  padding: 0;
  background-color: #ffe58f;
}
.ant-typography u,
.ant-typography ins {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
.ant-typography s,
.ant-typography del {
  text-decoration: line-through;
}
.ant-typography strong {
  font-weight: 600;
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: #f9ba48;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 4px;
}
.ant-typography-expand:focus-visible,
.ant-typography-edit:focus-visible,
.ant-typography-copy:focus-visible,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color: #ffd373;
}
.ant-typography-expand:active,
.ant-typography-edit:active,
.ant-typography-copy:active {
  color: #d49633;
}
.ant-typography-copy-success,
.ant-typography-copy-success:hover,
.ant-typography-copy-success:focus {
  color: #52c41a;
}
.ant-typography-edit-content {
  position: relative;
}
div.ant-typography-edit-content {
  left: -12px;
  margin-top: -5px;
  margin-bottom: calc(1em - 4px - 1px);
}
.ant-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
  font-style: normal;
  pointer-events: none;
}
.ant-typography-edit-content textarea {
  height: 1em;
  margin: 0 !important;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-transition: none;
}
.ant-typography ul,
.ant-typography ol {
  margin: 0 0 1em;
  padding: 0;
}
.ant-typography ul li,
.ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}
.ant-typography ul {
  list-style-type: circle;
}
.ant-typography ul ul {
  list-style-type: disc;
}
.ant-typography ol {
  list-style-type: decimal;
}
.ant-typography pre,
.ant-typography blockquote {
  margin: 1em 0;
}
.ant-typography pre {
  padding: 0.4em 0.6em;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.ant-typography pre code {
  display: inline;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  background: transparent;
  border: 0;
}
.ant-typography blockquote {
  padding: 0 0 0 0.6em;
  border-left: 4px solid rgba(100, 100, 100, 0.2);
  opacity: 0.85;
}
.ant-typography-single-line {
  white-space: nowrap;
}
.ant-typography-ellipsis-single-line {
  overflow: hidden;
  text-overflow: ellipsis;
}
a.ant-typography-ellipsis-single-line,
span.ant-typography-ellipsis-single-line {
  vertical-align: bottom;
}
.ant-typography-ellipsis-multiple-line {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.ant-typography-rtl {
  direction: rtl;
}
.ant-typography-rtl .ant-typography-expand,
.ant-typography-rtl .ant-typography-edit,
.ant-typography-rtl .ant-typography-copy {
  margin-right: 4px;
  margin-left: 0;
}
.ant-typography-rtl .ant-typography-expand {
  float: left;
}
div.ant-typography-edit-content.ant-typography-rtl {
  right: -12px;
  left: auto;
}
.ant-typography-rtl .ant-typography-edit-content-confirm {
  right: auto;
  left: 10px;
}
.ant-typography-rtl.ant-typography ul li,
.ant-typography-rtl.ant-typography ol li {
  margin: 0 20px 0 0;
  padding: 0 4px 0 0;
}
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.ant-upload input[type='file'] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #f9ba48;
}
.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #d9d9d9;
}
.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #d49633;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #ffd373;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #ffd373;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-picture-card-wrapper {
  display: inline-block;
  width: 100%;
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  line-height: 1.5715;
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list-item {
  position: relative;
  height: 22.001px;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}
.ant-upload-list-item-card-actions-btn {
  opacity: 0;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 22.001px;
  line-height: 1;
  vertical-align: top;
}
.ant-upload-list-item-card-actions.picture {
  top: 22px;
  line-height: 0;
}
.ant-upload-list-item-card-actions-btn:focus,
.ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-card-actions .anticon {
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-upload-list-item-card-actions:hover .anticon {
  color: rgba(0, 0, 0, 0.85);
}
.ant-upload-list-item-info {
  height: 100%;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-upload-list-item-info .anticon-loading .anticon,
.ant-upload-list-item-info .ant-upload-text-icon .anticon {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f5f5f5;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #ff4d4f;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #ff4d4f;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ff4d4f;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  line-height: 60px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
  fill: #fff2f0;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
  fill: #ff4d4f;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-icon .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  margin-bottom: 12px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin: 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5715;
  text-align: center;
}
.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  width: calc(100% - 14px);
  padding-left: 0;
}
.ant-upload-list-text-container,
.ant-upload-list-picture-container {
  transition: opacity 0.3s, height 0.3s;
}
.ant-upload-list-text-container::before,
.ant-upload-list-picture-container::before {
  display: table;
  width: 0;
  height: 0;
  content: '';
}
.ant-upload-list-text-container .ant-upload-span,
.ant-upload-list-picture-container .ant-upload-span {
  display: block;
  flex: auto;
}
.ant-upload-list-text .ant-upload-span,
.ant-upload-list-picture .ant-upload-span {
  display: flex;
  align-items: center;
}
.ant-upload-list-text .ant-upload-span > *,
.ant-upload-list-picture .ant-upload-span > * {
  flex: none;
}
.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  flex: auto;
  margin: 0;
  padding: 0 8px;
}
.ant-upload-list-text .ant-upload-list-item-card-actions,
.ant-upload-list-picture .ant-upload-list-item-card-actions {
  position: static;
}
.ant-upload-list-text .ant-upload-text-icon .anticon {
  position: static;
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: forwards;
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter {
  animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-name: uploadAnimateInlineOut;
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-upload-rtl {
  direction: rtl;
}
.ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
  margin-right: auto;
  margin-left: 8px;
}
.ant-upload-list-rtl {
  direction: rtl;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 22px;
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 22px;
  padding-left: 28px;
}
.ant-upload-list-rtl .ant-upload-list-item-name {
  padding-right: 22px;
  padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions {
  right: auto;
  left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-info {
  padding: 0 4px 0 12px;
}
.ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-progress {
  padding-right: 26px;
  padding-left: 0;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  right: 8px;
  left: auto;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-right: 48px;
  padding-left: 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 48px;
  padding-left: 18px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 48px;
  padding-left: 36px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 0;
  padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-picture-card-container {
  margin: 0 0 8px 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
}
/* Form */
.ant-form-item-label > label::after {
  content: "";
}
/* Card */
.ant-card {
  border-radius: 4px;
  margin-bottom: 20px;
}
.ant-card-body {
  padding: 10px;
}
@media (min-width: 576px) {
  .ant-card-body {
    padding: 15px;
  }
}
@media (min-width: 768px) {
  .ant-card-body {
    padding: 20px;
  }
}
.ant-card-bordered {
  border: none;
}
.ant-card-head::after {
  content: " ";
  background: #f9ba48;
  height: 3px;
  width: 72px;
}
/* PageHeader */
.ant-page-header-heading-sub-title {
  font-size: 12px;
}
.ant-btn-link .anticon {
  vertical-align: middle;
}
.ant-btn {
  font-size: 16px;
}
.ant-btn-sm {
  font-size: 12px;
}
.ant-btn.active {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.ant-menu {
  font-size: 16px;
}
.ant-alert-message {
  color: #4a4a4a;
}
.ant-alert-warning {
  border: none;
}
.ant-back-top-content,
.ant-back-top-content:hover {
  background-color: #f9ba48;
}
.ant-dropdown-menu-item-active:hover {
  background: rgba(255, 211, 115, 0.1);
}
.ant-drawer-content-wrapper {
  width: 256px !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 15px 20px !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 6px;
}
.ant-collapse {
  border: none !important;
  background-color: white !important;
}
.ant-collapse > .ant-collapse-item {
  border: none !important;
  background-color: #fafafa !important;
  margin: 5px 0;
}
.ant-collapse-content {
  border: none !important;
}
.ant-upload.ant-upload-drag {
  margin-bottom: 10px;
  border-width: 3px;
  border-style: dashed;
}
/**
 * Allows you to use retina images at various pixel densities.
 * Examples:
 *
 *   .retina(/images/mypic.jpg, 2);
 *   .retina(/images/mypic.jpg, 3, 100px 100px, left top no-repeat transparent);
 *
 * @param  {String} $path               The path to the file name minus extension.
 * @param  {Number} $cap:    2          The highest pixel density level images exist for.
 * @param  {Value}  $size:   auto auto  The intended width of the rendered image.
 * @param  {Value}  $extras: null       Any other `background` values to be added.
 */
body {
  margin: 0;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFangSC;
  font-weight: 400;
  color: #4a4a4a;
  background: #f7f7f7;
}
.img-gray {
  filter: grayscale(100%);
}
.float-clear {
  clear: both;
  content: " ";
}
.btn-primary:hover {
  background: #ffd373;
}
.btn-primary:active {
  background: #d49633;
}
.btn-primary,
.btn-primary[disabled] {
  background: #f9ba48;
}
.btn[disabled] {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  box-shadow: none;
}
.btn-block {
  display: block;
  width: 100%;
}
.hidden {
  display: none;
}
/* Text size */
.text-lg {
  font-size: 18px;
}
.text-md {
  font-size: 16px;
}
.text-sm {
  font-size: 12px;
}
/* Alignments */
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.text-break {
  word-break: break-all;
}
.text-middle {
  vertical-align: middle;
}
.text-bottom {
  vertical-align: baseline;
}
/* Text modification */
.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}
/* Text Colors */
.text-primary {
  color: #f9ba48;
}
a:not(.ant-btn).text-primary:link {
  color: #f9ba48;
}
a:not(.ant-btn).text-primary:visited {
  color: #f9ba48;
}
a:not(.ant-btn).text-primary:hover {
  color: #f9ba48;
}
a:not(.ant-btn).text-primary:active,
.text-primary.active {
  color: #f9ba48;
}
.text-secondary {
  color: #9b9b9b;
}
a.text-secondary:link {
  color: #9b9b9b;
}
a.text-secondary:visited {
  color: #9b9b9b;
}
a.text-secondary:hover {
  color: #9b9b9b;
}
a.text-secondary:active,
.text-secondary.active {
  color: #9b9b9b;
}
.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.mr-0 {
  margin-right: 0px;
}
.ml-0 {
  margin-left: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.gap-0 {
  gap: 0px;
}
.my-2 {
  margin-top: 2px;
  margin-bottom: 2px;
}
.mx-2 {
  margin-left: 2px;
  margin-right: 2px;
}
.px-2 {
  padding-left: 2px;
  padding-right: 2px;
}
.py-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}
.mr-2 {
  margin-right: 2px;
}
.ml-2 {
  margin-left: 2px;
}
.mt-2 {
  margin-top: 2px;
}
.mb-2 {
  margin-bottom: 2px;
}
.gap-2 {
  gap: 2px;
}
.my-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.mx-4 {
  margin-left: 4px;
  margin-right: 4px;
}
.px-4 {
  padding-left: 4px;
  padding-right: 4px;
}
.py-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.mr-4 {
  margin-right: 4px;
}
.ml-4 {
  margin-left: 4px;
}
.mt-4 {
  margin-top: 4px;
}
.mb-4 {
  margin-bottom: 4px;
}
.gap-4 {
  gap: 4px;
}
.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}
.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.ml-5 {
  margin-left: 5px;
}
.mt-5 {
  margin-top: 5px;
}
.mb-5 {
  margin-bottom: 5px;
}
.gap-5 {
  gap: 5px;
}
.my-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.mx-8 {
  margin-left: 8px;
  margin-right: 8px;
}
.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.mt-8 {
  margin-top: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.gap-8 {
  gap: 8px;
}
.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.gap-10 {
  gap: 10px;
}
.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.mx-15 {
  margin-left: 15px;
  margin-right: 15px;
}
.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.mr-15 {
  margin-right: 15px;
}
.ml-15 {
  margin-left: 15px;
}
.mt-15 {
  margin-top: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}
.gap-15 {
  gap: 15px;
}
.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}
.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.mr-20 {
  margin-right: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.gap-20 {
  gap: 20px;
}
.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.mx-30 {
  margin-left: 30px;
  margin-right: 30px;
}
.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.mr-30 {
  margin-right: 30px;
}
.ml-30 {
  margin-left: 30px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-30 {
  margin-bottom: 30px;
}
.gap-30 {
  gap: 30px;
}
.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.mx-50 {
  margin-left: 50px;
  margin-right: 50px;
}
.px-50 {
  padding-left: 50px;
  padding-right: 50px;
}
.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.mr-50 {
  margin-right: 50px;
}
.ml-50 {
  margin-left: 50px;
}
.mt-50 {
  margin-top: 50px;
}
.mb-50 {
  margin-bottom: 50px;
}
.gap-50 {
  gap: 50px;
}
.my-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}
.mx-100 {
  margin-left: 100px;
  margin-right: 100px;
}
.px-100 {
  padding-left: 100px;
  padding-right: 100px;
}
.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.mr-100 {
  margin-right: 100px;
}
.ml-100 {
  margin-left: 100px;
}
.mt-100 {
  margin-top: 100px;
}
.mb-100 {
  margin-bottom: 100px;
}
.gap-100 {
  gap: 100px;
}
/* Float */
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
/* Position */
.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}
/* Size */
.height-auto {
  height: auto;
}
/* Debug */
pre.debug {
  border: 1px solid #eee;
  background: #efefef;
  padding: 10px;
  max-height: 300px;
}
/* Links */
a:not(.ant-btn):link {
  color: #4a4a4a;
}
a:not(.ant-btn):visited {
  color: #7b7b7b;
}
a:not(.ant-btn):hover {
  color: #fbb42f;
}
a:not(.ant-btn):active,
a:not(.ant-btn).active {
  color: #d49633;
}
/* Forms */
.form-actions {
  display: flex;
}
.form-actions button {
  margin-right: 20px;
}
/* Menus */
.navs-post .ant-menu {
  font-size: 20px;
}
.modal-body .ant-menu {
  text-align: center;
  font-size: 18px;
  margin-bottom: 15px;
}
/* Sidebar */
.ant-layout-content {
  min-width: 320px;
}
.ant-layout-sider {
  background: transparent;
  margin-left: 20px;
}
.ant-layout-sider-collapsed {
  margin-left: 0;
}
/* Rich content */
.rich img {
  max-width: 100%;
}
/* Quote content */
.quote-content blockquote {
  border-left: 5px solid #eeeeee;
  padding: 5px 10px;
  margin: 0;
}
.quote-content blockquote p {
  margin-bottom: 0;
}
.quote-content blockquote p + p {
  margin-top: 10px;
}
.meta-info {
  font-size: 12px;
  line-height: 1.8;
  color: #9b9b9b;
}
.meta-info > span + span::before {
  display: inline;
  content: " · ";
  color: #9b9b9b;
  padding-left: 2px;
  padding-right: 2px;
}
.list-item {
  border-bottom: 1px solid #f7f7f7;
  padding-top: 20px;
  padding-bottom: 20px;
}
.featured {
  font-size: 12px;
  color: #d49633;
  border: 1px solid #f9ba48;
  padding: 0 3px;
  margin-left: 10px;
  border-radius: 2px;
}
.page-title {
  box-sizing: border-box;
  display: block;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 15px;
  word-wrap: break-word;
  font-weight: 400;
}
.actions > span + span {
  margin-left: 15px;
}
/* badge */
.badge {
  display: inline-block;
  background-color: #f9ba48;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: #ffffff;
  font-size: 12px;
}
.badge10 {
  width: 26px;
  border-radius: 10px;
}
.badge99 {
  width: 40px;
  border-radius: 10px;
}
.gender-icon span {
  vertical-align: middle;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.flex-direction-column {
  flex-direction: column;
}
.align-items-top {
  align-items: top;
}
.align-items-center {
  align-items: center;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}
.font-10 {
  font-size: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-17 {
  font-size: 17px;
}
.font-18 {
  font-size: 18px;
}
.font-19 {
  font-size: 19px;
}
.font-20 {
  font-size: 20px;
}
.font-32 {
  font-size: 32px;
}
.font-weight100 {
  font-size: 100px;
}
.font-weight200 {
  font-size: 200px;
}
.font-weight300 {
  font-size: 300px;
}
.font-weight400 {
  font-size: 400px;
}
.font-weight500 {
  font-size: 500px;
}
.font-weight600 {
  font-size: 600px;
}
.font-weight700 {
  font-size: 700px;
}
.font-weight800 {
  font-size: 800px;
}
.font-weight900 {
  font-size: 900px;
}
.w-50 {
  width: 50px;
}
.w-60 {
  width: 60px;
}
.w-70 {
  width: 70px;
}
.w-80 {
  width: 80px;
}
.w-90 {
  width: 90px;
}
.w-100 {
  width: 100px;
}
.w-full {
  width: fullpx;
}
.prompt-message {
  color: #51b52e;
  font-size: 14px;
}
.prompt-message-gray {
  color: #999;
  font-size: 14px;
}
.swiper-pagination-bullet-active {
  background-color: #f9ba48 !important;
}
.swiper-pagination {
  top: 80px !important;
}
.ant-card-head-title {
  font-weight: 400;
}
.w-full {
  width: 100%;
}
.theme-color {
  color: #f9ba48;
}
.cursor-pointer {
  cursor: pointer;
}
.pointer-events-none {
  pointer-events: none;
}
.container {
  max-width: 1180px;
  margin: 0 auto;
  padding-left: 5px;
  padding-right: 5px;
}
@media (min-width: 576px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 768px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.large-container {
  width: 100%;
  padding: 0;
}
.app-layout {
  margin-top: 20px;
  display: block;
}
@media (min-width: 768px) {
  .app-layout {
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: nowrap;
  }
}
@media (min-width: 768px) {
  .app-content {
    flex: 1 1 auto;
    min-height: calc(100vh - 70px - 270px);
  }
}
@media (min-width: 768px) {
  .app-sider {
    flex-shrink: 0;
    flex-grow: 0;
  }
}
@media (min-width: 768px) {
  .app-sider-md {
    flex-basis: 320px;
  }
}
@media (min-width: 768px) {
  .app-sider-sm {
    flex-basis: 200px;
  }
}
.app-header {
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(123, 123, 123, 0.05);
}
.app-header .inner {
  height: 70px;
  display: flex;
  align-items: middle;
  padding: 0 10px;
}
.app-brand {
  flex: 0 0 215px;
}
.app-menu-desktop {
  flex: 1;
  display: none;
}
.app-menu-desktop .app-menu-left {
  border: 0;
  width: 500px;
}
.app-menu-desktop .app-menu-left .ant-menu-item,
.app-menu-desktop .app-menu-left .ant-menu-submenu {
  cursor: default;
  height: 70px;
  top: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: 0;
  display: inline-block;
  padding: 18px 16px;
  margin: 0px 4px;
  font-size: 18px;
  line-height: 33px;
  text-decoration: none;
  color: #7b7b7b;
  z-index: 100;
  top: -10px;
}
.app-menu-desktop .app-menu-left .ant-menu-item::after,
.app-menu-desktop .app-menu-left .ant-menu-submenu::after {
  border: none !important;
}
.app-menu-desktop .app-menu-left .ant-menu-item a,
.app-menu-desktop .app-menu-left .ant-menu-submenu a {
  position: relative;
  top: 10px;
}
.app-menu-desktop .app-menu-left .ant-menu-item-selected,
.app-menu-desktop .app-menu-left .ant-menu-submenu-selected {
  background-color: #f9ba48;
  border-radius: 0 0 4px 4px;
}
.app-menu-desktop .app-menu-left .ant-menu-item-selected a,
.app-menu-desktop .app-menu-left .ant-menu-submenu-selected a {
  color: #fff;
}
.app-menu-desktop .app-menu-right {
  border: 0;
  text-align: right;
}
.app-menu-desktop .app-menu-right .ant-menu-item {
  height: 70px;
  top: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: 0;
  display: inline-block;
  padding: 18px 22px;
  font-size: 18px;
  line-height: 33px;
  text-decoration: none;
  color: #7b7b7b;
}
.app-menu-desktop .app-menu-right .ant-menu-item::after {
  border: none !important;
}
.app-menu-desktop .app-menu-right .ant-menu-submenu {
  top: 0;
  border: 0;
  margin-top: 0;
  padding: 0 5px;
}
.app-menu-desktop .app-menu-right .ant-menu-submenu::after {
  border: none !important;
}
.app-menu-desktop .app-menu-right .ant-menu-submenu-title {
  height: 70px;
  line-height: 70px;
}
.app-menu-desktop .app-menu-right .app-menu-search {
  padding: 3px 0px;
}
.app-menu-mobile {
  flex: 1;
}
.app-menu-mobile .ant-menu {
  border: none;
}
.app-menu-mobile .ant-menu-item-selected::after {
  display: none;
}
.ant-drawer .ant-menu-inline,
.ant-drawer .ant-menu-vertical,
.ant-drawer .ant-menu-vertical-left {
  border-right: 0;
}
@media (min-width: 992px) and (max-width: 1160px) {
  .app-menu-search {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .app-menu-desktop {
    display: block;
  }
  .app-menu-mobile {
    display: none;
  }
}
.app-menu-search .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 40px !important;
}
.camp-menu .ant-menu-vertical {
  background-color: rgba(123, 119, 109, 0.8) !important;
  transform: translate(-18.3%, 7%);
}
.camp-menu .ant-menu-vertical > .ant-menu-item {
  height: 58px;
  line-height: 22px;
  text-align: center;
  font-size: 12px;
  color: white;
  margin: 0;
}
.camp-menu .ant-menu-vertical.ant-menu-sub {
  min-width: 86px;
}
.camp-menu .ant-menu-vertical .ant-menu-item {
  padding: 0;
}
.camp-menu .ant-menu-item-only-child {
  padding: 0px;
}
.camp-menu .ant-menu-vertical a:not(.ant-btn):visited,
.camp-menu .ant-menu-vertical a:not(.ant-btn):link {
  color: white;
}
.camp-menu .ant-menu-vertical a:not(.ant-btn):hover {
  color: #f9ba48;
}
.camp-menu .ant-menu-vertical .ant-menu-item-only-child:not(:first-child) {
  border-top: 1px solid rgba(217, 217, 217, 0.5);
  padding-top: 8px;
}
.camp-menu .ant-menu-vertical .ant-menu-item-only-child:first-child {
  padding-top: 8px;
  margin-bottom: 0px;
}
.camp-menu-item {
  height: 58px !important;
  line-height: 22px !important;
}
.camp-menu-item .single-line {
  height: 40px;
  line-height: 40px;
}
.app-avatar-dropdown-menu-trigger {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}
.app-avatar-dropdown-menu-trigger .signin-btn {
  color: #f9ba48;
  border: 1px solid #f9ba48;
  border-radius: 4px;
}
.app-avatar-dropdown-menu-trigger .signup-btn {
  border-radius: 4px;
}
.app-avatar-dropdown-menu-mask {
  position: fixed;
  background: rgba(0, 0, 0, 0.45);
  width: 100%;
  height: 0;
  top: 70px;
  left: 0;
  z-index: 100;
}
.app-avatar-dropdown-menu-mask .app-avatar-dropdown-menu {
  opacity: 0;
  visibility: hidden;
  background-color: white;
  font-size: 14px;
  color: #7f7f7f;
  padding: 10px 25px 40px 25px;
  transition: all 0.3s ease-in-out;
}
.app-avatar-dropdown-menu-mask .app-avatar-dropdown-menu .app-avatar-dropdown-menu-me {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.app-avatar-dropdown-menu-mask .app-avatar-dropdown-menu .app-avatar-dropdown-menu-me a,
.app-avatar-dropdown-menu-mask .app-avatar-dropdown-menu .app-avatar-dropdown-menu-me div {
  width: 100px;
  text-align: center;
}
.app-avatar-dropdown-menu-mask .app-avatar-dropdown-menu .app-avatar-dropdown-menu-me a:hover,
.app-avatar-dropdown-menu-mask .app-avatar-dropdown-menu .app-avatar-dropdown-menu-me div:hover {
  border: 1px solid #f9ba48;
  border-radius: 5px;
}
.app-avatar-dropdown-menu-mask .app-avatar-dropdown-menu .app-avatar-dropdown-menu-others {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}
.app-avatar-dropdown-menu-mask .app-avatar-dropdown-menu.show {
  opacity: 1;
  visibility: visible;
}
.app-avatar-dropdown-menu-mask.show {
  height: 100%;
}
.app-mobile-nav .ant-menu {
  font-size: 0.8rem;
  background-color: inherit;
  margin-top: -18px;
}
.app-mobile-nav .ant-menu-horizontal > .ant-menu-item::after,
.app-mobile-nav .ant-menu-horizontal > .ant-menu-submenu::after {
  bottom: 10px;
}
.ReactModal__Overlay {
  background-color: rgba(74, 74, 74, 0.75);
  overflow-y: auto;
  z-index: 1000;
}
.ReactModal__Content {
  width: auto;
  left: 5px !important;
  right: 5px !important;
}
.ReactModal__Content .close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  margin-top: -10px;
}
.ReactModal__Content .close:hover,
.ReactModal__Content .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.ReactModal__Content .modal-body {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.ReactModal__Content .modal-body .modal-logo {
  display: block;
  margin: 20px auto;
  height: 38px;
}
.ReactModal__Content .modal-body .navs {
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  list-style: none;
  display: table;
  table-layout: fixed;
  width: 100%;
  text-align: center;
  overflow: hidden;
  border-bottom: 2px solid #d8d8d8;
}
.ReactModal__Content .modal-body .navs li {
  display: table-cell;
}
.ReactModal__Content .modal-body .navs li a {
  display: block;
  text-decoration: none;
  color: #9b9b9b;
  font-size: 16px;
}
.ReactModal__Content .modal-body .navs li a span {
  display: block;
  padding: 18px 18px;
  line-height: 25px;
}
.ReactModal__Content .modal-body .navs li.active a {
  color: #000000;
}
.ReactModal__Content .modal-body .navs li.active a::after {
  display: block;
  line-height: 0;
  content: " ";
  width: 40%;
  margin: 0 auto;
  border: 2px solid #f9ba48;
}
.ReactModal__Content .modal-body .ant-form-item {
  margin-bottom: 20px;
}
.ReactModal__Content .modal-body .ant-input {
  padding: 9px 16px;
  font-size: 16px;
  border-radius: 2px;
}
.ReactModal__Content .modal-body .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 40px;
}
.ReactModal__Content .modal-body .ant-input-affix-wrapper {
  padding: 0;
}
.ReactModal__Content .modal-body .ant-input-prefix {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  color: rgba(0, 0, 0, 0.65);
  z-index: 1;
}
.ReactModal__Content .modal-body .ant-menu {
  justify-content: center;
}
.newjob-modal {
  position: absolute;
  width: 89%;
  height: 562px;
  margin: 40px auto;
  overflow: hidden;
  overflow-y: scroll;
  color: #1e1d1d;
  padding: 20px 10px 10px 10px !important;
  inset: unset !important;
  border-radius: 12px !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -55%);
}
.newjob-modal .show-on-pc {
  display: none;
}
.newjob-modal .modal-body {
  margin: 0;
}
.newjob-modal .header .modal-title {
  font-size: 24px;
  font-weight: 900;
  padding: 10px 0;
}
.newjob-modal .header .tip {
  font-size: 12px;
  font-weight: 400;
  width: 72%;
  margin: 0 auto;
}
.newjob-modal .header .ant-alert {
  display: none;
  font-size: 12px;
  font-weight: 400;
  width: 388px;
  margin: 6px auto;
  background-color: #fef6da;
  border-radius: 12px;
}
.newjob-modal .header .ant-alert .ant-alert-message {
  color: #e34614;
}
.newjob-modal .header .ant-alert .ant-alert-icon {
  margin-right: 0;
}
.newjob-modal .ant-alert {
  display: none;
}
.newjob-modal .newjob-body {
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  row-gap: 10px;
}
.newjob-modal .newjob-body .item {
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
}
.newjob-modal .newjob-body .item .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.newjob-modal .newjob-body .item .ant-collapse-item {
  margin: 0;
  border-radius: 12px;
  background-color: white !important;
}
.newjob-modal .newjob-body .item .ant-collapse {
  border-radius: 12px;
}
.newjob-modal .newjob-body .item .ant-collapse-header {
  border-radius: 12px;
  padding: 27px 25px !important;
  background: linear-gradient(to bottom, #e0fda1, #f0ffd0);
}
.newjob-modal .newjob-body .item .ant-collapse-header .title-w {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.newjob-modal .newjob-body .item .ant-collapse-header .title-w .title {
  font-size: 20px;
  font-weight: 600;
}
.newjob-modal .newjob-body .item .ant-collapse-header .title-w .arrow {
  width: 10px;
  height: 10px;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  transform: rotate(-45deg);
  margin-top: -10px;
}
.newjob-modal .newjob-body .item .ant-collapse-header .sub-title {
  font-size: 12px;
  margin-top: 7px;
}
.newjob-modal .newjob-body .item .ant-collapse-header .sub-title .anticon {
  color: #f9ba48;
  margin-right: 5px;
}
.newjob-modal .newjob-body .item .ant-collapse-item-active .ant-collapse-header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.newjob-modal .newjob-body .item .ant-collapse-item-active .ant-collapse-header .title-w .arrow {
  transform: rotate(135deg);
  margin-top: 0px;
}
.newjob-modal .newjob-body .item .header {
  text-align: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 28px 0;
}
.newjob-modal .newjob-body .item .header .title {
  font-size: 20px;
  font-weight: 600;
}
.newjob-modal .newjob-body .item .header .sub-title {
  font-size: 12px;
  font-weight: 400;
  margin-top: 7px;
}
.newjob-modal .newjob-body .item .header .anticon {
  color: #f9ba48;
  margin-right: 10px;
}
.newjob-modal .newjob-body .item .list {
  display: flex;
  row-gap: 35px;
  flex-direction: column;
  padding: 0px 10px;
  background-color: white;
}
.newjob-modal .newjob-body .item .list .list-i {
  display: flex;
  align-items: center;
  font-size: 14px;
  column-gap: 15px;
  font-weight: 400;
}
.newjob-modal .newjob-body .item .list .list-i img {
  width: 32px;
  height: 32px;
}
.newjob-modal .newjob-body .item .list .list-i .text1 {
  font-size: 14px;
}
.newjob-modal .newjob-body .item .list .list-i .text2 {
  font-size: 12px;
  color: #787777;
  margin-top: 5px;
}
.newjob-modal .newjob-body .item .list .list-i .text2 span {
  color: #f9ba48;
  text-decoration: underline;
  padding: 0px 2px;
  cursor: pointer;
}
.newjob-modal .newjob-body .item .list .list-i .text3 {
  font-size: 16px;
  font-weight: 600;
}
.newjob-modal .newjob-body .item .list .bullet-points {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.newjob-modal .newjob-body .item .list .bullet-points div {
  position: relative;
}
.newjob-modal .newjob-body .item .list .bullet-points div::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #1e1d1d;
  left: 15px;
  text-decoration: underline;
  padding: 0px 2px;
  top: 40%;
}
.newjob-modal .newjob-body .item .list .list-i-mt {
  margin-top: 15px;
}
.newjob-modal .newjob-body .item .list3 {
  row-gap: 15px;
  padding: 10px 0px;
  width: 280px;
  margin: 0 auto;
  text-align: center;
}
.newjob-modal .newjob-body .item .list3 .list-i {
  justify-content: center;
}
.newjob-modal .newjob-body .item .btn {
  width: 84%;
  height: 46px;
  line-height: 46px;
  font-size: 16px;
  font-weight: 500;
  margin: 0 auto;
  text-align: center;
  border-radius: 12px;
  cursor: pointer;
}
.newjob-modal .newjob-body .item1 .btn {
  background-color: #dffda1;
  margin-top: 18px;
}
.newjob-modal .newjob-body .item2 .ant-collapse-header {
  background: linear-gradient(to bottom, #ffee95, #fff7cf);
}
.newjob-modal .newjob-body .item2 .btn {
  background-color: #ffee95;
  margin-top: 12px;
}
.newjob-modal .newjob-body .item3 .ant-collapse-header {
  background: linear-gradient(to bottom, #ffbca7, #ffe1d7);
}
.newjob-modal .newjob-body .item3 .btn {
  background-color: #ffbca7;
  margin-top: 10px;
}
.newjob-modal .newjob-body .item3 .line {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 15px;
}
.newjob-modal .newjob-body .item3 .services {
  display: flex;
  flex-wrap: nowrap;
  column-gap: 40px;
  margin-top: 15px;
  margin-left: 20px;
}
.newjob-modal .newjob-body .item3 .services div {
  position: relative;
}
.newjob-modal .newjob-body .item3 .services div::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  left: -15px;
  top: 40%;
  background-color: black;
}
@media (min-width: 768px) {
  .ReactModal__Content {
    width: 500px;
  }
  .ReactModal__Content .modal-body {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 40px;
  }
  .newjob-modal {
    color: #1e1d1d;
    width: 1320px;
    height: 750px;
    padding: 0 !important;
    overflow-y: hidden;
    position: absolute;
    border-radius: 12px;
    transform: translate(-50%, -50%);
  }
  .newjob-modal .show-on-pc {
    display: block;
  }
  .newjob-modal .show-on-mobile {
    display: none;
  }
  .newjob-modal .header .modal-title {
    font-size: 32px;
  }
  .newjob-modal .header .ant-alert {
    display: flex;
    font-size: 12px;
    font-weight: 400;
    width: 388px;
    margin: 20px auto;
    background-color: #fef6da;
    border-radius: 12px;
  }
  .newjob-modal .header .ant-alert .ant-alert-message {
    color: #e34614;
  }
  .newjob-modal .header .ant-alert .ant-alert-icon {
    margin-right: 0;
  }
  .newjob-modal .header .tip {
    display: none;
  }
  .newjob-modal .newjob-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 25px;
  }
  .newjob-modal .newjob-body .item {
    width: 32%;
    border: 1px solid #e5e5e5;
    border-radius: 12px;
    padding-bottom: 0px;
  }
  .newjob-modal .newjob-body .item .header {
    text-align: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 28px 0;
  }
  .newjob-modal .newjob-body .item .header .title {
    font-size: 20px;
    font-weight: 600;
  }
  .newjob-modal .newjob-body .item .header .sub-title {
    font-size: 12px;
    font-weight: 400;
    margin-top: 7px;
  }
  .newjob-modal .newjob-body .item .header .anticon {
    color: #f9ba48;
    margin-right: 10px;
  }
  .newjob-modal .newjob-body .item .list {
    display: flex;
    row-gap: 25px;
    flex-direction: column;
    padding: 0px 30px;
  }
  .newjob-modal .newjob-body .item .list .list-i {
    display: flex;
    align-items: center;
    font-size: 14px;
    column-gap: 15px;
    font-weight: 400;
  }
  .newjob-modal .newjob-body .item .list .list-i img {
    width: 40px;
    height: 40px;
  }
  .newjob-modal .newjob-body .item .list .list-i .text1 {
    font-size: 14px;
  }
  .newjob-modal .newjob-body .item .list .list-i .text1 span {
    color: #f9ba48;
    text-decoration: underline;
    padding: 0px 2px;
    cursor: pointer;
  }
  .newjob-modal .newjob-body .item .list .list-i .text2 {
    font-size: 12px;
    color: #787777;
    margin-top: 5px;
  }
  .newjob-modal .newjob-body .item .list .list-i .text3 {
    font-size: 16px;
    font-weight: 600;
  }
  .newjob-modal .newjob-body .item .list .bullet-points {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .newjob-modal .newjob-body .item .list .bullet-points div {
    position: relative;
  }
  .newjob-modal .newjob-body .item .list .bullet-points div::before {
    position: absolute;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #1e1d1d;
    left: 0px;
    text-decoration: underline;
    padding: 0px 2px;
    top: 40%;
  }
  .newjob-modal .newjob-body .item .list .list-i-mt {
    margin-top: 55px;
  }
  .newjob-modal .newjob-body .item .list3 {
    row-gap: 15px;
    padding: 38px 0px;
    width: 280px;
    margin: 0 auto;
    text-align: center;
  }
  .newjob-modal .newjob-body .item .list3 .list-i {
    justify-content: center;
  }
  .newjob-modal .newjob-body .item .btn {
    width: 84%;
    height: 46px;
    line-height: 46px;
    font-size: 16px;
    font-weight: 500;
    margin: 0 auto;
    text-align: center;
    border-radius: 12px;
    cursor: pointer;
  }
  .newjob-modal .newjob-body .item1 .header {
    background: linear-gradient(to bottom, #e0fda1, #f0ffd0);
  }
  .newjob-modal .newjob-body .item1 .btn {
    background-color: #dffda1;
    margin-top: 45px;
  }
  .newjob-modal .newjob-body .item2 .header {
    background: linear-gradient(to bottom, #ffee95, #fff7cf);
  }
  .newjob-modal .newjob-body .item2 .btn {
    background-color: #ffee95;
    margin-top: 33px;
  }
  .newjob-modal .newjob-body .item3 .header {
    background: linear-gradient(to bottom, #ffbca7, #ffe1d7);
  }
  .newjob-modal .newjob-body .item3 .btn {
    background-color: #ffbca7;
    margin-top: 80px;
  }
}
.wechat-unbind-modal p {
  font-size: 16px;
}
.wechat-unbind-modal__footer {
  display: flex;
}
.wechat-unbind-modal__footer button:first-child {
  margin-right: 20px;
}
.field-item {
  display: flex;
  margin-bottom: 10px;
  line-height: 26px;
  flex-wrap: wrap;
}
.field-item .field-label {
  flex: 100%;
  font-weight: bold;
  margin-bottom: 10px;
}
.field-item.block .field-label {
  display: none;
}
.field-item .field-body {
  flex: 100%;
}
@media (min-width: 576px) {
  .field-item {
    flex-wrap: nowrap;
  }
  .field-item .field-label {
    flex: 0 0 70px;
    width: calc(100% - 340px);
    margin-bottom: 0;
  }
  .field-item .field-body {
    flex: 1;
  }
}
.sider-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
}
.sider-list li {
  background: #F7F7F7;
  padding: 15px 24px;
}
.sider-list li + li {
  margin-top: 6px;
}
.sider-list li a {
  display: block;
}
.user-tag {
  color: #877d6a;
  font-size: 14px;
  padding: 3px 11px;
  background: #f5f3e7;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 4px;
  margin-bottom: 10px;
  display: inline-block;
  line-height: 20px;
}
.level-badge {
  position: relative;
  display: inline-block;
  height: 14px;
  width: 20px;
  top: 2px;
}
.level-badge img {
  position: absolute;
  height: 14px;
  left: 0;
  top: 0;
}
.level-badge span {
  position: absolute;
  color: #e5a95b;
  font-size: 9px;
  line-height: 1;
  font-weight: bold;
  font-style: italic;
  bottom: 0;
  left: 10px;
}
.user-info-block {
  margin: 10px;
}
.user-info-block .user-info-box {
  display: flex;
  padding: 0 5px;
  align-items: center;
}
.user-info-block .user-info-box h3 {
  color: #565a5c;
  line-height: 28px;
  font-size: 20px;
  margin-bottom: 0px;
}
.user-info-block .user-info-box .level-badge {
  margin-left: 10px;
}
.user-info-block p {
  line-height: 28px;
  font-size: 12px;
  font-weight: 400;
  color: #9b9b9b;
  line-height: 17px;
  margin-bottom: 15px;
  padding: 0 5px;
}
.user-info-block .body {
  width: 100%;
  max-width: auto;
}
.user-info-block .extra {
  padding-left: 5px;
}
@media (min-width: 576px) {
  .user-info-block .body {
    max-width: 500px;
  }
}
.small {
  width: 45%;
}
@media (max-width: 576px) {
  .small {
    width: 90%;
  }
}
.wechat-unbind-btn {
  margin-left: 10px;
}
.avatar-level {
  text-align: center;
  background: #fff;
  color: #f9ba48;
  border-radius: 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 56%;
  border: 1px solid #f9ba48;
  bottom: 0px;
  font-size: 9px;
  line-height: 9px;
}
.avatar-level img {
  width: 7px;
  height: 7px;
}
.avatar-level.small {
  font-size: 7px;
  line-height: 7px;
  bottom: -1px;
  width: 70%;
}
.avatar-level.small img {
  width: 6px;
  height: 6px;
}
.category-link {
  border-radius: 3px;
  padding: 0.1em 0.3em;
  font-size: 0.9em;
}
.post-item {
  border-bottom: 1px solid #f7f7f7;
  display: flex;
  padding: 15px 0;
  align-items: center;
}
.post-item .aside {
  flex: 0 0 40px;
  text-align: left;
}
.post-item .aside .ant-avatar {
  width: 30px;
  height: 30px;
}
.post-item .body {
  flex: 1;
  overflow: hidden;
}
.post-item .extra {
  flex: 0 0 40px;
  text-align: right;
}
.post-item .post-title {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 5px;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  font-weight: 400;
}
.post-item .post-title__hidden-tag {
  font-size: 14px;
  border-radius: 3px;
  color: white;
  background: #f9ba48;
  padding: 0 4px;
  margin-right: 8px;
}
.post-item.deleted .post-title a {
  text-decoration: line-through;
  pointer-events: none;
}
@media (min-width: 768px) {
  .post-item .aside {
    flex: 0 0 80px;
  }
  .post-item .aside .ant-avatar {
    width: 60px;
    height: 60px;
  }
}
/**
 * Post Charge
 */
.charge-button {
  cursor: pointer;
  width: 104px;
  height: 104px;
  text-align: center;
  padding-top: 23px;
  font-size: 12px;
  color: #ffffff;
}
.post-contact-info {
  color: #f4c876;
  border: 1px solid #f4c876;
  border-radius: 5px;
  display: inline-block;
  padding: 10px 20px;
  font-weight: bold;
  -webkit-box-shadow: 0px 1px 2px 0px #f5f5f3;
  -moz-box-shadow: 0px 1px 2px 0px #f5f5f3;
  box-shadow: 0px 1px 2px 0px #f5f5f3;
}
.post-comment-extra .ant-menu-horizontal {
  border-bottom: 0px;
}
.post-comment-extra .ant-menu-horizontal .ant-menu-item {
  padding: 0px;
  margin-right: 15px;
}
.post-comment-extra .ant-menu-horizontal .ant-menu-item:hover {
  border-bottom-color: transparent;
}
.post-comment-extra .ant-menu-horizontal .ant-menu-item-selected {
  border-bottom-color: transparent;
}
.post-action-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #f9ba48;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 15px 10px;
  font-size: 12px;
  color: #f9ba48;
  cursor: pointer;
}
.post-action-button-active {
  background-color: #f9ba48;
  color: white;
}
.post-list-vote-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  gap: 5px;
  color: #7b7b7b;
}
.post-list-vote-button .anticon {
  cursor: pointer;
}
.post-list-vote-button .upvote path,
.post-list-vote-button .downvote path {
  fill: #9b9b9b;
}
.post-list-vote-button .active {
  color: #f9ba48;
}
.post-list-vote-button .active .upvote path {
  fill: #f9ba48;
  stroke: #f9ba48;
  stroke-width: 30;
}
.post-list-vote-button .active .downvote path {
  fill: #444444;
  stroke: #444444;
  stroke-width: 30;
}
.post-list-vote-button .post-vote-count {
  width: 30px;
  text-align: center;
}
/* CKEditor */
.bf-content * {
  line-height: 1.3;
}
.bf-modal {
  z-index: 1000;
}
.public-DraftStyleDefault-block {
  margin-bottom: 0.5em;
}
.rich-content {
  overflow: hidden;
  word-break: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.rich-content > :first-child {
  margin-top: 0;
}
.rich-content a {
  text-decoration: underline;
}
.rich-content h1 {
  font-size: 24px;
  border-bottom: 2px solid #eee;
  padding-bottom: 5px;
}
.rich-content h2 {
  font-size: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}
.rich-content h3 {
  font-size: 18px;
}
.rich-content p {
  margin-bottom: 0.6em;
}
.rich-content blockquote {
  padding: 5px 10px;
  margin: 0 0 1em 0;
  font-size: 1em;
  border-left: 5px solid #eee;
}
.rich-content blockquote > :first-child {
  margin-top: 0;
}
.rich-content blockquote > :last-child {
  margin-bottom: 0;
}
.rich-content pre {
  padding: 5px 10px;
  font-size: 1em;
  background: #eee;
  white-space: pre-wrap;
}
.rich-content img,
.rich-content video,
.rich-content audio {
  max-width: 100%;
}
.rich-content hr {
  border: 1px solid #eee;
}
.rich-content figcaption {
  font-size: 0.8em;
  color: #aaa;
}
.rich-content ul,
.rich-content ol {
  margin: 0 0 1em 0;
  padding: 0 0 0 2em;
}
.rich-content ul > ul,
.rich-content ul > ol,
.rich-content ol > ul,
.rich-content ol > ul {
  margin-bottom: 0;
}
.rich-content .secret_note {
  background-color: #fff8ee;
  padding: 3px 8px;
}
.root-comment {
  padding-bottom: 15px;
}
.root-comment + .root-comment {
  border-top: 1px solid #D8D8D8;
  padding-top: 15px;
}
.sub-comment {
  background: #F7F7F7;
  padding: 10px 0;
  margin-top: 15px;
}
.sub-comment .form-reply,
.sub-comment .form-update {
  margin-left: 10px;
  margin-right: 10px;
}
@media (min-width: 768px) {
  .sub-comment {
    padding: 20px 0;
  }
}
.comment {
  display: flex;
}
.comment .aside {
  flex: 0 0 32px;
}
.comment .aside .ant-avatar {
  width: 32px;
  height: 32px;
}
@media (min-width: 768px) {
  .comment .aside {
    flex: 0 0 50px;
  }
  .comment .aside .ant-avatar {
    width: 50px;
    height: 50px;
  }
}
.comment .body {
  flex: 1;
  padding: 0 10px;
}
@media (min-width: 768px) {
  .comment .body {
    padding: 0 20px;
  }
}
.root-comment > .extra {
  margin-left: 32px;
}
.root-comment > .extra .form-reply {
  background: #efefef;
  padding: 10px;
  margin-top: 10px;
}
.root-comment > .extra .form-update {
  background: #efefef;
  padding: 10px;
  margin-top: 10px;
}
@media (min-width: 768px) {
  .root-comment > .extra {
    margin-left: 50px;
  }
  .root-comment > .extra .form-reply {
    padding: 20px;
  }
  .root-comment > .extra .form-update {
    padding: 20px;
  }
}
.meta {
  height: 20px;
  line-height: 20px;
  vertical-align: middle !important;
  margin-bottom: 5px;
}
.comment-content.deleted {
  color: #939393;
  background-color: #e9e9e9;
  padding: 3px 8px;
}
.comment-content.deleted a {
  text-decoration: underline;
}
.post-comment-extra .ant-menu-item::after {
  border: none !important;
}
.ant-form-item-label {
  white-space: normal;
}
.modal-profile-edit {
  overflow-y: auto;
}
.modal-profile-edit .modal-body {
  overflow-y: auto;
  margin: 20px 40px;
}
.modal-profile-edit .ant-row {
  display: block;
}
.modal-profile-edit .ant-form-item-label {
  display: block;
  text-align: left;
  border-bottom: 1px solid #D8D8D8;
  margin-bottom: 10px;
  padding: 0 !important;
  line-height: 40px;
}
.modal-profile-edit .ant-form-item-label label {
  border-bottom: 3px solid #f9ba48;
  display: inline-block;
  height: 100%;
}
.modal-profile-edit .ant-form-item-label .ant-form-item-required::before {
  display: none;
}
.modal-profile-edit .ant-form-item-label .ant-form-item-required::after {
  display: inline-block;
  margin-left: 4px;
  margin-right: 0;
  vertical-align: sub;
  color: #f5222d;
  font-size: 16px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.radio-gender.ant-radio-group-solid {
  display: flex;
  justify-content: space-between;
}
.radio-gender.ant-radio-group-solid .ant-radio-button-wrapper {
  flex: 0 47%;
  text-align: center;
  border: 0;
  background: #F7F7F7;
  color: #4A4A4A;
  height: 44px;
}
.radio-gender.ant-radio-group-solid .ant-radio-button-wrapper:before {
  display: none;
}
.radio-gender.ant-radio-group-solid .ant-radio-button-wrapper .ant-radio-button + span {
  display: block;
  line-height: 44px;
}
.radio-gender.ant-radio-group-solid .ant-radio-button-wrapper svg {
  vertical-align: middle;
  fill: #4A4A4A;
}
.radio-gender.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #F9BA48;
  color: #FFFFFF;
  box-shadow: none;
}
.radio-gender.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) svg {
  fill: #FFFFFF;
}
/**
 * wwads 广告自适应尺寸
 *
 * https://wwads.cn/help/publishers/change-banner-css
 */
.wwads-cn a.block {
  width: 100% !important;
  height: auto !important;
}
.promo-item {
  margin-bottom: 10px;
}
.promo-item.promo-type-sidebar a,
.promo-item.promo-type-post a {
  display: block;
}
.promo-item.promo-type-sidebar img,
.promo-item.promo-type-post img {
  width: 100%;
}
.promo-item.promo-type-post {
  margin-top: 10px;
}
/* Banner */
.promo-banner {
  margin-bottom: 10px;
}
.promo-banner .swiper {
  display: grid;
}
.promo-banner .swiper-wrapper {
  min-width: 0;
}
.promo-banner a {
  display: block;
}
.promo-banner img {
  width: 100%;
  height: 100px;
}
@media (min-width: 768px) {
  .promo-banner {
    margin-bottom: 20px;
  }
  .promo-banner img {
    width: 100%;
  }
}
.owner-card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  background-color: white;
}
.owner-card .left {
  display: flex;
  column-gap: 13px;
}
.owner-card .left .avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #f9ba48;
}
.owner-card .left .nickname {
  font-size: 16px;
  color: #4a4a4a;
}
.owner-card .left .create-time {
  font-size: 12px;
  color: #9b9b9b;
}
.city-rules-card {
  margin: 0;
  padding: 0;
  font-size: 13px;
  color: #9b9b9b;
  list-style-type: none;
}
.city-rules-card li {
  background: url("~public/static/icons/list.png") left center no-repeat;
  padding-left: 20px;
  line-height: 25px;
}
.city-members-card {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin: 5px 0 7px 0;
  max-height: 264px;
  overflow-y: scroll;
}
.city-members-card .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f9ba48;
}
.city-members-card .more {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f0f0f0;
  color: #7b7b7b;
  font-size: 30px;
  cursor: pointer;
}
.city-members-card .more::after {
  content: "...";
  position: absolute;
  font-size: 26px;
  left: 50%;
  transform: translate(-50%, -15%);
}
.join-btn-big {
  width: 100%;
  background-color: #f9ba48;
  font-size: 16px;
  color: white;
  text-align: center;
  border-radius: 4px;
  padding: 10px 0;
  margin-top: 20px;
  cursor: pointer;
}
.joined-big {
  background-color: #f0f0f0;
  color: #7b7b7b;
}
.city-group-card {
  max-height: 264px;
  overflow-y: scroll;
}
.city-details-wrapper {
  position: relative;
}
.city-details-wrapper .site-swiper {
  z-index: 0;
}
.city-details-wrapper .city-details {
  position: absolute;
  bottom: 30px;
  left: 65px;
  z-index: 1;
}
.city-details-wrapper .city-details .top {
  display: flex;
  align-items: center;
}
.city-details-wrapper .city-details .top .city-name {
  font-size: 40px;
  font-weight: 600;
  color: white;
}
.city-details-wrapper .city-details .bottom {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  column-gap: 15px;
}
.city-details-wrapper .city-details .bottom .index {
  display: flex;
  column-gap: 10px;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: white;
}
.city-details-wrapper .city-details .bottom .index .text {
  line-height: 10px;
}
.city-details-wrapper .city-details .bottom .index img {
  width: 40px;
  height: 40px;
}
.city-menu-wrapper {
  display: flex;
  font-size: 16px;
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
}
.city-menu-wrapper div {
  margin-left: 10px;
  margin-right: 10px;
  color: #4a4a4a;
  cursor: pointer;
}
.city-menu-wrapper div:hover {
  color: #f9ba48;
}
.city-menu-wrapper .active {
  color: #f9ba48;
}
.owner-wrapper {
  display: none;
}
@media screen and (max-width: 1175px) {
  .city-details-wrapper .city-details {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
  .city-details-wrapper .city-details .top .city-name {
    font-size: 24px;
  }
  .city-details-wrapper .city-details .top .hide-in-mp {
    display: none;
  }
  .city-details-wrapper .city-details .bottom {
    margin-top: 10x;
    row-gap: 5px;
  }
  .city-details-wrapper .city-details .bottom .index {
    column-gap: 5px;
    font-size: 10px;
  }
  .city-details-wrapper .city-details .bottom .index .text {
    line-height: 9px;
  }
  .city-details-wrapper .city-details .bottom .index img {
    width: 24px;
    height: 24px;
  }
  .owner-wrapper {
    display: block;
    background-color: white;
    padding: 15px 20px;
    border-radius: 8px;
  }
  .owner-wrapper .city-tag-wrapper {
    justify-content: space-between;
  }
  .owner-wrapper .city-tag-wrapper .tag {
    padding: 2px 10px;
    font-size: 14px;
  }
  .owner-wrapper .owner-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .owner-wrapper .owner-info .left {
    color: #4a4a4a;
    display: flex;
    column-gap: 10px;
    align-items: center;
  }
  .owner-wrapper .owner-info .left .nickname {
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    font-weight: 500;
    color: #4a4a4a;
  }
  .owner-wrapper .owner-info .left .create-time {
    font-size: 13px;
  }
  .owner-wrapper .owner-info .join-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    line-height: 24px;
    margin-left: 9px;
    padding: 0px 10px;
    font-size: 14px;
    font-weight: 500;
    color: #f9ba48;
    border: 1px solid #f9ba48;
    background-color: white;
    border-radius: 20px;
    cursor: pointer;
  }
  .owner-wrapper .owner-info .joined {
    color: #7b7b7b;
    border: none;
    background-color: #f0f0f0;
  }
  .owner-wrapper .line {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #f0f0f0;
    margin: 15px 0;
  }
  .owner-wrapper .members-wrapper {
    display: flex;
    align-items: center;
    column-gap: 5px;
    margin-top: 15px;
  }
  .owner-wrapper .members-wrapper .more-users {
    position: relative;
    width: 22px;
    height: 22px;
    font-size: 10px;
    border-radius: 50%;
    background-color: #f0f0f0;
    color: #7b7b7b;
  }
  .owner-wrapper .members-wrapper .more-users::after {
    content: "...";
    position: absolute;
    font-size: 15px;
    left: 50%;
    transform: translate(-50%, -20%);
  }
  .owner-wrapper .members-wrapper .total-count {
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
  }
  .owner-wrapper .members-wrapper .total-count span {
    color: #f9ba48;
  }
  .city-menu-wrapper {
    background-color: inherit;
    padding: 15px 0;
    margin-bottom: 0;
  }
}
.group-info-wrapper {
  padding: 32px;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  text-align: center;
}
.group-profile {
  text-align: left;
}
.group-logo {
  width: 108px;
  height: 108px;
  border-radius: 8px;
}
.group-name-wrapper {
  height: 30px;
  overflow: hidden;
}
.group-info {
  display: inline-block;
  vertical-align: middle;
  margin-left: 32px;
}
.group-info .name,
.group-info .group-owner,
.group-info .item {
  display: inline-block;
}
.group-info .name {
  font-size: 20px;
  color: #333333;
  vertical-align: middle;
}
.group-info .group-owner {
  padding: 2px 8px;
  margin-left: 10px;
  background-color: #4edb88;
  border-radius: 26px;
  vertical-align: middle;
  font-size: 12px;
  color: #fff;
}
.group-info .group-owner img,
.group-info .group-owner span,
.group-info .group-owner a {
  vertical-align: middle;
}
.group-info .group-owner a {
  color: #fff;
}
.group-info .group-owner img {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 5px;
}
.group-info .item {
  margin: 10px 15px 0 0;
}
.group-info .item img {
  vertical-align: middle;
  height: 15px;
}
.group-info .item span {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  color: #828282;
  margin-left: 6px;
}
.group-info .group-tags {
  display: inline-block;
  height: 36px;
  overflow: hidden;
}
.group-info .group-tags .item {
  font-size: 12px;
  color: #828282;
  text-align: left;
  padding: 4px 6px;
  border-radius: 8px;
  background-color: #e3e3e3;
  margin-top: 10px;
  margin-right: 7px;
}
.group-desc {
  background-color: #fafafa;
  padding: 10px;
  text-align: justify;
  color: #7b7b7b;
  margin: 15px 0px;
  overflow-x: hidden;
  height: 120px;
}
.group-desc pre {
  white-space: pre-wrap;
  word-break: break-all;
}
.group-sm-wrapper {
  border-radius: 8px;
  border: 1px solid #d3d3d3;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.group-sm-wrapper .group-logo {
  width: 76.5px;
  height: 76.5px;
  border-radius: 4.25px;
}
.group-sm-wrapper .group-info {
  font-size: 13px;
}
.group-sm-wrapper .group-info .name {
  font-size: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.group-sm-wrapper:hover {
  transform: scale(1.05);
  border: 1px solid #fff;
  box-shadow: 0px 0px 10px #dedede;
}
.group-sm-wrapper .group-type-wrapper {
  height: 27px;
  overflow-y: hidden;
}
.group-sm-wrapper .group-type {
  display: inline-block;
  border-radius: 8.5px;
  background-color: #ffc700;
  padding: 5px 7px;
  margin-left: 5px;
  color: white;
  line-height: 0px;
  vertical-align: middle;
  font-size: 0.8em;
}
.group-sm-wrapper .group-type * {
  vertical-align: middle;
}
.group-sm-wrapper .group-type img {
  height: 12px;
  margin-right: 4px;
}
.group-sm-wrapper .group-type.group-type-pay {
  background-color: #a0de1e;
}
.group-sm-wrapper .group-type.group-type-new {
  background-color: #ff7a02;
}
.group-sm-wrapper .group-type.group-type-new .anticon {
  width: 12px;
  height: 12px;
}
.group-sm-simple {
  border: none;
  padding: 12px 4px 12px 16px;
  margin: 0;
}
.group-sm-simple:hover {
  transform: none;
  border: none;
  box-shadow: none;
}
.group-modal {
  color: #7b7b7b;
}
.group-modal .group-desc {
  height: 300px;
}
.group-modal .ant-modal-footer {
  border-top: 0px solid #fff !important;
  text-align: left;
  padding: 0px 50px 40px 50px;
}
.group-modal .group-modal-footer .center {
  text-align: center;
}
.group-modal .group-modal-footer .qr-code {
  width: 100%;
  margin-top: 10px;
}
.groups-nav {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
}
.groups-nav .new-group {
  padding: 0 14px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.groups-nav .new-group * {
  vertical-align: middle;
}
.groups-nav .right {
  text-align: right;
}
.groups-nav .tag {
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
  white-space: nowrap;
  display: inline-block;
  margin-top: 10px;
}
.groups-nav .ant-btn-text {
  border-color: transparent;
}
@media screen and (max-width: 600px) {
  .groups-nav .tag {
    border: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 0.8rem;
    background-color: white;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    border-radius: 20px;
    margin-right: 10px;
    margin-bottom: 5px;
    white-space: nowrap;
    display: inline-block;
  }
}
.recommend-tip {
  text-align: right;
}
.prompt-message-mb {
  display: none;
}
@media screen and (max-width: 600px) {
  .recommend-tip {
    text-align: left;
  }
  .prompt-message-pc {
    display: none;
  }
  .prompt-message-mb {
    display: block;
    padding: 0 15px 20px 15px;
  }
  .groups-nav {
    flex-direction: column;
    align-items: flex-start;
  }
}
.links-label {
  font-size: 16px;
  color: #ffc700;
  margin-bottom: 8px;
}
.col {
  margin-bottom: 4px;
}
.add-notice {
  text-align: center;
  color: #777777;
}
.icon-img {
  margin-right: 6px;
}
.item-wrapper {
  border-bottom: 1px solid #f2f1f1;
  padding: 10px 10px;
  cursor: pointer;
}
.item-wrapper:hover {
  background-color: #f5f5f5;
}
.item-wrapper .item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item-wrapper .item-header .top .title span {
  display: inline-block;
  vertical-align: middle;
}
.item-wrapper .item-header .top .title span:hover {
  color: #f9ba48;
}
.item-wrapper .item-header .status {
  color: #f9ba48;
}
.item-wrapper .update-at {
  font-size: 12px;
  color: #aaa;
  margin-top: 10px;
}
.selected {
  background-color: #f5f5f5;
}
.status-wrapper .title {
  font-weight: 900;
}
.status-wrapper .content {
  font-weight: 500;
  font-size: 12px;
}
@media only screen and (min-width: 1170px) {
  .status-wrapper .vertical-timeline-element {
    margin: 1em 0 !important;
  }
}
.status-wrapper .vertical-timeline {
  padding: 0 !important;
}
.status-wrapper .vertical-timeline .time-line-date {
  padding: 3px;
}
.status-wrapper .vertical-timeline .time-line-content {
  padding: 5px;
}
.status-wrapper .vertical-timeline .current-time-item {
  background: #f9ba48;
  width: 20px;
  height: 20px;
  margin-top: 10px;
  margin-left: 10px;
}
.status-wrapper .vertical-timeline .time-item {
  background: #f6f6f6;
  width: 20px;
  height: 20px;
  margin-top: 10px;
  margin-left: 10px;
}
.english .section-1 {
  padding-top: 74px;
  position: relative;
}
.english .section-1 .nav {
  position: relative;
  width: 1175px;
  margin: auto;
  background: rgba(70, 70, 70, 0.69);
  border-radius: 66px;
  padding: 14px 39px;
}
.english .section-1 .nav img {
  width: 245px;
}
.english .section-1 .nav .nav-menu-icon {
  position: absolute;
  display: none;
  right: 28px;
  top: 50%;
  transform: translateY(-50%);
}
.english .section-1 .nav .text {
  display: inline-flex;
  flex-direction: column;
  color: #efb44a;
  margin-left: 20px;
}
.english .section-1 .nav .text .chinese {
  font-size: 40px;
  font-weight: 900;
  line-height: 40px;
  letter-spacing: 15px;
}
.english .section-1 .nav .text .english {
  font-size: 15px;
  line-height: 20px;
}
.english .section-1 .nav .nav-menu {
  display: inline-flex;
  justify-content: space-between;
  width: 35%;
  margin-left: 16%;
  color: white;
  font-size: 20px;
  font-weight: 500;
}
.english .section-1 .nav .nav-menu div {
  cursor: pointer;
}
.english .section-1 .content-wrapper {
  position: absolute;
  top: 254px;
  left: 50%;
  transform: translateX(-50%);
  width: 1175px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  text-align: center;
}
.english .section-1 .content-wrapper .item {
  width: 49%;
  background-color: white;
  border-radius: 18px;
  text-align: center;
  padding: 51px 0;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.03);
}
.english .section-1 .content-wrapper .item .text-1 {
  font-weight: 600;
  font-size: 38px;
  line-height: 128%;
  color: #1f1f1f;
}
.english .section-1 .content-wrapper .item .text-4 {
  font-size: 16px;
  font-weight: 900;
  color: #1f1f1f;
}
.english .section-1 .content-wrapper .item .line {
  width: 456px;
  border-top: 1px solid #d6d6d6;
  margin: 15px auto;
}
.english .section-1 .content-wrapper .item .text-2 img {
  width: 360px;
}
.english .section-1 .content-wrapper .item .items-wrapper {
  margin-top: 35px;
}
.english .section-1 .content-wrapper .item .items-wrapper div {
  font-size: 24px;
  line-height: 184.8%;
  font-weight: 600;
  letter-spacing: 0.24em;
  color: white;
  background-color: #fbad22;
  padding: 15px 20px;
  border-radius: 55px;
  width: 349px;
  margin: 0 auto;
  margin-top: 10px;
  text-align: left;
}
.english .section-1 .content-wrapper .item .items-wrapper div > :first-child {
  margin-right: 10px;
}
.english .section-1 .content-wrapper .item .member-wrapper {
  width: 410px;
  margin: 0 auto;
  margin-top: 60px;
  text-align: left;
}
.english .section-1 .content-wrapper .item .member-wrapper img {
  width: 36.8px;
  height: 36.8px;
  margin-top: -15px;
  border-radius: 50%;
}
.english .section-1 .content-wrapper .item .member-wrapper .omit {
  display: inline-block;
  font-size: 25px;
  margin-top: -20px;
}
.english .section-1 .content-wrapper .item .text-3 {
  font-size: 24px;
  font-weight: 700;
  color: #fbad22;
  margin-top: 28px;
}
.english .section-1 .content-wrapper .item .common-btn img {
  width: 211px;
  margin-top: 35px;
  margin-left: 25px;
}
.english .section-1 .content-wrapper > :not(:first-child) .items-wrapper div > :first-child {
  margin-left: 70px;
}
.english .section-1 .white-block {
  border-top-left-radius: 103px;
  border-top-right-radius: 103px;
  background-color: #f9f9f9;
  height: 52px;
  margin-top: 366px;
}
.english .section-2 {
  background-color: #f9f9f9;
  text-align: center;
  padding: 670px 0 0 0;
}
.english .section-2 > :first-child {
  width: 71px;
  height: 71px;
  margin: 0 auto;
}
.english .section-2 .phone-table-wrapper {
  display: none;
}
.english .section-2 .table-wrapper {
  width: 88.9%;
  margin: 0 auto;
  margin-top: 51px;
  padding: 65px 25px 85px 25px;
  border-radius: 40px;
  font-size: 20px;
  line-height: 24px;
}
.english .section-2 .table-wrapper > div {
  display: flex;
  align-items: center;
  padding: 20px 0;
}
.english .section-2 .table-wrapper > div img {
  width: 114px;
}
.english .section-2 .table-wrapper > div > div {
  width: 200px;
}
.english .section-2 .table-wrapper > div > div > div:last-child {
  font-size: 16px;
  margin-top: 10px;
}
.english .section-2 .table-wrapper .big {
  width: 240px;
}
.english .section-2 .table-wrapper > div:not(:first-child) {
  background-color: white;
  border-radius: 22px;
  margin-top: 20px;
}
.english .section-2 .table-wrapper > div:first-child {
  color: white;
  font-weight: 600px;
  line-height: 24px;
  font-size: 24px;
}
.english .section-3 .text-1 {
  text-align: center;
  margin-top: 67px;
}
.english .section-3 .text-1 img {
  width: 192px;
}
.english .section-3 .text-2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  color: #9a9a9a;
  margin-top: 15px;
  text-align: center;
}
.english .section-3 .my-swiper {
  position: relative;
  overflow: hidden;
  margin-top: 56px;
  padding-top: 56px;
  padding-bottom: 76px;
  background-color: #f2f2f2;
}
.english .section-3 .my-swiper .cover-img-l {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  --tw-blur: blur(40px);
  pointer-events: none;
}
.english .section-3 .my-swiper .cover-img-r {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  transform: rotate(180deg);
  pointer-events: none;
}
.english .section-3 .carousels-web {
  display: block;
}
.english .section-3 .carousels-phone {
  display: none;
}
.english .section-3 .carousels .img-wrapper {
  display: flex;
  width: 100%;
  height: 120px !important;
  border: 4px solid white;
  border-radius: 24px;
  box-sizing: border-box;
  overflow: hidden;
  align-items: middle;
}
.english .section-3 .carousels .img-wrapper img {
  width: 100%;
  object-fit: cover;
  transform: scale(1.1);
  flex: 1;
}
.english .section-3 .image-view {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(17, 24, 39, 0.4);
  z-index: 10;
}
.english .section-3 .image-view .wrapper {
  transform: scale(0.75);
  position: relative;
}
.english .section-3 .image-view .mobile-image-item-wrapper img {
  width: 98vw;
}
.english .section-3 .image-view .web-image-item-wrapper img {
  height: 100vh;
}
.english .section-3 .image-view .image-item-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.english .section-3 .image-view .close {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  font-size: 48px;
  color: white;
  border-radius: 50%;
  background-color: rgba(249, 250, 251, 0.4);
  width: 48px;
  height: 48px;
  text-align: center;
  margin-top: 5px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.english .section-3 .image-view .close div {
  position: absolute;
  top: 50%;
  transform: translateY(-55%);
}
.english .swiper-button-prev {
  background-image: url("https://duckfiles.oss-cn-qingdao.aliyuncs.com/images/english/button-pre.png") !important;
  width: 52px !important;
  height: 52px !important;
  position: fixed !important;
  background-size: 100% 100%;
}
.english .swiper-button-next {
  background-image: url("https://duckfiles.oss-cn-qingdao.aliyuncs.com/images/english/button-next.png") !important;
  width: 52px !important;
  height: 52px !important;
  position: fixed !important;
  background-size: 100% 100%;
}
.english .swiper-button-prev:after {
  content: "none" !important;
}
.english .swiper-button-next:after {
  content: "none" !important;
}
.english .swiper-button-next.swiper-button-disabled,
.english .swiper-button-prev.swiper-button-disabled {
  pointer-events: auto !important;
}
.english .section-4 {
  text-align: center;
  margin-top: 67px;
  padding: 36px 0;
  background-color: #323639;
}
.english .section-4 .left {
  display: inline-block;
}
.english .section-4 .logo {
  display: inline-block;
  vertical-align: middle;
}
.english .section-4 .v-line {
  height: 56px;
  background-color: #6b7280;
  vertical-align: middle;
  margin: 36px 0;
  width: 1px;
  display: inline-block;
  margin-left: 60px;
}
.english .section-4 .h-line {
  display: none;
}
.english .section-4 .slogan {
  font-size: 14px;
  line-height: 30px;
  display: inline-block;
  vertical-align: middle;
  color: #9b9b9b;
  width: 384px;
  margin-left: 36px;
  text-align: left;
}
.english .section-4 .right {
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  margin-left: 60px;
}
.english .section-4 .right .copy-right {
  font-size: 14px;
  line-height: 20px;
  color: #9b9b9b;
  text-align: left;
  display: inline-block;
}
.english .section-4 .right span:not(:first-child) {
  display: inline-block;
  line-height: 2;
  margin-left: 48px;
}
.english .my-popup {
  width: 100px;
  padding: 10px 0;
}
.english .my-popup > div:not(:first-child) {
  margin-top: 10px;
}
@media screen and (max-width: 1175px) {
  .english .section-1 .nav {
    width: 95%;
  }
  .english .section-1 .nav .nav-menu {
    display: none;
  }
  .english .section-1 .nav .nav-menu-icon {
    display: inline-block;
  }
  .english .section-1 .nav .nav-menu-icon img {
    width: 30px;
  }
  .english .section-1 .content-wrapper {
    width: 95%;
    top: 230px;
  }
  .english .section-1 .content-wrapper .item .line {
    width: 95%;
  }
  .english .section-1 .content-wrapper .item .member-wrapper {
    width: 90%;
  }
  .english .section-1 .content-wrapper > :not(:first-child) .items-wrapper div > :first-child {
    margin-left: 25px;
  }
  .english .section-1 .white-block {
    margin-top: 120px;
  }
}
@media screen and (max-width: 820px) {
  .english .section-2 {
    padding: 870px 0 0 0;
  }
}
@media screen and (max-width: 768px) {
  .english .section-1 {
    padding-top: 30px;
  }
  .english .section-1 .nav {
    padding: 14px 20px;
  }
  .english .section-1 .nav img {
    width: 140px;
  }
  .english .section-1 .nav .nav-menu-icon {
    display: inline-block;
  }
  .english .section-1 .nav .nav-menu-icon img {
    width: 22px;
  }
  .english .section-1 .nav .text {
    margin-left: 12px;
  }
  .english .section-1 .nav .text .chinese {
    font-size: 25px;
    line-height: 25px;
    letter-spacing: 11px;
  }
  .english .section-1 .nav .text .english {
    font-size: 10px;
    line-height: 12px;
  }
  .english .section-1 .nav .nav-menu {
    display: none;
  }
  .english .section-1 .content-wrapper {
    width: 95%;
    top: 130px;
  }
  .english .section-1 .content-wrapper .item {
    padding: 30px 0;
  }
  .english .section-1 .content-wrapper .item .text-1 {
    font-size: 18px;
    line-height: 20px;
  }
  .english .section-1 .content-wrapper .item .text-4 {
    font-size: 10px;
    line-height: 20px;
  }
  .english .section-1 .content-wrapper .item .text-2 img {
    width: 90%;
  }
  .english .section-1 .content-wrapper .item .line {
    width: 95%;
    margin: 10px auto;
  }
  .english .section-1 .content-wrapper .item .items-wrapper {
    margin-top: 20px;
  }
  .english .section-1 .content-wrapper .item .items-wrapper div {
    font-size: 12px;
    padding: 7px 0px 7px 10px;
    width: 95%;
  }
  .english .section-1 .content-wrapper .item .items-wrapper div > :first-child {
    width: 12px;
    margin-right: 6px;
  }
  .english .section-1 .content-wrapper .item .member-wrapper {
    width: 90%;
    margin: 0 auto;
    margin-top: 28px;
    line-height: 9px;
  }
  .english .section-1 .content-wrapper .item .member-wrapper img {
    width: 14px;
    height: 14px;
  }
  .english .section-1 .content-wrapper .item .member-wrapper .omit {
    display: none;
  }
  .english .section-1 .content-wrapper .item .text-3 {
    font-size: 10px;
    margin-top: 8px;
  }
  .english .section-1 .content-wrapper .item .common-btn img {
    margin: 0 auto;
    margin-top: 10px;
    width: 85%;
  }
  .english .section-1 .content-wrapper .item > :not(:first-child) .items-wrapper div > :first-child {
    margin-left: 25px;
  }
  .english .section-2 {
    padding: 387px 0 0 0;
  }
  .english .section-2 > :first-child {
    width: 50px;
    height: 50px;
  }
  .english .section-2 .table-wrapper {
    display: none;
  }
  .english .section-2 .phone-table-wrapper {
    display: inline-block;
    width: 95%;
    margin: 0 auto;
    position: relative;
    margin-top: 15px;
  }
  .english .section-2 .phone-table-wrapper .mid {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffab14;
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.03);
    border-radius: 18px;
    font-size: 12px;
    top: 75px;
    color: white;
    padding: 15px 0 35px 0;
    width: 26%;
  }
  .english .section-2 .phone-table-wrapper .mid div {
    margin-top: 20px;
  }
  .english .section-2 .phone-table-wrapper .items-wrapper {
    font-size: 12px;
    color: #7d7d7d;
    display: flex;
    justify-content: space-between;
  }
  .english .section-2 .phone-table-wrapper .items-wrapper .item {
    width: 40%;
    background-color: white;
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.03);
    border-radius: 18px;
    padding: 20px 0;
  }
  .english .section-2 .phone-table-wrapper .items-wrapper .item img {
    width: 100px;
  }
  .english .section-2 .phone-table-wrapper .items-wrapper .item div {
    margin-top: 20px;
  }
  .english .section-2 .phone-table-wrapper .items-wrapper > :not(:first-child) > div img {
    width: 90px;
    padding-bottom: 7px;
  }
  .english .section-3 .text-1 {
    margin-top: 40px;
  }
  .english .section-3 .text-1 img {
    width: 25%;
  }
  .english .section-3 .text-2 {
    font-size: 10px;
    margin-top: 0px;
  }
  .english .section-3 .my-swiper {
    margin-top: 16px;
    padding-top: 26px;
    padding-bottom: 46px;
  }
  .english .section-3 .my-swiper .carousels-web {
    display: none;
  }
  .english .section-3 .my-swiper .carousels-phone {
    display: block;
  }
  .english .section-4 {
    padding: 40px 16px;
    margin-top: 20px;
  }
  .english .section-4 .left {
    display: block;
    text-align: left;
  }
  .english .section-4 .left div {
    display: inline-block;
  }
  .english .section-4 .left .slogan {
    width: 100%;
    margin-left: 0px;
    text-align: justify;
  }
  .english .section-4 .v-line {
    display: none;
  }
  .english .section-4 .h-line {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #6b7280;
    margin: 24px 0;
  }
  .english .section-4 .right {
    display: block;
    margin-left: 0;
    text-align: left;
  }
  .english .section-4 .right .copy-right span {
    display: block;
    margin-left: 0;
  }
  .english .swiper-button-prev,
  .english .swiper-button-next {
    display: none !important;
  }
}
@font-face {
  font-family: "DINPro";
  src: url("../assets/web3/DINPro.ttf");
  font-weight: 500;
  font-style: normal;
}
.web3 {
  font-family: "DINPro", "Helvetica";
  background-position-y: 800px;
}
.web3 .btn {
  padding: 9px 16px;
  margin-right: 20px;
  font-size: 16px;
  font-weight: 500;
  background: linear-gradient(90deg, #ffba3a 0%, #eec06a 100%);
  color: white;
  border: 2px solid #ffba3b;
  letter-spacing: 0.8px;
  cursor: pointer;
}
.web3 .btn.trans {
  padding: 9px 10px;
  background: transparent;
}
.web3 .title {
  display: inline-flex;
  padding-right: 65px;
  margin-bottom: 74px;
  border-bottom: 2px solid #ffb939;
}
.web3 .title h1 {
  font-size: 56px;
  font-weight: 500;
  line-height: 74px;
}
.web3 .title h2 {
  font-size: 42px;
  font-weight: 400;
  color: #4b4b4b;
  margin-top: 15px;
  margin-left: 24px;
}
.web3 .header {
  display: flex;
  background-color: #000;
  height: 75px;
  padding: 15px 135px;
  border-bottom: 1px solid #ffba3b;
  font-size: 16px;
}
.web3 .header ul > li {
  list-style: none;
}
.web3 .header ul {
  display: flex;
  margin: 0;
  padding: 0;
  margin-left: 32px;
}
.web3 .header ul > li {
  padding: 0 24px;
  height: 100%;
}
.web3 .header ul > li > a {
  opacity: 0.8;
  color: #f7f7f7;
  line-height: 48px;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.web3 .header ul > li > a.active {
  opacity: 1;
  color: #ffba3b;
}
.web3 .section-1 {
  background-size: cover;
  background-position-y: -75px;
  padding: 100px;
  padding-left: 25%;
  color: white;
}
.web3 .section-1 h1 {
  color: white;
  font-weight: 500;
  font-size: 60px;
  margin-bottom: 0;
}
.web3 .section-1 h1 span {
  font-family: "DINPro", "Helvetica";
  font-weight: 600;
  color: #ffba3b;
}
.web3 .section-1 .p1 {
  margin-top: 10px;
  font-size: 16px;
  line-height: 21px;
}
.web3 .section-1 .p2 {
  width: 650px;
  font-weight: 400;
  font-size: 14px;
  color: #f7f7f7;
  line-height: 25px;
}
.web3 .section-1 .buttons {
  display: flex;
  margin-top: 40px;
}
.web3 .section-1 .socials {
  margin-top: 30px;
}
.web3 .section-1 .socials img {
  margin-right: 30px;
}
.web3 .section-2 {
  display: flex;
  background-size: cover;
  height: 176px;
  padding: 0 130px;
  justify-content: space-around;
}
.web3 .section-2 .stats {
  margin-top: 26px;
  color: #f7f7f7;
  text-align: center;
}
.web3 .section-2 .stats .num {
  font-weight: 900;
  font-size: 50px;
  line-height: 64px;
}
.web3 .section-2 .stats .name,
.web3 .section-2 .stats .name-en {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
}
.web3 .section-3 {
  position: relative;
  padding: 110px 138px;
  border-bottom: 1px solid #ffb939;
}
.web3 .section-3 .logo-bg {
  position: absolute;
  top: -20px;
  right: 20px;
  height: 201px;
}
.web3 .section-3 .post {
  display: block;
  background-color: #ffffff;
  margin-bottom: 24px;
  color: #333333;
  padding: 22px 24px;
  font-size: 22px;
  font-weight: 500;
  transition: none;
}
.web3 .section-3 .post:hover {
  color: #ffffff;
  background-color: #ffba3a;
}
.web3 .section-3 .more {
  margin: 0 auto;
  margin-top: 44px;
  margin-bottom: 128px;
  width: 211px;
  text-align: center;
}
.web3 .section-3 .desc {
  margin-bottom: 60px;
}
.web3 .section-3 .desc p {
  font-size: 28px;
  line-height: 36px;
  margin: 0;
}
.web3 .section-3 .services {
  display: grid;
  grid-template-columns: 50% 50%;
  row-gap: 80px;
  column-gap: 40px;
  margin-bottom: 150px;
}
.web3 .section-3 .service {
  display: flex;
  width: 510px;
  font-size: 22px;
  line-height: 24px;
}
.web3 .section-3 .service img {
  width: 130px;
  height: 130px;
  margin-right: 50px;
}
.web3 .section-3 .service .t1 {
  font-weight: 500;
}
.web3 .section-3 .service .t2 {
  margin-top: 5px;
  font-weight: 400;
}
.web3 .section-3 .service .t3,
.web3 .section-3 .service .t4 {
  font-size: 18px;
}
.web3 .section-3 .service .t3 {
  margin-top: 20px;
}
.web3 .section-3 .service .t4 {
  margin-top: 10px;
}
.web3 .section-3 .partner {
  margin: 10px 30px;
  height: 125px;
}
.web3 .section-4 {
  padding: 72px 0 66px 0;
  text-align: center;
  color: #333333;
}
.web3 .section-4 p {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  margin: 0px;
  padding: 0px;
}
.web3 .section-4 a:visited {
  color: inherit;
}
.web3 .section-4 .s {
  margin: 0 15px;
  margin-top: 26px;
}
@media screen and (max-width: 768px) {
  .web3 .title {
    display: inline-flex;
    padding-right: 15px;
    margin-bottom: 15px;
    border-bottom: 2px solid #ffb939;
  }
  .web3 .title h1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
  }
  .web3 .title h2 {
    font-size: 16px;
    margin-top: 5px;
    margin-left: 10px;
  }
  .web3 .header {
    padding: 15px 15px;
  }
  .web3 .header .mb-hidden {
    display: none;
  }
  .web3 .section-1 {
    padding: 20px;
  }
  .web3 .section-1 h1 {
    font-size: 50px;
  }
  .web3 .section-1 h1 #web3-typewriter {
    display: block;
  }
  .web3 .section-1 .p2 {
    width: auto;
  }
  .web3 .section-1 .btn {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: inherit;
  }
  .web3 .section-1 .buttons {
    margin-top: 20px;
  }
  .web3 .section-1 .socials {
    margin-top: 20px;
  }
  .web3 .section-1 .socials .mb-hidden {
    display: none;
  }
  .web3 .section-1 .logo {
    width: 100px;
  }
  .web3 .section-2 {
    padding: 0 15px;
  }
  .web3 .section-2 .stats {
    margin-top: 24px;
    color: #f7f7f7;
    text-align: center;
  }
  .web3 .section-2 .stats .num {
    font-weight: 900;
    font-size: 40px;
    line-height: 64px;
  }
  .web3 .section-2 .stats .name,
  .web3 .section-2 .stats .name-en {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
  }
  .web3 .section-3 {
    padding: 15px;
    padding-top: 40px;
  }
  .web3 .section-3 .post {
    font-size: 16px;
    padding: 12px 14px;
    margin-bottom: 12px;
  }
  .web3 .section-3 .more {
    margin-top: 22px;
    margin-bottom: 40px;
  }
  .web3 .section-3 .desc {
    margin-bottom: 20px;
  }
  .web3 .section-3 .desc p {
    font-size: 18px;
    line-height: 28px;
    margin: 0;
  }
  .web3 .section-3 .services {
    grid-template-columns: 100%;
    row-gap: 0px;
    column-gap: 0px;
    margin-bottom: 40px;
  }
  .web3 .section-3 .logo-bg {
    top: -10px;
    right: 0px;
    height: 60px;
  }
  .web3 .section-3 .service {
    display: flex;
    width: auto;
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 20px;
  }
  .web3 .section-3 .service img {
    width: 80px;
    height: 80px;
    margin-right: 20px;
  }
  .web3 .section-3 .service .t2 {
    margin-top: 5px;
  }
  .web3 .section-3 .service .t3,
  .web3 .section-3 .service .t4 {
    font-size: 16px;
  }
  .web3 .section-3 .service .t3 {
    margin-top: 6px;
  }
  .web3 .section-3 .service .t4 {
    margin-top: 4px;
  }
  .web3 .section-3 .partner {
    height: auto;
    width: 33%;
  }
}
body {
  margin: 0;
  padding: 0;
  background-color: #f3f3f3;
  color: #3d3d3d;
}
div,
img {
  box-sizing: border-box;
}
.wrapper {
  margin-top: -20px;
}
.wrapper ul {
  list-style: none;
}
.wrapper ul li::before {
  content: "\2022";
  color: #ffbb34;
  font-weight: bold;
  display: inline-block;
  width: 20px;
  margin-left: -1em;
}
.wrapper .tag {
  width: 141px;
  height: 72px;
  background-color: #f9ba48;
  position: relative;
  padding-left: 11px;
  margin-top: 60px;
  font-size: 32px;
  font-weight: 500;
  line-height: 72px;
  font-weight: 600;
}
.wrapper .tag::before {
  content: "";
  position: absolute;
  left: 101px;
  border-style: solid;
  border-width: 0 40px 80px 0;
  border-color: transparent white transparent transparent;
}
.wrapper .title {
  text-align: center;
}
.wrapper .title * {
  display: inline-block;
  vertical-align: middle;
}
.wrapper .title img {
  width: 32px;
  height: 31px;
}
.wrapper .title div {
  margin: 0 30px;
  font-size: 42px;
  font-weight: 600;
  color: #242424;
}
.wrapper .block1 {
  background-color: white;
}
.wrapper .block1 .nav {
  width: 1200px;
  margin: 0 auto;
  padding: 16px 0;
  background-color: white;
}
.wrapper .block1 .nav * {
  display: inline-block;
  vertical-align: middle;
}
.wrapper .block1 .nav .nav-left img {
  width: 147px;
  height: 47.7px;
}
.wrapper .block1 .nav .nav-left .line {
  width: 1px;
  height: 36px;
  background: #343434;
  margin: 0 25px;
}
.wrapper .block1 .nav .nav-left .text {
  font-size: 24px;
}
.wrapper .block1 .nav .nav-right-pc {
  margin-left: 160px;
  font-size: 20px;
}
.wrapper .block1 .nav .nav-right-pc span {
  padding: 0 20px;
  cursor: pointer;
}
.wrapper .block1 .nav .nav-right-mb {
  display: none;
}
.wrapper .block1 .header {
  width: 100%;
  background-color: #f9ba48;
  padding: 37px;
}
.wrapper .block1 .header .content {
  width: 1200px;
  margin: 0 auto;
}
.wrapper .block1 .header .content .left {
  display: inline-block;
  vertical-align: middle;
}
.wrapper .block1 .header .content .left .text-pc {
  font-size: 68px;
}
.wrapper .block1 .header .content .left .text-mb {
  display: none;
}
.wrapper .block1 .header .content .left .text-en {
  font-size: 60px;
  font-weight: 500;
  margin-top: 14px;
}
.wrapper .block1 .header .content .left .text-en-small {
  font-size: 40px;
  line-height: 40px;
}
.wrapper .block1 .header .content .left .consult {
  width: 201px;
  height: 58px;
  line-height: 58px;
  font-size: 24px;
  font-weight: 500;
  color: white;
  border-radius: 10px;
  text-align: center;
  background-color: #3d3d3d;
  margin-top: 32px;
  cursor: pointer;
}
.wrapper .block1 .header .content .header-img {
  display: inline-block;
  width: 336px;
  vertical-align: middle;
  margin-left: 235px;
}
.wrapper .block2 {
  padding: 45px 0 58px 0;
  background-color: white;
}
.wrapper .block2 .item-w {
  display: flex;
  justify-content: space-around;
  width: 1200px;
  margin: 80px auto 0;
}
.wrapper .block2 .item-w .item {
  position: relative;
  width: 356px;
  font-size: 20px;
  text-align: center;
  padding: 70px 0 20px 0;
  border: 1px solid #f9ba48;
  border-radius: 10px;
}
.wrapper .block2 .item-w .item span {
  display: block;
}
.wrapper .block2 .item-w .item img {
  position: absolute;
  width: 96px;
  left: 50%;
  top: -35%;
  transform: translateX(-50%);
}
.wrapper .block3 {
  background-color: white;
  padding: 29px 0;
}
.wrapper .block3 .content {
  width: 1200px;
  margin: 0 auto;
}
.wrapper .block3 .content .panel {
  background-color: #f3f3f3 !important;
  padding-bottom: 24px;
  margin-top: 33px;
}
.wrapper .block3 .content .remove {
  display: none !important;
}
.wrapper .block3 .content .add {
  display: block;
}
.wrapper .block3 .content ul {
  margin-bottom: 0px;
}
.wrapper .block3 .content .detail {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 156px;
  height: 51px;
  line-height: 51px;
  border-radius: 25px;
  border: 1px solid black;
  font-size: 20px;
  font-weight: 500;
  gap: 10px;
  margin-right: 30px;
  cursor: pointer;
}
.wrapper .block3 .content .detail img {
  width: 11px;
  vertical-align: middle;
}
.wrapper .block3 .content .list-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 12px;
}
.wrapper .block3 .content .list-wrapper .list-i {
  width: 500px;
}
.wrapper .block3 .content .course-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrapper .block3 .content .course-title .course-title-left * {
  display: inline-block;
  vertical-align: middle;
}
.wrapper .block3 .content .course-title .line {
  width: 10px;
  height: 73px;
  background-color: #f9ba48;
  margin-right: 40px;
}
.wrapper .block3 .content .course-title .course-title-text {
  position: relative;
  font-size: 32px;
  font-weight: 500;
}
.wrapper .block3 .content .course-title .days {
  position: relative;
  width: 88px;
  height: 44px;
  text-align: center;
  margin-left: 40px;
}
.wrapper .block3 .content .course-title .days img {
  width: 88px;
}
.wrapper .block3 .content .course-title .days .icon-mp {
  display: none;
}
.wrapper .block3 .content .course-title .days div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 26px;
  font-weight: 500;
  width: 100px;
}
.wrapper .block3 .content .course-title .arrow-down {
  transform: rotate(90deg);
}
.wrapper .block3 .content .course-time {
  padding-top: 20px;
  padding-left: 35px;
  border-top: 1px solid #fff;
}
.wrapper .block3 .content .course-time span:first-child {
  font-size: 24px;
  font-weight: 500;
}
.wrapper .block3 .content .course-time span:last-child {
  font-size: 20px;
  padding-left: 36px;
}
.wrapper .block3 .content .course-core-text {
  font-size: 24px;
  font-weight: 500;
  padding-top: 14px;
  padding-left: 35px;
}
.wrapper .block3 .course-list-title {
  font-size: 20px;
  font-weight: 500;
  background-color: #f9ba48;
  width: 500px;
  height: 40px;
  line-height: 40px;
  padding: 0 26px;
  border-radius: 30px;
  margin-top: 16px;
}
.wrapper .block3 .course-list-title-sm {
  font-size: 15px;
}
.wrapper .block3 .course-list {
  font-size: 18px;
  width: 500px;
}
.wrapper .block3 .course-list li {
  margin-top: 13px;
}
.wrapper .block3 .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0 0 !important;
}
.wrapper .block3 .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 0 !important;
}
.wrapper .block3 .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 0 !important;
}
.wrapper .block4 {
  background-color: #f9ba48;
  padding: 36px 0;
}
.wrapper .block4 .content {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
  align-items: center;
}
.wrapper .block4 .content .content-left div {
  display: flex;
  margin: 12px 0;
  width: 730px;
}
.wrapper .block4 .content .content-left div img:first-child {
  display: none;
}
.wrapper .block4 .content .content-left img {
  width: 22px;
  height: 20px;
  margin: 6px 14px 0 0;
}
.wrapper .block4 .content .content-left p {
  margin-bottom: 0;
  font-size: 20px;
}
.wrapper .block4 .content .content-right {
  width: 297px;
  height: 361px;
  margin-left: 150px;
}
.wrapper .block5 {
  padding: 55px 0;
  background-color: white;
}
.wrapper .block5 .content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1200px;
  margin: 0 auto;
}
.wrapper .block5 .content .item {
  position: relative;
  width: 574px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1);
  border-width: 1px 1px 1px 5px;
  border-color: #f9ba48;
  border-style: solid;
  border-radius: 10px;
  margin-top: 46px;
  padding: 5px 34px;
}
.wrapper .block5 .content .item span:first-of-type {
  font-size: 42px;
  font-weight: 500;
  padding-right: 10px;
}
.wrapper .block5 .content .item span:last-of-type {
  font-size: 24px;
  font-weight: 400;
}
.wrapper .block5 .content .item p {
  font-size: 16px;
  color: #474747;
  text-align: justify;
}
.wrapper .block5 .content .arrow {
  position: absolute;
  width: 61px;
  height: 19px;
  top: 25px;
  right: 40px;
}
.wrapper .block5 .content .p1 {
  display: none;
}
.wrapper .block6 {
  padding: 50px 0;
}
.wrapper .block6 .content {
  width: 1200px;
  margin: 0 auto;
}
.wrapper .block6 .content .block {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
}
.wrapper .block6 .content .block .item {
  position: relative;
  width: 394px;
  height: 185px;
  background-color: white;
  border: 2px solid #f9ba48;
  border-radius: 10px;
  padding: 37px 12px 0 12px;
}
.wrapper .block6 .content .block .item div:first-child {
  position: absolute;
  width: 181px;
  height: 54px;
  background-color: #f9ba48;
  border-radius: 163px;
  font-size: 26px;
  font-weight: 600;
  line-height: 54px;
  text-align: center;
  left: 50%;
  top: -12%;
  transform: translateX(-50%);
}
.wrapper .block6 .content .block .item p {
  font-size: 16px;
  margin-bottom: 0;
}
.wrapper .block6 .content .block .item p span:first-child {
  padding-right: 10px;
}
.wrapper .block6 .content .en {
  display: none;
}
.wrapper .block6 .content .list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.wrapper .block6 .content .list div {
  width: 585px;
  border: 2px solid #f9ba48;
  border-radius: 10px;
  font-size: 26px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: 30px;
  background-color: white;
  padding: 18px;
}
.wrapper .block6 .content .list span {
  padding-right: 10px;
}
.wrapper .block6 .content .p6,
.wrapper .block6 .content .p7 {
  display: none;
}
.wrapper .block6 .p3 {
  display: block;
  width: 1200px;
  margin: 20px auto 0;
}
.wrapper .block7 {
  background-color: white;
  padding: 70px 0;
}
.wrapper .block7 .content {
  width: 1200px;
  margin: 23px auto 0;
}
.wrapper .block7 .content .about {
  width: 1200px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
  background-color: #f3f3f3;
  padding: 10px 30px;
}
.wrapper .block7 .content .tag-big {
  width: 351px;
}
.wrapper .block7 .content .tag-big::before {
  left: 311px;
}
.wrapper .block7 .content .item-w {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  margin-top: 27px;
}
.wrapper .block7 .content .item-w .item .item-title div {
  display: inline-block;
  vertical-align: middle;
}
.wrapper .block7 .content .item-w .item .item-title .line {
  width: 7px;
  height: 48px;
  background-color: #f9ba48;
}
.wrapper .block7 .content .item-w .item .item-title .item-title-text {
  font-size: 20px;
  font-weight: 500;
  width: 380px;
  margin-left: 10px;
}
.wrapper .block7 .content .item-w .item .item-content {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}
.wrapper .block7 .content .item-w .item .item-content ul {
  padding-left: 16px;
}
.wrapper .block7 .content .item-w .item .item-content ul li {
  margin-top: 10px;
}
.wrapper .block7 .content .item-w .item .item-content .p4 {
  width: 273px;
  height: 123px;
}
.wrapper .block7 .content .item-w .item .item-content .p5 {
  width: 257px;
}
.wrapper .block7 .content .qa-item-w {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
  gap: 37px;
}
.wrapper .block7 .content .qa-item-w .item {
  width: 579px;
  background-color: #f3f3f3;
  border-radius: 10px;
  border-left: 2px solid #f9ba48;
  padding: 12px 10px 30px 10px;
}
.wrapper .block7 .content .qa-item-w .item div {
  font-size: 20px;
  font-weight: 600;
  line-height: 33px;
}
.wrapper .block7 .content .qa-item-w .item p {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 0px;
  line-height: 26px;
}
.wrapper .block8 {
  background-color: #f9ba48;
  padding: 19px 0 43px 0;
}
.wrapper .block8 .content {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
}
.wrapper .block8 .content .text1 {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  margin-top: 22px;
}
.wrapper .block8 .content .tag {
  display: none;
}
.wrapper .block8 .content .item-w {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 30px;
  font-size: 19px;
  column-gap: 35px;
  row-gap: 20px;
}
.wrapper .block8 .content .item-w div {
  width: 576px;
  border-radius: 20px;
  border: 1px solid #000;
  line-height: 25px;
  padding: 7px 0;
}
.wrapper .block8 .content .item-w div:last-child {
  background-color: #3d3d3d;
  color: white;
}
.wrapper .block9 {
  background-color: #3d3d3d;
  text-align: center;
  padding: 30px 0;
}
.wrapper .block9 .title-text-mp {
  display: none;
}
.wrapper .block9 div {
  color: white !important;
}
.wrapper .block9 .text1 {
  font-size: 24px;
  font-weight: 500;
  margin-top: 15px;
}
.wrapper .block9 .contact {
  width: 182px;
  margin-top: 30px;
}
.menu-w {
  opacity: 0;
  position: fixed;
  width: 100px;
  background-color: #474747;
  border-radius: 10px;
  padding: 30px 5px;
  top: 400px;
  left: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.menu-w img {
  width: 76px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -10%;
}
.menu-w .menu a {
  display: inline-block;
  width: 90px;
  height: 34px;
  line-height: 34px;
  background-color: #f9ba48;
  text-align: center;
  border-radius: 20px;
  margin: 16px auto 0;
  color: #3d3d3d;
  cursor: pointer;
}
.menu-w .menu a:hover {
  background-color: #ffd994;
}
.menu-w .menu a:active {
  background-color: #d99418;
}
.show {
  opacity: 1;
  transition: opacity 0.5s ease;
}
.hide {
  opacity: 0;
  transition: opacity 0.5s ease;
}
@media screen and (max-width: 1175px) {
  .wrapper .tag {
    width: 108px;
    height: 30px;
    margin-top: 15px;
    font-size: 16px;
    line-height: 30px;
    text-align: left;
  }
  .wrapper .tag::before {
    left: 90px;
    top: -1px;
    border-width: 0 19px 36px 0;
    border-color: transparent #f3f3f3 transparent transparent;
  }
  .wrapper .title div {
    font-size: 23px;
    margin: 0 10px;
    font-weight: 500;
  }
  .wrapper .title img {
    width: 18px;
    height: 18.5px;
  }
  .wrapper .block1 .nav {
    position: relative;
    width: 100%;
    padding: 27px 16px 14px 16px;
  }
  .wrapper .block1 .nav .nav-left img {
    width: 89px;
    height: 28px;
  }
  .wrapper .block1 .nav .nav-left .line {
    height: 18px;
    margin: 10px;
  }
  .wrapper .block1 .nav .nav-left .text {
    font-size: 14px;
  }
  .wrapper .block1 .nav .nav-right-pc {
    display: none;
  }
  .wrapper .block1 .nav .nav-right-mb {
    width: 50px;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
  }
  .wrapper .block1 .header {
    padding: 20px 20px;
  }
  .wrapper .block1 .header .content {
    width: 100%;
  }
  .wrapper .block1 .header .content .left .text-pc {
    display: none;
  }
  .wrapper .block1 .header .content .left .text-mb {
    display: block;
    font-size: 42px;
    line-height: 46px;
    font-weight: 600;
    width: 210px;
  }
  .wrapper .block1 .header .content .left .text-en {
    font-size: 20px;
    font-weight: 400;
    margin-top: 20px;
  }
  .wrapper .block1 .header .content .left .text-en-small {
    font-size: 15px;
    line-height: 15px;
    font-weight: 400;
  }
  .wrapper .block1 .header .content .header-img {
    width: 105.5px;
    vertical-align: middle;
    margin-left: 12px;
    margin-top: 20px;
  }
  .wrapper .block1 .header .content .consult {
    display: none;
  }
  .wrapper .block2 {
    padding: 30px 5px;
    background-color: #f3f3f3;
  }
  .wrapper .block2 .item-w {
    margin-top: 35px;
    width: 100%;
  }
  .wrapper .block2 .item-w .item {
    padding: 30px 5px 0 5px;
    width: 30%;
    height: 112.5px;
    font-size: 11px;
    text-align: justify;
    border: none;
    border-radius: 0%;
    background-color: white;
  }
  .wrapper .block2 .item-w .item span {
    display: inline;
  }
  .wrapper .block2 .item-w .item img {
    width: 49px;
    height: 49px;
    top: -20%;
  }
  .wrapper .block3 {
    background-color: #f3f3f3 !important;
    padding: 0 0 15px 0;
  }
  .wrapper .block3 .content {
    margin-top: 15px;
    width: 100%;
    background-color: #f3f3f3 !important;
  }
  .wrapper .block3 .content .panel {
    padding: 15px;
    background-color: white !important;
    margin-top: 17px;
  }
  .wrapper .block3 .content .ant-collapse {
    background-color: #f3f3f3 !important;
  }
  .wrapper .block3 .content .remove {
    display: none;
  }
  .wrapper .block3 .content .detail {
    width: 78px;
    height: 22px;
    line-height: 22px;
    border-radius: 18px;
    font-size: 10px;
    font-weight: 500;
    gap: 4px;
    margin-right: 0;
  }
  .wrapper .block3 .content .detail img {
    width: 6px;
  }
  .wrapper .block3 .content .course-title .line {
    width: 3.5px;
    height: 22px;
    margin-right: 7.5px;
  }
  .wrapper .block3 .content .course-title .course-title-text {
    font-size: 18px;
    font-weight: 500;
  }
  .wrapper .block3 .content .course-title .days {
    width: 34px;
    height: 20px;
    margin-left: 5px;
  }
  .wrapper .block3 .content .course-title .days .icon-mp {
    display: inline-block;
  }
  .wrapper .block3 .content .course-title .days .icon-web {
    display: none;
  }
  .wrapper .block3 .content .course-title .days img {
    width: 34px;
    margin-top: -7.5px;
  }
  .wrapper .block3 .content .course-title .days .icon-big {
    width: 49px;
  }
  .wrapper .block3 .content .course-title .days div {
    font-size: 14px;
    font-weight: 500;
    width: 34px;
  }
  .wrapper .block3 .content .course-title .days .big {
    left: 70%;
  }
  .wrapper .block3 .content .course-time {
    padding-top: 10px;
    padding-left: 0px;
  }
  .wrapper .block3 .content .course-time span:first-child {
    font-size: 12px;
    font-weight: 600;
  }
  .wrapper .block3 .content .course-time span:last-child {
    font-size: 12px;
    padding-left: 10px;
  }
  .wrapper .block3 .content .course-core-text {
    font-size: 12px;
    font-weight: 600;
    padding-left: 0px;
    padding-top: 10px;
  }
  .wrapper .block3 .content .course-list-title {
    font-size: 12px;
    width: 100%;
    height: 24px;
    line-height: 24px;
    padding: 0 14px;
    margin-top: 12px;
  }
  .wrapper .block3 .content .course-list-title-sm {
    font-size: 11px;
  }
  .wrapper .block3 .content .course-list {
    font-size: 11px;
    padding-left: 20px;
    width: 93%;
  }
  .wrapper .block3 .content .course-list li {
    margin-top: 9px;
  }
  .wrapper .block3 .content .course-list li::before {
    width: 10px;
  }
  .wrapper .block3 .content .list-wrapper {
    padding: 0px;
  }
  .wrapper .block3 .content .list-wrapper .list-i {
    width: 100%;
  }
  .wrapper .block3 .uls {
    display: flex;
    width: 70%;
    justify-content: space-between;
  }
  .wrapper .block4 {
    padding: 15px 16px;
  }
  .wrapper .block4 .content {
    width: 100%;
    margin-top: 0px;
  }
  .wrapper .block4 .content .content-left div {
    width: 100%;
  }
  .wrapper .block4 .content .content-left div img:first-child {
    display: block;
  }
  .wrapper .block4 .content .content-left div img:last-of-type {
    display: none;
  }
  .wrapper .block4 .content .content-left img {
    width: 17px;
    height: 20px;
    margin: 0px 10px 0 0;
  }
  .wrapper .block4 .content .content-left p {
    font-size: 12px;
  }
  .wrapper .block4 .content .content-right {
    display: none;
  }
  .wrapper .block5 {
    padding: 15px 0;
    background-color: #f3f3f3;
  }
  .wrapper .block5 .content {
    width: 96%;
    margin: 0 auto;
  }
  .wrapper .block5 .content .item {
    width: 100%;
    box-shadow: none;
    background-color: white;
    padding: 6.6px 10px;
    margin-top: 15px;
  }
  .wrapper .block5 .content .item span:first-of-type {
    font-size: 24px;
    font-weight: 500;
  }
  .wrapper .block5 .content .item span:last-of-type {
    font-size: 14px;
  }
  .wrapper .block5 .content .item p {
    font-size: 11px;
  }
  .wrapper .block5 .content .arrow {
    display: none;
  }
  .wrapper .block5 .content .p1 {
    display: block;
    width: 100%;
    margin: 20px auto 0;
  }
  .wrapper .block6 {
    padding: 15px 0;
  }
  .wrapper .block6 .content {
    width: 100%;
  }
  .wrapper .block6 .content .block {
    width: 96%;
    margin: 28px auto 0;
  }
  .wrapper .block6 .content .block .item {
    width: 48%;
    height: 122px;
    padding: 13px 10px;
  }
  .wrapper .block6 .content .block .item div:first-child {
    width: 85px;
    height: 22px;
    line-height: 22px;
    font-size: 13px;
    font-weight: 500;
  }
  .wrapper .block6 .content .block .item div:first-child span {
    display: none;
  }
  .wrapper .block6 .content .block .item p {
    font-size: 10px;
    margin-bottom: 2px;
  }
  .wrapper .block6 .content .block .item p span:first-child {
    padding-right: 10px;
  }
  .wrapper .block6 .content .block .item-pc {
    display: none;
  }
  .wrapper .block6 .content .en {
    display: block;
    width: 96%;
    border: 2px solid #f9ba48;
    border-radius: 10px;
    margin: 11px auto 0;
    background-color: white;
    padding: 11px 11px;
  }
  .wrapper .block6 .content .en div {
    display: inline-block;
    vertical-align: middle;
  }
  .wrapper .block6 .content .en div .en-text {
    display: none;
  }
  .wrapper .block6 .content .en div:first-child {
    width: 85px;
    height: 22px;
    line-height: 22px;
    font-size: 13px;
    font-weight: 500;
    background-color: #f9ba48;
    border-radius: 10px;
    text-align: center;
    margin-right: 16px;
  }
  .wrapper .block6 .content .en p {
    font-size: 10px;
    margin-bottom: 5px;
  }
  .wrapper .block6 .content .list div {
    width: 96%;
    margin: 11px auto 0;
    font-size: 13px;
    padding: 4px 16px;
  }
  .wrapper .block6 .content .list div span {
    display: none;
  }
  .wrapper .block6 .content .pictures {
    text-align: center;
    margin-top: 10px;
  }
  .wrapper .block6 .content .pictures img {
    display: block;
  }
  .wrapper .block6 .content .pictures img:first-child {
    width: 372px;
    height: 228px;
  }
  .wrapper .block6 .content .pictures img:last-child {
    width: 362px;
    height: 320px;
  }
  .wrapper .block6 .content .p6,
  .wrapper .block6 .content .p7 {
    display: block;
    width: 100%;
    margin-top: 10px;
  }
  .wrapper .block6 .p3 {
    display: none;
  }
  .wrapper .block7 {
    background-color: inherit;
    padding: 8px 0;
  }
  .wrapper .block7 .content {
    width: 100%;
    margin-top: 0px;
  }
  .wrapper .block7 .content .about {
    width: 100%;
    margin: 0 auto;
    font-size: 11px;
    text-align: justify;
    padding: 3px 15px;
    line-height: 23px;
  }
  .wrapper .block7 .content .tag {
    background-color: #f9ba48;
    margin-top: 14px;
  }
  .wrapper .block7 .content .tag-big {
    width: 175px;
  }
  .wrapper .block7 .content .tag-big::before {
    left: 156px;
  }
  .wrapper .block7 .content .item-w {
    flex-wrap: wrap;
    gap: 15px;
    width: 97%;
    margin: 10px auto 0;
  }
  .wrapper .block7 .content .item-w .item {
    width: 100%;
    background-color: white;
    padding: 5px 0 20px 10px;
  }
  .wrapper .block7 .content .item-w .item .item-title .line {
    width: 3px;
    height: 30px;
  }
  .wrapper .block7 .content .item-w .item .item-title .item-title-text {
    font-size: 12px;
    font-weight: 500;
    width: 245px;
  }
  .wrapper .block7 .content .item-w .item .item-content {
    font-size: 11px;
    margin-top: 8px;
  }
  .wrapper .block7 .content .item-w .item .item-content ul {
    padding: 0 10px;
  }
  .wrapper .block7 .content .item-w .item .item-content ul li::before {
    width: 10px;
  }
  .wrapper .block7 .content .item-w .item .item-content .p4 {
    width: 165px;
    height: 75px;
  }
  .wrapper .block7 .content .item-w .item .item-content .p5 {
    width: 165px;
    height: 95px;
  }
  .wrapper .block7 .content .qa-item-w {
    flex-wrap: wrap;
    width: 96%;
    margin: 14px auto 0;
    gap: 14px;
  }
  .wrapper .block7 .content .qa-item-w .item {
    width: 100%;
    background-color: white;
    border: 1px solid #f9ba48;
    border-left: 4px solid #f9ba48;
    padding: 10px;
  }
  .wrapper .block7 .content .qa-item-w .item div {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
  .wrapper .block7 .content .qa-item-w .item p {
    font-size: 11px;
    line-height: 20px;
    margin-top: 5px;
  }
  .wrapper .block8 {
    padding-bottom: 15px;
    margin-top: 16px;
  }
  .wrapper .block8 .content {
    width: 100%;
  }
  .wrapper .block8 .content .text1 {
    width: 317px;
    font-size: 12px;
    font-weight: 500;
    margin: 15px auto 0;
  }
  .wrapper .block8 .content .item-w {
    width: 92%;
    margin: 15px auto 0;
    flex-wrap: wrap;
    gap: 15px;
    font-size: 12px;
  }
  .wrapper .block8 .content .item-w div {
    width: 100%;
    line-height: 15px;
    padding: 5px 0;
  }
  .wrapper .block8 .content .tag {
    display: block;
    color: white;
    background-color: black;
  }
  .wrapper .block8 .content .tag::before {
    border-color: transparent #f9ba48 transparent transparent;
  }
  .wrapper .block9 .title-text-mp {
    display: inline-block;
  }
  .wrapper .block9 .title-text-pc {
    display: none;
  }
  .wrapper .block9 .text1 {
    font-size: 12px;
    font-weight: 500;
  }
  .wrapper .block9 .contact {
    width: 172px;
    margin-top: 14px;
  }
  .menu-w {
    display: none;
  }
}
.fs-wrapper {
  margin-top: -20px;
  background-color: white;
}
.fs-wrapper * {
  box-sizing: border-box;
}
.fs-wrapper .title-w {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  position: relative;
}
.fs-wrapper .title-w .title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.fs-wrapper .title-w img {
  position: absolute;
  width: 114px;
  left: 50%;
  transform: translateX(-50%);
  top: 25px;
}
.fs-wrapper .title-w .title-white {
  color: white;
}
.fs-wrapper .block1 {
  width: 100%;
  background: url("~/assets/fullstack/p1@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.fs-wrapper .block1 .content {
  padding: 20px 0 20px 21px;
  position: relative;
}
.fs-wrapper .block1 .content .text {
  font-size: 28px;
  line-height: 40px;
  font-weight: bold;
}
.fs-wrapper .block1 .content .text-en {
  font-size: 20px;
  line-height: 20px;
  font-weight: lighter;
}
.fs-wrapper .block1 .content .text-en-1 {
  margin-top: 16px;
}
.fs-wrapper .block1 .content .pc-text {
  display: none;
}
.fs-wrapper .block1 .content img {
  position: absolute;
  width: 182px;
  bottom: 0;
  right: 12px;
}
.fs-wrapper .block1 .content .pcp4 {
  display: none;
}
.fs-wrapper .block2 .content {
  padding-top: 26px;
}
.fs-wrapper .block2 .content .list-w-pc {
  display: none;
}
.fs-wrapper .block2 .content .list-w-mb .list-w {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 64px 21px 0 21px;
}
.fs-wrapper .block2 .content .list-w-mb .list-w .item {
  display: flex;
  gap: 14px;
  padding-top: 4px;
  align-items: cneter;
}
.fs-wrapper .block2 .content .list-w-mb .list-w .item img {
  width: 52px;
  height: 44px;
}
.fs-wrapper .block2 .content .list-w-mb .list-w .item p {
  width: 100px;
  font-size: 12px;
}
.fs-wrapper .block2 .content .list-w-mb .mb-item {
  display: flex;
  gap: 14px;
  align-items: cneter;
  padding: 4px 22px 0 22px;
}
.fs-wrapper .block2 .content .list-w-mb .mb-item img {
  width: 52px;
  height: 44px;
}
.fs-wrapper .block2 .content .list-w-mb .mb-item p {
  font-size: 12px;
}
.fs-wrapper .block3 .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px 0px !important;
}
.fs-wrapper .block3 .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 0 !important;
}
.fs-wrapper .block3 .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 0 !important;
}
.fs-wrapper .block3 .ant-collapse > .ant-collapse-item {
  background-color: #f3f3f3 !important;
  border-radius: 18px;
}
.fs-wrapper .block3 .content {
  padding: 27px 17px 30px 17px;
}
.fs-wrapper .block3 .content .text {
  font-size: 14px;
  color: #3d3d3d;
  padding-top: 52px;
}
.fs-wrapper .block3 .content .ac-item-title {
  display: flex;
  width: 100%;
  height: 36px;
  border-radius: 18px;
  background-color: #f3f3f3;
  font-size: 14px;
  font-weight: 500;
  color: #3d3d3d;
  justify-content: space-between;
  padding-left: 18px;
  align-items: center;
  border: 1px solid #dbdbdb;
}
.fs-wrapper .block3 .content .ac-item-title img {
  width: 95px;
  margin-right: -10px;
  margin-top: 6px;
}
.fs-wrapper .block3 .content .ac-item {
  padding: 6px 10px 8px 17px;
  background-color: #f6f6f6;
  font-size: 14px;
  color: #5d5d5d;
  border-radius: 0 0 10px 10px;
}
.fs-wrapper .block3 .content .ac-item .item .title {
  position: relative;
  color: #3d3d3d;
  font-weight: 600;
  padding-left: 15px;
}
.fs-wrapper .block3 .content .ac-item .item .title::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #f9ba48;
  top: 42%;
  transform: translateX(-50%);
  left: 0px;
}
.fs-wrapper .block3 .content .ac-item .item .detail {
  padding-left: 38px;
}
.fs-wrapper .block3 .content .ac-item .item p {
  padding-left: 15px;
}
.fs-wrapper .block3 .content .ac-item .item .line {
  border: none;
  border-top: 1px dotted #a4a4a4;
  height: 1px;
  width: 100%;
  margin: 10px 0;
}
.fs-wrapper .block3 .content .ac-item p {
  margin-bottom: 0px !important;
  line-height: 33px;
}
.fs-wrapper .block3 .content .panel {
  margin-top: 17px;
}
.fs-wrapper .block4 {
  background: url("~/assets/fullstack/p14@3x.png");
  background-size: cover;
}
.fs-wrapper .block4 .content {
  padding-top: 9px;
}
.fs-wrapper .block4 .content .wrapper {
  padding: 0 0 25px 14px;
  margin-top: 61px;
}
.fs-wrapper .block4 .content .wrapper .left .item {
  display: flex;
  align-items: center;
  margin-top: 13px;
  font-size: 12px;
  color: #3d3d3d;
}
.fs-wrapper .block4 .content .wrapper .left .item .icon {
  width: 17px;
  height: 19px;
  background: url("~/assets/fullstack/p13@3x.png");
  background-size: cover;
  font-size: 12px;
  color: white;
  text-align: center;
}
.fs-wrapper .block4 .content .wrapper .left .item p {
  margin-bottom: 0px;
  margin-left: 7px;
}
.fs-wrapper .block4 .content .wrapper .right {
  display: none;
}
.fs-wrapper .block5 .content {
  padding-top: 17px;
}
.fs-wrapper .block5 .content .list-w {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  padding: 83px 15px 22px 15px;
}
.fs-wrapper .block5 .content .list-w .item {
  width: 100%;
  position: relative;
}
.fs-wrapper .block5 .content .list-w .item .name {
  background: url("~/assets/fullstack/p16@3x.png");
  background-size: cover;
  width: 99px;
  height: 41px;
  line-height: 41px;
  text-align: center;
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  color: #3d3d3d;
  top: -20px;
  left: 20px;
}
.fs-wrapper .block5 .content .list-w .item .desc {
  padding: 28px 20px 10px 14px;
  border-radius: 10px;
  background-color: #f3f3f3;
  font-size: 12px;
  color: #3d3d3d;
  box-shadow: 0 0 10px 5px #dbdbdb;
  text-align: justify;
}
.fs-wrapper .block5 .content .list-w p {
  margin-bottom: 0px;
}
.fs-wrapper .block6 {
  background-color: #5d5d5d;
  padding: 18px 28px 34px 28px;
}
.fs-wrapper .block6 .content {
  text-align: center;
}
.fs-wrapper .block6 .content .price {
  display: none;
}
.fs-wrapper .block6 .content .list-w {
  display: flex;
  justify-content: space-between;
  padding-top: 68px;
}
.fs-wrapper .block6 .content .list-w .item .top {
  font-size: 16px;
  color: #5d5d5d;
  width: 138px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 20px;
  background-color: #f9ba48;
}
.fs-wrapper .block6 .content .list-w .item .top-after {
  color: white;
  background-color: #ff7402;
}
.fs-wrapper .block6 .content .list-w .item .bottom {
  font-size: 32px;
  color: white;
  font-weight: 500;
  width: 138px;
  text-align: center;
}
.fs-wrapper .block6 .content .list-w .item .bottom span {
  font-weight: 200;
}
.fs-wrapper .block6 .content .contact {
  width: 145px;
  margin-top: 45px;
}
.fs-wrapper .block6 .content .contact-text {
  width: 156px;
  font-size: 14px;
  color: white;
  margin: 10px auto;
}
.fs-wrapper .block6 .content .number-with-line {
  position: relative;
}
.fs-wrapper .block6 .content .number-with-line::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-47%);
  bottom: 50%;
  width: 80%;
  height: 2px;
  background-color: white;
}
@media screen and (min-width: 1175px) {
  .fs-wrapper .content {
    width: 1200px;
    margin: 0 auto;
  }
  .fs-wrapper .title-w {
    font-size: 42px;
  }
  .fs-wrapper .title-w img {
    width: 199px;
    height: 15px;
    top: 50px;
  }
  .fs-wrapper .title-w .title-white {
    color: white;
  }
  .fs-wrapper .block1 .content {
    padding: 58px 0 42px 0px;
  }
  .fs-wrapper .block1 .content .text {
    font-size: 80px;
    line-height: 100px;
    font-weight: bold;
  }
  .fs-wrapper .block1 .content .text-en {
    font-size: 40px;
    font-weight: lighter;
    line-height: 57px;
  }
  .fs-wrapper .block1 .content .text-en-1 {
    font-size: 60px;
    font-weight: bold;
    margin-top: 60px;
  }
  .fs-wrapper .block1 .content .pc-text {
    display: block;
  }
  .fs-wrapper .block1 .content .mp-text {
    display: none;
  }
  .fs-wrapper .block1 .content img {
    width: 540px;
    right: 40px;
  }
  .fs-wrapper .block1 .content .p4 {
    display: none;
  }
  .fs-wrapper .block1 .content .pcp4 {
    display: block;
  }
  .fs-wrapper .block2 .content {
    padding: 35px 0px 120px 0;
  }
  .fs-wrapper .block2 .content .list-w-mb {
    display: none;
  }
  .fs-wrapper .block2 .content .list-w-pc {
    padding-top: 140px;
    display: block;
  }
  .fs-wrapper .block2 .content .list-w-pc .list-w {
    width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .fs-wrapper .block2 .content .list-w-pc .list-w .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .fs-wrapper .block2 .content .list-w-pc .list-w .item img {
    width: 120px;
  }
  .fs-wrapper .block2 .content .list-w-pc .list-w .item p {
    width: 188px;
    font-size: 20px;
    text-align: left;
  }
  .fs-wrapper .block2 .content .list-w-pc .list-w-b {
    width: 660px;
    margin: -150px auto;
  }
  .fs-wrapper .block3 .ant-collapse {
    margin-top: 58px;
  }
  .fs-wrapper .block3 .ant-collapse-header-text {
    background-color: white;
    border-radius: 35px;
  }
  .fs-wrapper .block3 .ant-collapse > .ant-collapse-item {
    background-color: white !important;
    box-shadow: 0px 7px 10px #d8d8d8;
    border-radius: 35px !important;
  }
  .fs-wrapper .block3 .ant-collapse-content {
    border-radius: 0 0 35px 35px;
  }
  .fs-wrapper .block3 .content {
    padding: 102px 0 67px 0;
  }
  .fs-wrapper .block3 .content .text {
    width: 770px;
    margin: 0 auto;
    text-align: center;
    font-size: 24px;
    padding-top: 99px;
  }
  .fs-wrapper .block3 .content .ac-item-title {
    height: 71px;
    border-radius: 35px;
    background-color: #f3f3f3;
    font-size: 28px;
    padding-left: 34px;
    border: 1px solid #d8d8d8;
  }
  .fs-wrapper .block3 .content .ac-item-title img {
    width: 187px;
    margin-right: -23px;
    margin-top: 13px;
  }
  .fs-wrapper .block3 .content .ac-item {
    padding: 26px 32px;
    font-size: 20px;
    border-radius: 0 0 35px 35px;
    background-color: white;
  }
  .fs-wrapper .block3 .content .ac-item .item {
    width: 520px;
  }
  .fs-wrapper .block3 .content .ac-item .item .title {
    position: relative;
    color: #3d3d3d;
    font-weight: 600;
    padding-left: 30px;
    padding-bottom: 10px;
  }
  .fs-wrapper .block3 .content .ac-item .item .title::before {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #f9ba48;
    top: 25%;
    left: 15px;
  }
  .fs-wrapper .block3 .content .ac-item .item .detail {
    padding-left: 65px;
  }
  .fs-wrapper .block3 .content .ac-item .item p {
    padding-left: 30px;
  }
  .fs-wrapper .block3 .content .ac-item .item .line {
    display: none;
  }
  .fs-wrapper .block3 .content .ac-item p {
    margin-bottom: 4px !important;
    line-height: 33px;
  }
  .fs-wrapper .block3 .content .ac-item-pc {
    padding: 15px 15px 25px 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 35px;
    width: 1135px;
  }
  .fs-wrapper .block3 .content .without-shadow {
    box-shadow: none !important;
  }
  .fs-wrapper .block3 .content .panel {
    margin-top: 32px;
  }
  .fs-wrapper .block4 .content {
    padding-top: 30px;
  }
  .fs-wrapper .block4 .content .wrapper {
    display: flex;
    gap: 34px;
    padding: 32px 0px 36px 79px;
    margin-top: 58px;
  }
  .fs-wrapper .block4 .content .wrapper .left .item {
    display: flex;
    align-items: center;
    margin-top: 17px;
    font-size: 20px;
  }
  .fs-wrapper .block4 .content .wrapper .left .item .icon {
    width: 21px;
    height: 25.6px;
    font-size: 12px;
    line-height: 25.6px;
  }
  .fs-wrapper .block4 .content .wrapper .left .item p {
    margin-bottom: 0px;
    margin-left: 7px;
  }
  .fs-wrapper .block4 .content .wrapper .right {
    display: block;
    width: 434px;
  }
  .fs-wrapper .block5 .content {
    padding-top: 52px;
  }
  .fs-wrapper .block5 .content .list-w {
    gap: 30px;
    row-gap: 80px;
    padding: 138px 0px 57px 0px;
    justify-content: space-between;
  }
  .fs-wrapper .block5 .content .list-w .item {
    width: 48%;
    position: relative;
  }
  .fs-wrapper .block5 .content .list-w .item .name {
    width: 196px;
    height: 77px;
    line-height: 77px;
    font-size: 42px;
    top: -45px;
  }
  .fs-wrapper .block5 .content .list-w .item .desc {
    padding: 44px 20px 10px 36px;
    font-size: 20px;
    box-shadow: 0 0 10px 5px #dbdbdb;
    text-align: left;
    width: 560px;
    height: 344px;
    line-height: 40px;
  }
  .fs-wrapper .block6 {
    padding: 28px 28px 52px 28px;
  }
  .fs-wrapper .block6 .title-w {
    display: none;
  }
  .fs-wrapper .block6 .content {
    text-align: left;
  }
  .fs-wrapper .block6 .content .pc {
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    width: 1090px;
  }
  .fs-wrapper .block6 .content .pc .left {
    width: 369px;
    display: inline-block;
  }
  .fs-wrapper .block6 .content .pc .left .price {
    display: block;
    font-size: 42px;
    font-weight: bold;
    color: white;
  }
  .fs-wrapper .block6 .content .pc .left .list-w {
    gap: 24px;
    padding-top: 20px;
  }
  .fs-wrapper .block6 .content .pc .left .list-w .item .top {
    font-size: 20px;
    width: 176px;
  }
  .fs-wrapper .block6 .content .pc .left .list-w .item .bottom {
    font-size: 36px;
    width: 176px;
  }
  .fs-wrapper .block6 .content .pc .wrapper {
    display: inline-flex;
    align-items: center;
    gap: 17px;
  }
  .fs-wrapper .block6 .content .pc .wrapper .contact {
    margin-top: 45px;
  }
  .fs-wrapper .block6 .content .pc .wrapper .contact-text {
    margin-top: 45px;
    font-size: 20px;
    width: 222px;
  }
  .fs-wrapper .block6 .content .number-with-line::before {
    width: 70%;
  }
}
.camp-tab {
  font-size: 14px;
  color: #565656;
  position: relative;
  height: 39px;
}
.camp-tab .common {
  position: absolute;
  background-color: #faf2db;
  border-radius: 16px 16px 0px 0px;
  width: 55.4%;
  line-height: 32px;
  bottom: 0px;
}
.camp-tab .left {
  left: 0;
  padding-left: 42px;
}
.camp-tab .right {
  right: 0;
  padding-left: 58px;
}
.camp-tab .left-selected {
  background: url("~/assets/fullstack/p2@3x.png");
  font-weight: bold;
  color: #2a2220;
  line-height: 39px;
  width: 100%;
  background-size: cover;
  padding-left: 42px;
  pointer-events: none;
  z-index: 1;
}
.camp-tab .right-selected {
  background: url("~/assets/fullstack/p3@3x.png");
  font-weight: bold;
  color: #2a2220;
  text-align: center;
  line-height: 39px;
  width: 100%;
  background-size: cover;
  text-align: right;
  padding-right: 30px;
  pointer-events: none;
  z-index: 1;
}
@media screen and (min-width: 1175px) {
  .camp-tab {
    display: none;
  }
}
.java-wrapper {
  margin-top: -20px;
  background-color: white;
}
.java-wrapper * {
  box-sizing: border-box;
}
.java-wrapper .title-w {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
.java-wrapper .title-w .title {
  padding: 0 10px;
}
.java-wrapper .title-w .title-sm {
  padding: 0 5px;
}
.java-wrapper .title-w .title-white {
  color: white;
}
.java-wrapper .title-w img {
  width: 128px;
}
.java-wrapper .title-w img:nth-child(3) {
  transform: rotate(180deg);
}
.java-wrapper .title-w * {
  display: inline-block;
  vertical-align: middle;
}
.java-wrapper .block1 {
  width: 100%;
  position: relative;
  background: url("~/assets/fullstack/p1@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.java-wrapper .block1 .content {
  padding: 20px 0 20px 21px;
}
.java-wrapper .block1 .content .left .text {
  font-size: 28px;
  line-height: 40px;
  font-weight: bold;
}
.java-wrapper .block1 .content .left .p2 {
  width: 100px;
  margin-top: 24px;
}
.java-wrapper .block1 .p3 {
  position: absolute;
  width: 250px;
  bottom: 0;
  right: 0;
}
.java-wrapper .block2 .content {
  padding: 15px 0px;
}
.java-wrapper .block2 .pc-list-content {
  display: none;
}
.java-wrapper .block2 .mp-list-content {
  position: relative;
}
.java-wrapper .block2 .mp-list-content .list-w {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 68px;
  padding: 0 28px;
  margin-top: 25px;
}
.java-wrapper .block2 .mp-list-content .list-w .item {
  background: url("~/assets/java/p5@3x.png");
  background-size: contain;
  width: 138px;
  height: 84px;
  padding: 12px;
  font-size: 12px;
}
.java-wrapper .block2 .mp-list-content .list-w .item-last {
  padding: 20px 18px;
}
.java-wrapper .block2 .mp-list-content .item-long {
  position: relative;
  background: url("~/assets/java/p7@3x.png");
  background-size: contain;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  width: 276px;
  margin: 18px auto;
  padding-left: 20px;
}
.java-wrapper .block2 .mp-list-content .item-long div {
  position: absolute;
  left: -15px;
  top: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: white;
  background-color: #5b5b5b;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.java-wrapper .block2 .mp-list-content .mid {
  position: absolute;
  top: 19%;
  left: 30%;
  width: 130px;
}
.java-wrapper .block3 .content {
  padding: 20px 0;
}
.java-wrapper .block3 .list-w {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 23px;
}
.java-wrapper .block3 .list-w .item {
  width: 162px;
  height: 231px;
  border-radius: 9px;
  position: relative;
  text-align: center;
  padding-top: 100px;
  margin-top: 23px;
}
.java-wrapper .block3 .list-w .item .text1 {
  font-size: 18px;
  font-weight: bold;
}
.java-wrapper .block3 .list-w .item .text2 {
  font-size: 14px;
  text-align: left;
  width: 142px;
  margin: 0 auto;
  margin-top: 6px;
}
.java-wrapper .block3 .list-w .item1 {
  background: url("~/assets/java/p15@3x.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.java-wrapper .block3 .list-w .item2 {
  background: url("~/assets/java/p16@3x.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.java-wrapper .block3 .list-w .item3 {
  background: url("~/assets/java/p17@3x.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.java-wrapper .block3 .list-w .item4 {
  background: url("~/assets/java/p18@3x.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 90px;
}
.java-wrapper .block3 .list-w .item4 .text2 {
  width: 150px;
}
.java-wrapper .block4 .ant-collapse > .ant-collapse-item,
.java-wrapper .block4 .ant-collapse-header,
.java-wrapper .block4 .ant-collapse-content-box {
  background-color: white;
}
.java-wrapper .block4 .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px 20px !important;
}
.java-wrapper .block4 .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 20px;
}
.java-wrapper .block4 .ant-collapse-header-text {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  margin-top: 14px;
}
.java-wrapper .block4 .without-line .ant-collapse-header-text {
  border: none !important;
}
.java-wrapper .block4 .content .ac-item-title {
  display: flex;
  width: 100%;
  height: 36px;
  border-radius: 18px;
  background-color: #f3f3f3;
  font-size: 14px;
  font-weight: 500;
  color: #3d3d3d;
  justify-content: space-between;
  padding-left: 18px;
  align-items: center;
  border: 1px solid #dbdbdb;
}
.java-wrapper .block4 .content .ac-item-title img {
  width: 95px;
  margin-right: -10px;
  margin-top: 6px;
}
.java-wrapper .block4 .content .ac-item {
  padding: 6px 10px 8px 0px;
  background-color: white;
  font-size: 14px;
  color: #5d5d5d;
  border-radius: 0 0 10px 10px;
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.java-wrapper .block4 .content .ac-item .item .title {
  position: relative;
  color: #3d3d3d;
  font-weight: 600;
  padding-left: 15px;
  padding-top: 5px;
}
.java-wrapper .block4 .content .ac-item .item .title::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 19px;
  background-color: #f9ba48;
  top: 31%;
  transform: translateX(-50%);
  left: 5px;
}
.java-wrapper .block4 .content .ac-item .item .title-mt {
  margin-top: 5px;
}
.java-wrapper .block4 .content .ac-item .item p {
  padding-left: 18px;
}
.java-wrapper .block4 .content .ac-item .item .p-mt {
  margin-top: 10px;
}
.java-wrapper .block4 .content .ac-item .item .p-mt span {
  display: block;
  line-height: 25px;
}
.java-wrapper .block4 .content .ac-item .item .line {
  border: none;
  border-top: 1px dotted #a4a4a4;
  height: 1px;
  width: 100%;
  margin: 10px 0;
}
.java-wrapper .block4 .content .ac-item p {
  margin-bottom: 0px !important;
  line-height: 33px;
}
.java-wrapper .block5 .content {
  padding: 30px 0;
}
.java-wrapper .block5 .content .list-w {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 30px;
  row-gap: 30px;
  margin-top: 15px;
}
.java-wrapper .block5 .content .list-w .item {
  width: 150px;
  text-align: center;
  font-size: 14px;
}
.java-wrapper .block5 .content .list-w .item img {
  width: 66.5px;
  margin-bottom: 7px;
}
.java-wrapper .block6 {
  background: linear-gradient(to right, #ffefd3, #fabc4b);
}
.java-wrapper .block6 .content {
  padding: 16px 0 20px 0;
}
.java-wrapper .block6 .content .list-w {
  display: flex;
  font-size: 14px;
  flex-wrap: wrap;
  padding: 0 10px;
}
.java-wrapper .block6 .content .list-w .item {
  width: 100%;
}
.java-wrapper .block6 .content .list-w .item div {
  width: 100%;
  background-color: #f0b64a;
  border-radius: 18px;
  padding: 7px 26px;
  margin-top: 10px;
  position: relative;
}
.java-wrapper .block6 .content .list-w .item div::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: black;
  top: 16px;
  left: 15px;
}
.java-wrapper .block6 .content .list-w img {
  display: none;
}
.java-wrapper .block7 .content {
  padding: 35px 0;
}
.java-wrapper .block7 .content .list-w {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  width: 100%;
  row-gap: 36px;
  margin-top: 25px;
}
.java-wrapper .block7 .content .list-w .item {
  position: relative;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  padding: 30px 0 5px 15px;
  background: linear-gradient(to bottom, #fff2db, #fffefe);
}
.java-wrapper .block7 .content .list-w .item .name {
  position: absolute;
  background: url("~/assets/java/p32@3x.png");
  background-size: contain;
  width: 98px;
  height: 32px;
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  padding-left: 15px;
  top: -15px;
  left: 10px;
}
.java-wrapper .block7 .content .list-w .item .text {
  font-size: 14px;
  position: relative;
  padding-left: 3px;
  line-height: 30px;
}
.java-wrapper .block7 .content .list-w .item .text::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  top: 13px;
  left: -5px;
  background-color: black;
}
@media screen and (min-width: 1441px) {
  .block1 .p3 {
    width: 980px !important;
  }
}
@media screen and (min-width: 1175px) and (max-width: 1440px) {
  .block1 .p3 {
    width: 780px !important;
  }
}
@media screen and (min-width: 1175px) {
  .java-wrapper .content {
    width: 1200px;
    margin: 0 auto;
  }
  .java-wrapper .title-w {
    font-size: 36px;
  }
  .java-wrapper .title-w .title {
    padding: 0 15px;
  }
  .java-wrapper .title-w img {
    width: 280px;
  }
  .java-wrapper .block1 .content {
    padding: 92px 0 42px 0px;
  }
  .java-wrapper .block1 .content .left .text {
    font-size: 90px;
    line-height: 100px;
    font-weight: bold;
  }
  .java-wrapper .block1 .content .left .p2 {
    width: 250px;
    margin-top: 85px;
  }
  .java-wrapper .block2 .content {
    padding: 75px 0 40px 0;
  }
  .java-wrapper .block2 .content .mp-list-content {
    display: none;
  }
  .java-wrapper .block2 .content .pc-list-content {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    align-items: center;
  }
  .java-wrapper .block2 .content .pc-list-content .list-w .item {
    position: relative;
    background: url("~/assets/java/p8@3x.png");
    background-size: contain;
    height: 54px;
    line-height: 54px;
    width: 711px;
    margin-top: 10px;
    font-size: 24px;
    padding-left: 55px;
  }
  .java-wrapper .block2 .content .pc-list-content .list-w .item div {
    position: absolute;
    left: 7px;
    top: 7px;
    width: 41px;
    height: 41px;
    line-height: 41px;
    color: white;
    background-color: #5b5b5b;
    border-radius: 50%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  .java-wrapper .block2 .content .pc-list-content .p10 {
    width: 350px;
  }
  .java-wrapper .block3 .content {
    padding: 40px 0 20px 0;
  }
  .java-wrapper .block3 .list-w {
    display: flex;
    justify-content: space-between;
    padding: 0px;
  }
  .java-wrapper .block3 .list-w .item {
    width: 268px;
    height: 349px;
    padding-top: 160px;
  }
  .java-wrapper .block3 .list-w .item .text1 {
    font-size: 28px;
  }
  .java-wrapper .block3 .list-w .item .text2 {
    font-size: 20px;
    width: 240px;
    margin: 0 auto;
    margin-top: 27px;
  }
  .java-wrapper .block3 .list-w .item1 {
    background: url("~/assets/java/p155@3x.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .java-wrapper .block3 .list-w .item2 {
    background: url("~/assets/java/p166@3x.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .java-wrapper .block3 .list-w .item3 {
    background: url("~/assets/java/p177@3x.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .java-wrapper .block3 .list-w .item4 {
    background: url("~/assets/java/p188@3x.png");
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 135px;
  }
  .java-wrapper .block3 .list-w .item4 .text2 {
    width: 230px;
    margin-top: 9px;
  }
  .java-wrapper .block4 .content {
    padding: 25px 0;
  }
  .java-wrapper .block4 .content .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0px 0px !important;
  }
  .java-wrapper .block4 .content .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 0px;
  }
  .java-wrapper .block4 .content .ant-collapse {
    margin-top: 10px;
  }
  .java-wrapper .block4 .content .ant-collapse-header-text {
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    margin-top: 24px;
  }
  .java-wrapper .block4 .content .ac-item-title {
    height: 71px;
    border-radius: 35px;
    background-color: #f3f3f3;
    font-size: 26px;
    padding-left: 34px;
    border: 1px solid #d8d8d8;
  }
  .java-wrapper .block4 .content .ac-item-title img {
    width: 187px;
    margin-right: -23px;
    margin-top: 13px;
  }
  .java-wrapper .block4 .content .ac-item {
    padding: 26px 32px 26px 0;
    font-size: 20px;
    border-radius: 0 0 35px 35px;
    background-color: white;
  }
  .java-wrapper .block4 .content .ac-item .item {
    width: 520px;
  }
  .java-wrapper .block4 .content .ac-item .item .title {
    position: relative;
    color: #3d3d3d;
    font-weight: 600;
    padding-left: 30px;
    padding-bottom: 10px;
    font-size: 24px;
  }
  .java-wrapper .block4 .content .ac-item .item .title::before {
    width: 5px;
    height: 25px;
    background-color: #f9ba48;
    top: 20%;
    left: 15px;
  }
  .java-wrapper .block4 .content .ac-item .item .title-mt {
    margin-top: 25px;
  }
  .java-wrapper .block4 .content .ac-item .item .item-mt {
    margin-top: 50px !important;
  }
  .java-wrapper .block4 .content .ac-item .item p {
    padding-left: 35px;
  }
  .java-wrapper .block4 .content .ac-item .item .line {
    display: none;
  }
  .java-wrapper .block4 .content .ac-item .item .p-item span {
    display: block;
    line-height: 35px;
  }
  .java-wrapper .block4 .content .ac-item .item .p-mt {
    margin-top: 23px;
  }
  .java-wrapper .block4 .content .ac-item .item .p-mt-big {
    margin-top: 50px;
  }
  .java-wrapper .block4 .content .ac-item .item .p-mt-big-2 {
    margin-top: 95px;
  }
  .java-wrapper .block4 .content .ac-item p {
    margin-bottom: 6px !important;
    line-height: 33px;
  }
  .java-wrapper .block5 .content {
    padding: 60px 0 75px 0;
  }
  .java-wrapper .block5 .content .list-w {
    padding: 0 60px;
    margin-top: 55px;
  }
  .java-wrapper .block5 .content .list-w .item {
    width: 240px;
    font-size: 24px;
  }
  .java-wrapper .block5 .content .list-w .item img {
    width: 128px;
    margin-bottom: 17px;
  }
  .java-wrapper .block6 {
    background: linear-gradient(to right, #ffefd3, #fabc4b);
    background-size: contain;
  }
  .java-wrapper .block6 .content {
    padding: 27px 0 70px 0;
  }
  .java-wrapper .block6 .content .list-w {
    padding: 0 60px;
    justify-content: space-between;
    flex-wrap: nowrap;
    font-size: 18px;
    position: relative;
    margin-top: 50px;
  }
  .java-wrapper .block6 .content .list-w .item {
    width: 380px;
  }
  .java-wrapper .block6 .content .list-w .item div {
    border-radius: 40px;
    padding: 5px 30px;
    line-height: 24px;
  }
  .java-wrapper .block6 .content .list-w .item div::before {
    top: 14px;
  }
  .java-wrapper .block6 .content .list-w img {
    display: block;
    position: absolute;
    width: 280px;
    left: 38%;
    top: -27%;
  }
  .java-wrapper .block7 .content {
    padding-top: 60px;
  }
  .java-wrapper .block7 .content .list-w {
    flex-wrap: nowrap;
    justify-content: space-around;
    margin-top: 70px;
    padding: 0px;
  }
  .java-wrapper .block7 .content .list-w .item {
    width: 366px;
    padding: 40px 0 0 25px;
  }
  .java-wrapper .block7 .content .list-w .item .name {
    font-size: 32px;
    width: 153px;
    height: 50px;
    line-height: 50px;
    padding-left: 30px;
    top: -26px;
    left: 15px;
  }
  .java-wrapper .block7 .content .list-w .item .text {
    font-size: 18px;
    padding: 0px 15px 0 10px;
    line-height: 35px;
  }
  .java-wrapper .block7 .content .list-w .item .text::before {
    width: 6px;
    height: 6px;
    top: 15px;
  }
  .java-wrapper .block7 .content .list-w .item .text-1 {
    line-height: 25px;
  }
  .java-wrapper .block7 .content .list-w .item .text-1::before {
    width: 6px;
    height: 6px;
    top: 10px;
  }
  .java-wrapper .block7 .content .list-w .item-last {
    padding: 30px 0 20px 25px !important;
  }
}
.ai-wrapper {
  margin-top: -20px;
  background-color: white;
}
.ai-wrapper * {
  box-sizing: border-box;
}
.ai-wrapper .title-w {
  position: relative;
  width: 180px;
  height: 60px;
  margin: 0 auto;
  text-align: center;
  font-size: 32px;
  background: url("~/assets/ai/p4.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.ai-wrapper .title-w .title {
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -3px;
}
.ai-wrapper .block1 {
  width: 100%;
  position: relative;
  background: url("~/assets/fullstack/p1@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.ai-wrapper .block1 .content {
  padding: 25px 0 23px 21px;
  position: relative;
}
.ai-wrapper .block1 .content .left .text {
  font-size: 26px;
  line-height: 40px;
  font-weight: bold;
  margin-bottom: 5px;
}
.ai-wrapper .block1 .content .left .text-en {
  font-size: 12px;
  font-weight: 500;
  color: #7f7f7f;
  position: relative;
  z-index: 2;
}
.ai-wrapper .block1 .content .left .learn-btn {
  cursor: pointer;
  width: 138px;
  height: 38px;
  line-height: 38px;
  border-radius: 19px;
  background-color: #3d3d3d;
  color: white;
  font-size: 16px;
  text-align: center;
  margin-top: 35px;
}
.ai-wrapper .block1 .p3m,
.ai-wrapper .block1 .p3 {
  position: absolute;
  width: 173px;
  bottom: 0;
  right: 0;
}
.ai-wrapper .block1 .p3 {
  display: none;
}
.ai-wrapper .block2 .content {
  padding: 42px 15px 20px 15px;
}
.ai-wrapper .block2 .content .list-content {
  position: relative;
  align-items: center;
  font-size: 14px;
}
.ai-wrapper .block2 .content .list-content .list-w {
  margin-top: 15px;
}
.ai-wrapper .block2 .content .list-content .list-w .item {
  display: flex;
  align-items: start;
  margin-top: 4px;
}
.ai-wrapper .block2 .content .list-content .list-w .item .p5 {
  position: relative;
  margin-right: 2px;
}
.ai-wrapper .block2 .content .list-content .list-w .item .p5 img {
  width: 31px;
  height: 27px;
}
.ai-wrapper .block2 .content .list-content .list-w .item .p5 div {
  position: absolute;
  top: 50%;
  left: 32%;
  transform: translateY(-50%);
}
.ai-wrapper .block2 .content .list-content .list-w .item .text {
  line-height: 29px;
}
.ai-wrapper .block2 .content .list-content .p6 {
  display: none;
}
.ai-wrapper .block3 {
  background-color: #fff8eb;
}
.ai-wrapper .block3 .content {
  padding: 28px 15px;
}
.ai-wrapper .block3 .list-w {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.ai-wrapper .block3 .list-w .item {
  position: relative;
  border-radius: 10px;
  background-color: white;
  width: 100%;
  height: 90px;
  padding-left: 130px;
  padding-top: 15px;
  box-sizing: border-box;
  margin-top: 53px;
}
.ai-wrapper .block3 .list-w .item img {
  position: absolute;
  width: 121px;
  top: -33px;
  left: 5px;
}
.ai-wrapper .block3 .list-w .item .line {
  width: 46px;
  height: 6px;
  background-color: #ffc658;
  border-radius: 3px;
}
.ai-wrapper .block3 .list-w .item .text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 8px;
  text-align: left;
}
.ai-wrapper .block4 .ant-collapse {
  margin-top: 15px;
}
.ai-wrapper .block4 .ant-collapse > .ant-collapse-item,
.ai-wrapper .block4 .ant-collapse-header,
.ai-wrapper .block4 .ant-collapse-content-box {
  background-color: white;
}
.ai-wrapper .block4 .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px 20px !important;
}
.ai-wrapper .block4 .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 20px;
}
.ai-wrapper .block4 .ant-collapse-header-text {
  border-top-left-radius: 23px;
  border-top-right-radius: 23px;
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  margin-top: 10px;
}
.ai-wrapper .block4 .without-line .ant-collapse-header-text {
  border: none !important;
}
.ai-wrapper .block4 .content {
  padding: 36px 0;
}
.ai-wrapper .block4 .content .ac-item-title {
  display: flex;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  background-color: #f3f3f3;
  font-size: 20px;
  font-weight: 500;
  color: #3d3d3d;
  justify-content: space-between;
  padding-left: 18px;
  align-items: center;
  border: 1px solid #dbdbdb;
}
.ai-wrapper .block4 .content .ac-item-title img {
  width: 120px;
  margin-right: -10px;
  margin-top: 6px;
}
.ai-wrapper .block4 .content .ac-item {
  padding: 6px 10px 8px 15px;
  background-color: white;
  font-size: 14px;
  color: #5d5d5d;
  border-radius: 0 0 10px 10px;
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}
.ai-wrapper .block4 .content .ac-item .y-title {
  position: relative;
  color: #3d3d3d;
  font-size: 16px;
  padding-left: 15px;
  padding-top: 5px;
  margin-bottom: 10px;
}
.ai-wrapper .block4 .content .ac-item .y-title::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 42px;
  background-color: #f9ba48;
  top: 13%;
  transform: translateX(-50%);
  left: 3px;
}
.ai-wrapper .block4 .content .ac-item .y-title-sm {
  margin-top: 15px;
}
.ai-wrapper .block4 .content .ac-item .y-title-sm::before {
  width: 5px;
  height: 20px;
  top: 8px;
}
.ai-wrapper .block4 .content .ac-item .list-w {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
}
.ai-wrapper .block4 .content .ac-item .list-w p {
  margin-bottom: 0px !important;
  line-height: 27px;
}
.ai-wrapper .block7 .content {
  padding: 10px 0 25px 0;
}
.ai-wrapper .block7 .content .list-w {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 10px;
  width: 100%;
  row-gap: 36px;
  margin-top: 50px;
}
.ai-wrapper .block7 .content .list-w .p10 {
  display: none;
}
.ai-wrapper .block7 .content .list-w .item {
  position: relative;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  padding: 30px 0 5px 15px;
  background: linear-gradient(to bottom, #fff2db, #fffefe);
}
.ai-wrapper .block7 .content .list-w .item .name {
  position: absolute;
  background: url("~/assets/java/p32@3x.png");
  background-size: contain;
  width: 98px;
  height: 32px;
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  padding-left: 15px;
  top: -15px;
  left: 10px;
}
.ai-wrapper .block7 .content .list-w .item .text {
  font-size: 12px;
  position: relative;
  padding-left: 3px;
  line-height: 30px;
}
.ai-wrapper .block7 .content .list-w .item .text::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  top: 13px;
  left: -5px;
  background-color: black;
}
.ai-wrapper .block8 {
  background-color: #585858;
  color: white;
}
.ai-wrapper .block8 .content {
  padding: 25px 20px 30px 20px;
}
.ai-wrapper .block8 .content .list-w {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: end;
}
.ai-wrapper .block8 .content .item .price {
  font-size: 32px;
  letter-spacing: 15px;
  position: relative;
  z-index: 1;
  padding-left: 20px;
}
.ai-wrapper .block8 .content .item .price::before {
  content: "";
  position: absolute;
  left: 10px;
  top: 35px;
  width: 122px;
  height: 12px;
  border-radius: 6px;
  background-color: #ffc65b;
  z-index: -1;
}
.ai-wrapper .block8 .content .item .price-list {
  font-size: 16px;
  padding: 10px 0 0 10px;
  line-height: 30px;
}
.ai-wrapper .block8 .content .item img {
  width: 105px;
}
.ai-wrapper .block8 .content .item .contact-text {
  width: 110px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  margin-top: 7px;
}
.ai-wrapper .block8 .content .item-right {
  text-align: center;
}
@media screen and (min-width: 1175px) {
  .block1 .p3 {
    display: block;
    width: 526px !important;
    right: 30px !important;
  }
}
@media screen and (min-width: 1175px) {
  .ai-wrapper .content {
    width: 1200px;
    margin: 0 auto;
  }
  .ai-wrapper .title-w {
    font-size: 36px;
  }
  .ai-wrapper .title-w .title {
    bottom: -5px;
  }
  .ai-wrapper .title-w img {
    width: 280px;
  }
  .ai-wrapper .title-pc {
    width: 200px;
    background: url("~/assets/ai/p4-pc.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .ai-wrapper .title-pc .title {
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-53.5%);
  }
  .ai-wrapper .block1 .content {
    padding: 52px 40px 72px 40px;
  }
  .ai-wrapper .block1 .content .left .text {
    font-size: 56px;
    line-height: 100px;
    font-weight: bold;
    margin-bottom: -5px;
    position: relative;
    z-index: 2;
  }
  .ai-wrapper .block1 .content .left .text-en {
    font-size: 26px;
    line-height: 31px;
  }
  .ai-wrapper .block1 .content .left .learn-btn {
    width: 253px;
    height: 68px;
    line-height: 68px;
    border-radius: 34px;
    background-color: #3d3d3d;
    text-align: center;
    font-size: 26px;
    color: white;
    margin-top: 50px;
  }
  .ai-wrapper .block1 .p3 {
    display: block;
  }
  .ai-wrapper .block1 .p3m {
    display: none !important;
  }
  .ai-wrapper .block2 .content {
    padding: 42px 20px 92px 20px;
  }
  .ai-wrapper .block2 .content .list-content {
    font-size: 20px;
  }
  .ai-wrapper .block2 .content .list-content .list-w {
    margin-top: 35px;
  }
  .ai-wrapper .block2 .content .list-content .list-w .item {
    margin-top: 14px;
  }
  .ai-wrapper .block2 .content .list-content .list-w .item .p5 img {
    width: 41px;
    height: 37px;
  }
  .ai-wrapper .block2 .content .list-content .list-w .item .p5 div {
    left: 32%;
  }
  .ai-wrapper .block2 .content .list-content .p6 {
    position: absolute;
    display: block;
    top: -120px;
    right: 0px;
    width: 436px;
    height: 451px;
  }
  .ai-wrapper .block3 .content {
    padding: 37px 50px 66px 50px;
  }
  .ai-wrapper .block3 .list-w {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    margin-top: 110px;
  }
  .ai-wrapper .block3 .list-w .item {
    width: 344px;
    height: 244px;
    padding: 115px 30px 86px 30px;
  }
  .ai-wrapper .block3 .list-w .item img {
    width: 186px;
    left: 50%;
    transform: translateX(-50%);
    top: -90px;
  }
  .ai-wrapper .block3 .list-w .item .line {
    margin: 0 auto;
  }
  .ai-wrapper .block3 .list-w .item .text {
    font-size: 20px;
    margin: 0 auto;
    line-height: 30px;
    margin-top: 21px;
  }
  .ai-wrapper .block3 .list-w .item .text-1 {
    text-align: center;
  }
  .ai-wrapper .block3 .list-w .item-1 {
    padding: 115px 15px 86px 15px;
  }
  .ai-wrapper .block4 .content {
    padding: 58px 0 25px 0;
  }
  .ai-wrapper .block4 .content .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0px 0px !important;
  }
  .ai-wrapper .block4 .content .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 0px;
  }
  .ai-wrapper .block4 .content .ant-collapse {
    margin-top: 25px;
  }
  .ai-wrapper .block4 .content .ant-collapse-header-text {
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    margin-top: 24px;
  }
  .ai-wrapper .block4 .content .ac-item-title {
    height: 71px;
    border-radius: 35px;
    background-color: #f3f3f3;
    font-size: 26px;
    padding-left: 34px;
    border: 1px solid #d8d8d8;
  }
  .ai-wrapper .block4 .content .ac-item-title img {
    width: 187px;
    margin-right: -23px;
    margin-top: 13px;
  }
  .ai-wrapper .block4 .content .ac-item {
    padding: 10px 32px 26px 0;
    font-size: 20px;
    border-radius: 0 0 35px 35px;
    background-color: white;
  }
  .ai-wrapper .block4 .content .ac-item .y-title {
    font-size: 26px;
    padding-left: 30px;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .ai-wrapper .block4 .content .ac-item .y-title::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 25px;
    background-color: #f9ba48;
    top: 28%;
    transform: translateX(-50%);
    left: 20px;
  }
  .ai-wrapper .block4 .content .ac-item .y-title-sm {
    margin-top: 30px;
  }
  .ai-wrapper .block4 .content .ac-item .list-w {
    flex-direction: row;
  }
  .ai-wrapper .block4 .content .ac-item .list-w .item {
    width: 520px;
  }
  .ai-wrapper .block4 .content .ac-item .list-w .item p {
    padding-left: 31px;
    margin-top: 15px;
  }
  .ai-wrapper .block4 .content .ac-item .list-w .pc-item {
    padding-left: 8px;
  }
  .ai-wrapper .block4 .content .ac-item p {
    margin-bottom: 6px !important;
    line-height: 33px;
  }
  .ai-wrapper .block7 .content {
    padding-top: 33px;
  }
  .ai-wrapper .block7 .content .list-w {
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 35px;
    padding: 0px;
  }
  .ai-wrapper .block7 .content .list-w .item {
    width: 520px;
    height: 300px;
    padding: 40px 0 0 25px;
  }
  .ai-wrapper .block7 .content .list-w .item .name {
    font-size: 32px;
    width: 153px;
    height: 50px;
    line-height: 50px;
    padding-left: 30px;
    top: -26px;
    left: 15px;
  }
  .ai-wrapper .block7 .content .list-w .item .text {
    font-size: 18px;
    padding: 0px 15px 0 10px;
    line-height: 35px;
  }
  .ai-wrapper .block7 .content .list-w .item .text::before {
    width: 6px;
    height: 6px;
    top: 15px;
  }
  .ai-wrapper .block7 .content .list-w .item .text-1 {
    line-height: 25px;
  }
  .ai-wrapper .block7 .content .list-w .item .text-1::before {
    width: 6px;
    height: 6px;
    top: 10px;
  }
  .ai-wrapper .block7 .content .list-w .p10 {
    display: block;
    width: 350px;
  }
  .ai-wrapper .block8 .content {
    padding: 35px 35px 63px 35px;
  }
  .ai-wrapper .block8 .content .list-w {
    align-items: center;
  }
  .ai-wrapper .block8 .content .list-w .item .price {
    font-size: 36px;
    letter-spacing: 20px;
  }
  .ai-wrapper .block8 .content .list-w .item .price::before {
    top: 40px;
  }
  .ai-wrapper .block8 .content .list-w .item .price-list {
    font-size: 26px;
    line-height: 45px;
  }
  .ai-wrapper .block8 .content .list-w .item img {
    width: 182px;
  }
  .ai-wrapper .block8 .content .list-w .item .contact-text {
    font-size: 26px;
    width: 250px;
    margin-top: 15px;
  }
}
.cities-container {
  margin-top: -20px;
  margin-bottom: 55px;
}
.cities-container .small-container {
  width: 1200px;
  margin: 0 auto;
}
.cities-container .small-container .create-city-block {
  display: none;
}
.cities-container .small-container .event-swiper {
  margin: 40px 0 80px 0;
  height: 140px;
}
.cities-container .small-container .event-swiper .event-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  width: 100%;
  font-size: 16px;
}
.cities-container .small-container .event-swiper .event-item .left {
  display: flex;
  align-items: center;
  color: #7b7b7b;
}
.cities-container .small-container .event-swiper .event-item .left img {
  width: 20px;
  margin-right: 5px;
}
.cities-container .small-container .event-swiper .event-item .left .yellow {
  color: #f9ba48 !important;
  cursor: pointer;
}
.cities-container .small-container .event-swiper .event-item .left .gray {
  color: #7b7b7b !important;
}
.cities-container .small-container .event-swiper .event-item .left .title {
  font-weight: 400;
  margin: 0 10px;
  display: inline-block;
  max-width: 800px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cities-container .small-container .event-swiper .event-item .right {
  color: #9b9b9b;
}
.cities-container .small-container .city-wrapper {
  display: flex;
  margin-top: 57px;
  height: 387px;
  border-radius: 8px;
  background-color: white;
}
.cities-container .small-container .city-wrapper .left {
  position: relative;
  width: 516px;
  overflow: hidden;
  height: inherit;
  text-align: left;
  cursor: pointer;
}
.cities-container .small-container .city-wrapper .left .cover {
  position: absolute;
  z-index: 0;
  width: 516px;
  height: 387px;
  border-radius: 8px;
  object-fit: cover;
}
.cities-container .small-container .city-wrapper .right {
  flex: 1;
  padding: 57px 45px 0px 45px;
}
.cities-container .small-container .city-wrapper .right .city-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cities-container .small-container .city-wrapper .right .city-info .left-info {
  display: flex;
  align-items: center;
}
.cities-container .small-container .city-wrapper .right .city-info .left-info .city-name {
  max-width: 180px;
  font-size: 40px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
.cities-container .small-container .city-wrapper .right .city-info .left-info .join-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  line-height: 36px;
  margin-left: 18px;
  padding: 0px 15px;
  font-size: 16px;
  font-weight: 400;
  color: white;
  background-color: #f9ba48;
  border-radius: 20px;
  cursor: pointer;
}
.cities-container .small-container .city-wrapper .right .city-info .left-info .joined {
  background-color: #f0f0f0;
  color: #7b7b7b;
}
.cities-container .small-container .city-wrapper .right .city-info .owner-info {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.cities-container .small-container .city-wrapper .right .city-info .owner-info .nickname {
  max-width: 100px;
  margin-left: 10px;
  color: #7b7b7b;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cities-container .small-container .city-wrapper .right .city-info .owner-info .date {
  color: #9b9b9b;
  margin-left: 12px;
}
.cities-container .small-container .city-wrapper .right .followed-users {
  margin-top: 54px;
}
.cities-container .small-container .city-wrapper .right .followed-users .pc-users {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.cities-container .small-container .city-wrapper .right .followed-users .mp-users {
  display: none;
}
.cities-container .small-container .city-wrapper .right .followed-users .more-users {
  position: relative;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #f0f0f0;
  color: #7b7b7b;
  font-size: 30px;
}
.cities-container .small-container .city-wrapper .right .followed-users .more-users::after {
  content: "...";
  position: absolute;
  font-size: 26px;
  left: 50%;
  transform: translate(-50%, -15%);
}
.cities-container .small-container .city-wrapper .right .followed-users .total-count {
  font-size: 16px;
  font-weight: 400;
}
.cities-container .small-container .city-wrapper .right .followed-users .total-count span {
  color: #f9ba48;
}
.cities-container .small-container .city-wrapper .right .city-indexes {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  margin-top: 23px;
}
.cities-container .small-container .city-wrapper .right .city-indexes .index {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #7b7b7b;
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  padding: 2px 10px;
  gap: 3px;
}
.cities-container .small-container .city-wrapper .right .line {
  display: none;
}
.cities-container .small-container .city-wrapper .right .hide-in-pc {
  display: none;
}
.cities-container .small-container .city-wrapper-reverse {
  flex-direction: row-reverse;
}
.cities-container .small-container .city-wrapper-reverse .tag-wrapper {
  justify-content: end;
}
.cities-container .small-container .create-city-btn {
  display: flex;
  justify-content: center;
  width: 592px;
  margin: 80px auto 0;
  padding: 15px 0;
  font-size: 16px;
  font-weight: 400;
  color: #7b7b7b;
  border: 1px solid #e0e0e0;
  border-radius: 40px;
  cursor: pointer;
}
.cities-container .small-container .create-city-btn * {
  display: inline-block;
}
.cities-container .small-container .create-city-btn .text {
  color: #f9ba48;
}
.cities-container .small-container .create-city-btn .create-icon {
  position: relative;
  width: 24px;
  height: 24px;
  color: white;
  border-radius: 50%;
  background-color: #f9ba48;
}
.cities-container .small-container .create-city-btn .create-icon::after {
  content: "+";
  position: absolute;
  left: 50%;
  transform: translate(-50%, -7%);
}
.city-tag-wrapper {
  display: flex;
  column-gap: 10px;
  position: relative;
  z-index: 1;
  padding: 20px;
  color: white;
  font-size: 16px;
  font-weight: 400;
}
.city-tag-wrapper .tag {
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
  align-items: center;
  padding: 2px 7px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.35);
}
.city-tag-wrapper .tag img {
  width: 20px;
}
.city-tag-wrapper .tag .small {
  display: none;
}
.ant-progress-steps-item {
  width: 10px !important;
  height: 3px !important;
  border-radius: 30%;
}
@media screen and (max-width: 1175px) {
  .cities-container {
    margin-bottom: 25px;
  }
  .cities-container .small-container {
    width: 100%;
    padding: 0 15px;
  }
  .cities-container .small-container .create-city-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
  }
  .cities-container .small-container .create-city-block .all-city-text {
    font-size: 14px;
    font-weight: 500;
    color: #4a4a4a;
  }
  .cities-container .small-container .create-city-block .sm-create-city-btn {
    display: flex;
    column-gap: 3px;
    font-size: 12px;
    align-items: center;
    color: #7b7b7b;
  }
  .cities-container .small-container .event-swiper {
    margin: 15px 0 15px 0;
    height: 100px;
  }
  .cities-container .small-container .event-swiper .event-item {
    font-size: 12px;
    height: 20px;
  }
  .cities-container .small-container .event-swiper .event-item .left img {
    margin-right: 0px;
    width: 15px;
  }
  .cities-container .small-container .event-swiper .event-item .left .title {
    margin: 0 5px;
    max-width: 140px;
  }
  .cities-container .small-container .event-swiper .event-item .left .nickname {
    max-width: 90px;
  }
  .cities-container .small-container .city-wrapper {
    margin-top: 12px;
    width: 100%;
    height: 120px;
  }
  .cities-container .small-container .city-wrapper .left {
    width: 88px;
  }
  .cities-container .small-container .city-wrapper .left .hide-in-mp {
    display: none;
  }
  .cities-container .small-container .city-wrapper .left .cover {
    width: 88px;
    height: 120px;
  }
  .cities-container .small-container .city-wrapper .right {
    padding: 15px 15px 10px 15px;
    width: auto;
  }
  .cities-container .small-container .city-wrapper .right .city-info {
    flex-wrap: wrap;
  }
  .cities-container .small-container .city-wrapper .right .city-info .left-info {
    justify-content: space-between;
    width: 100%;
  }
  .cities-container .small-container .city-wrapper .right .city-info .left-info .city-name {
    font-size: 16px;
    max-width: 150px;
  }
  .cities-container .small-container .city-wrapper .right .city-info .left-info .join-btn {
    height: 24px;
    line-height: 24px;
    margin-left: 9px;
    font-size: 11px;
    color: #f9ba48;
    border: 1px solid #f9ba48;
    background-color: white;
    padding: 0 10px;
  }
  .cities-container .small-container .city-wrapper .right .city-info .left-info .joined {
    color: #7b7b7b;
    border: none;
    background-color: #f0f0f0;
  }
  .cities-container .small-container .city-wrapper .right .city-info .owner-info {
    display: none;
  }
  .cities-container .small-container .city-wrapper .right .followed-users {
    margin-top: 10px;
  }
  .cities-container .small-container .city-wrapper .right .followed-users .pc-users {
    display: none;
  }
  .cities-container .small-container .city-wrapper .right .followed-users .mp-users {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
  .cities-container .small-container .city-wrapper .right .followed-users .more-users {
    width: 22px;
    height: 22px;
    font-size: 10px;
  }
  .cities-container .small-container .city-wrapper .right .followed-users .more-users::after {
    font-size: 15px;
    left: 50%;
    transform: translate(-50%, -20%);
  }
  .cities-container .small-container .city-wrapper .right .followed-users .total-count {
    font-size: 12px;
    font-weight: 400;
  }
  .cities-container .small-container .city-wrapper .right .followed-users .total-count span {
    color: #f9ba48;
  }
  .cities-container .small-container .city-wrapper .right .city-indexes {
    display: none;
  }
  .cities-container .small-container .city-wrapper .right .line {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #f0f0f0;
    margin: 9px 0;
  }
  .cities-container .small-container .city-wrapper .right .hide-in-pc {
    display: flex;
  }
  .cities-container .small-container .city-wrapper-reverse {
    flex-direction: row;
  }
  .cities-container .small-container .create-city-btn {
    display: none;
    width: 70%;
    padding: 5px;
    font-size: 12px;
    margin-top: 40px;
  }
  .cities-container .small-container .create-city-btn .create-icon {
    width: 15px;
    height: 15px;
  }
  .cities-container .small-container .create-city-btn .create-icon::after {
    left: 50%;
    transform: translate(-50%, -4%);
  }
  .city-tag-wrapper {
    font-size: 11px;
    flex-wrap: wrap;
    padding: 10px 5px;
    color: #e89b11;
    column-gap: 5px;
    padding: 0px;
  }
  .city-tag-wrapper .tag {
    background-color: rgba(249, 186, 72, 0.1);
    border-radius: 4px;
    padding: 2px;
    column-gap: 0;
  }
  .city-tag-wrapper .tag img {
    width: 12px;
    margin-right: 5px;
  }
  .city-tag-wrapper .tag .normal {
    display: none;
  }
  .city-tag-wrapper .tag .small {
    display: block;
  }
  .ant-progress-steps-item {
    width: 7px !important;
    height: 2px !important;
    border-radius: 20%;
  }
}
.site-swiper {
  position: relative;
  z-index: 1;
}
.site-swiper .swiper {
  display: grid;
}
.site-swiper .swiper-wrapper {
  min-width: 0;
}
.site-swiper img {
  width: 100%;
  max-height: 508px;
  object-fit: cover;
}
.site-swiper .btn-wrapper {
  position: absolute;
  z-index: 2;
  bottom: 30px;
  right: 58px;
  display: flex;
  column-gap: 24px;
}
.site-swiper .btn-wrapper .custom-prev {
  width: 38px;
  height: 38px;
  background: url("~/assets/cities/pre-btn.png");
  background-size: cover;
  cursor: pointer;
}
.site-swiper .btn-wrapper .custom-next {
  width: 38px;
  height: 38px;
  background: url("~/assets/cities/next-btn.png");
  background-size: cover;
  cursor: pointer;
}
.site-swiper .btn-wrapper .custom-btn-disabled {
  opacity: 0.5;
}
.site-swiper .btn-wrapper .custom-prev:hover {
  background: url("~/assets/cities/pre-btn-hover.png");
  background-size: cover;
}
.site-swiper .btn-wrapper .custom-next:hover {
  background: url("~/assets/cities/next-btn-hover.png");
  background-size: cover;
}
.site-swiper .active-image {
  cursor: pointer;
}
@media screen and (max-width: 1175px) {
  .site-swiper img {
    height: 180px;
    object-position: center;
  }
  .site-swiper .btn-wrapper .custom-prev,
  .site-swiper .btn-wrapper .custom-next {
    display: none;
  }
}
.bn-wrapper {
  /* 定义滚动条的宽度、颜色和背景 */
  /* 定义滚动条的滑块颜色 */
  /* 定义滚动条轨道的背景颜色 */
  margin-top: -20px;
  background-color: white;
}
.bn-wrapper ::-webkit-scrollbar {
  height: 5px;
  /* 宽度 */
  border-radius: 3px;
}
.bn-wrapper ::-webkit-scrollbar-thumb {
  background-color: #ffc65b;
  /* 滑块颜色 */
}
.bn-wrapper ::-webkit-scrollbar-track {
  background-color: #ebebeb;
  /* 轨道背景颜色 */
}
.bn-wrapper * {
  box-sizing: border-box;
}
.bn-wrapper .title-w {
  text-align: center;
  font-size: 19px;
  font-weight: bold;
  color: #3d3d3d;
}
.bn-wrapper .title-w .title {
  position: relative;
  width: 240px;
  margin: 0 auto;
}
.bn-wrapper .title-w .title .circle-big {
  width: 20px;
  height: 20px;
  position: absolute;
}
.bn-wrapper .title-w .title .circle-small {
  width: 13px;
  height: 13px;
  position: absolute;
}
.bn-wrapper .title-w .line {
  width: 75px;
  height: 6px;
  border-radius: 3px;
  background-color: #ffc65b;
  margin: 5px auto 0;
}
.bn-wrapper .block1 {
  width: 100%;
  background: url("~/assets/business/p1@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.bn-wrapper .block1 .content {
  padding: 20px 0 24px 38px;
  position: relative;
}
.bn-wrapper .block1 .content .text {
  font-size: 21px;
  line-height: 26px;
  font-weight: bold;
}
.bn-wrapper .block1 .content img {
  position: absolute;
  width: 220px;
  bottom: 0;
  right: 15px;
}
.bn-wrapper .block1 .content .learn-btn {
  width: 92px;
  height: 28px;
  line-height: 28px;
  border-radius: 14px;
  background-color: #3d3d3d;
  margin-top: 10px;
  font-size: 10px;
  color: white;
  text-align: center;
  cursor: pointer;
}
.bn-wrapper .block1 .content .p2 {
  display: none;
}
.bn-wrapper .block2 .content {
  padding: 32px 20px 0 20px;
}
.bn-wrapper .block2 .content .title-w .title .circle-big {
  left: 25px;
}
.bn-wrapper .block2 .content .title-w .title .circle-small {
  bottom: 3px;
  right: -1px;
}
.bn-wrapper .block2 .content .title-w .title-pc {
  display: none;
}
.bn-wrapper .block2 .content .sub-title-mb {
  width: 251px;
  height: 26px;
  line-height: 26px;
  background-color: #ffc65b;
  border-radius: 13px;
  font-size: 12px;
  color: #3d3d3d;
  text-align: center;
  margin: 20px auto 0;
}
.bn-wrapper .block2 .content .text-content-wrapper {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-top: 22px;
}
.bn-wrapper .block2 .content .text-content-wrapper .sub-title-pc {
  display: none;
}
.bn-wrapper .block2 .content .text-content-wrapper .text-content {
  width: 186px;
  font-size: 10px;
  line-height: 18px;
  color: #3d3d3d;
}
.bn-wrapper .block2 .content .text-content-wrapper .illustration {
  width: 151px;
}
.bn-wrapper .block3 .content {
  padding: 30px 12px 20px 12px;
}
.bn-wrapper .block3 .content .title-w .title {
  width: 325px;
}
.bn-wrapper .block3 .content .title-w .title .circle-big {
  top: -5px;
  left: -8px;
}
.bn-wrapper .block3 .content .title-w .title .circle-small {
  bottom: 0px;
  right: -5px;
}
.bn-wrapper .block3 .content .sub-title {
  font-size: 14px;
  color: #3d3d3d;
  text-align: center;
  margin-top: 10px;
}
.bn-wrapper .block3 .content .text-content-w {
  margin-top: 13px;
}
.bn-wrapper .block3 .content .text-content-w .text-content .item {
  display: flex;
  align-items: center;
  font-size: 11px;
  column-gap: 5px;
  margin-top: 5px;
}
.bn-wrapper .block3 .content .text-content-w .text-content .item div:first-child {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #ffc658;
  font-size: 9px;
  text-align: center;
}
.bn-wrapper .block3 .content .text-content-w .text-content .item .custom {
  width: 330px;
}
.bn-wrapper .block3 .content .text-content-w .illustration {
  display: none;
}
.bn-wrapper .block4 {
  background-color: #ffc65b;
  padding: 30px 4px 20px 4px;
}
.bn-wrapper .block4 .content .title-w .title .circle-big {
  top: -5px;
  left: -15px;
}
.bn-wrapper .block4 .content .title-w .title .circle-small {
  bottom: 0;
}
.bn-wrapper .block4 .content .title-w .sub-title {
  margin-top: 5px;
  text-align: center;
  font-size: 14px;
  color: #3d3d3d;
}
.bn-wrapper .block4 .content .title-w .line {
  background-color: rgba(255, 249, 238, 0.5);
}
.bn-wrapper .block4 .content .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 13px;
  row-gap: 13px;
  margin-top: 25px;
}
.bn-wrapper .block4 .content .list .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 110px;
  border-radius: 5px;
  background-color: white;
  padding: 15px 0;
}
.bn-wrapper .block4 .content .list .item .icon {
  width: 39px;
  height: 39px;
  line-height: 39px;
  text-align: center;
  background-color: #ffc65b;
  border-radius: 50%;
}
.bn-wrapper .block4 .content .list .item .icon img {
  width: 21px;
  height: 21px;
}
.bn-wrapper .block4 .content .list .item .text {
  font-size: 13px;
  font-weight: bold;
  margin-top: 10px;
}
.bn-wrapper .block4 .content .list .item .text1,
.bn-wrapper .block4 .content .list .item .text2 {
  font-size: 10px;
}
.bn-wrapper .block4 .content .list .item .text1 {
  margin-top: 5px;
}
.bn-wrapper .block5 .content {
  padding: 50px 15px 0 15px;
}
.bn-wrapper .block5 .content .title-w .title {
  width: 200px;
}
.bn-wrapper .block5 .content .title-w .title .circle-big {
  top: -5px;
  left: -5px;
}
.bn-wrapper .block5 .content .title-w .title .circle-small {
  bottom: 0;
  right: 0px;
}
.bn-wrapper .block5 .content .title-w .line {
  margin-top: 15px;
}
.bn-wrapper .block5 .content .text-content {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 198, 91, 0.1);
  border-radius: 5px;
  margin: 15px auto 0;
  padding: 10px 10px 10px 20px;
  row-gap: 15px;
}
.bn-wrapper .block5 .content .text-content div {
  position: relative;
  font-size: 12px;
  color: #3d3d3d;
}
.bn-wrapper .block5 .content .text-content div::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #ffc65b;
  top: 16%;
  left: -10px;
}
.bn-wrapper .block5 .content .text-content .single::before {
  top: 35%;
}
.bn-wrapper .block5 .content .sub-title {
  width: 170px;
  height: 26px;
  line-height: 26px;
  border-radius: 13px;
  background-color: #ffc65b;
  margin: 25px auto 0;
  font-size: 12px;
  color: #3d3d3d;
  text-align: center;
}
.bn-wrapper .block5 .content .graphic-content-wrapper {
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  column-gap: 3px;
  overflow-x: scroll;
  padding: 10px 0;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column {
  width: 88px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column .b1 {
  background-color: white;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  padding: 15px 0;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column .b1 .text1 {
  font-size: 14px;
  color: #90c985;
  font-weight: bold;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column .b1 .text2 {
  width: 75px;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  background-color: #90c985;
  font-size: 12px;
  font-weight: bold;
  color: white;
  margin: 5px auto 0;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column .b1 .text3 {
  font-size: 9px;
  line-height: 18px;
  color: #90c985;
  border-top: 1px dotted #d8d8d8;
  border-bottom: 1px dotted #d8d8d8;
  margin-top: 10px;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column .b1 .text4 {
  font-size: 9px;
  line-height: 18px;
  color: #90c985;
  border-bottom: 1px dotted #d8d8d8;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column .b1 .text5 {
  font-size: 7px;
  width: 81px;
  height: 100px;
  margin: 5px auto 0;
  text-align: justify;
  padding: 0 3px;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column .b2,
.bn-wrapper .block5 .content .graphic-content-wrapper .column .b3,
.bn-wrapper .block5 .content .graphic-content-wrapper .column .b4,
.bn-wrapper .block5 .content .graphic-content-wrapper .column .b5 {
  font-size: 9px;
  color: #3d3d3d;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column .b6 {
  font-size: 7px;
  color: #3d3d3d;
  text-align: justify;
  padding: 3px 2px;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column .b2 {
  display: flex;
  justify-content: space-between;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column .b2 div {
  width: 40px;
  height: 16px;
  line-height: 16px;
  border-radius: 3px;
  border: 1px solid #cacaca;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column .b2 div:last-child {
  text-align: right;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column .b3,
.bn-wrapper .block5 .content .graphic-content-wrapper .column .b4,
.bn-wrapper .block5 .content .graphic-content-wrapper .column .b5,
.bn-wrapper .block5 .content .graphic-content-wrapper .column .b6 {
  border: 1px solid #cacaca;
  border-radius: 3px;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column-0 .b1 {
  border: none;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column-0 .b1 .text3,
.bn-wrapper .block5 .content .graphic-content-wrapper .column-0 .b1 .text4,
.bn-wrapper .block5 .content .graphic-content-wrapper .column-0 .b1 .text5 {
  font-size: 9px;
  color: #3d3d3d;
  text-align: right;
  padding-right: 5px;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column-0 .b3 {
  border: none;
  text-align: right;
  padding: 2px 5px 1px 0;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column-0 .b6 {
  border: none;
  padding: 2px 0 3px 0;
  text-align: right;
  padding-right: 5px;
  font-size: 9px;
  line-height: 22px;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column-2 .b1 .text1 {
  color: #839add;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column-2 .b1 .text2 {
  background-color: #839add;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column-2 .b1 .text3 {
  margin-top: 22px;
  color: #839add;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column-2 .b1 .text4 {
  color: #839add;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column-2 .b2 {
  text-align: left;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column-3 .b1 .text1 {
  color: #918edd;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column-3 .b1 .text2 {
  background-color: #918edd;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column-3 .b1 .text3 {
  margin-top: 36px;
  color: #918edd;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column-3 .b1 .text4 {
  color: #918edd;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column-4 .b1 .text1 {
  color: #f3b257;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column-4 .b1 .text2 {
  background-color: #f3b257;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column-4 .b1 .text3 {
  margin-top: 48px;
  color: #f3b257;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .column-4 .b1 .text4 {
  color: #f3b257;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .double {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .double .top {
  display: flex;
  flex-direction: row;
  column-gap: 3px;
  align-items: flex-end;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .double .top .column-5 .b1 .text1 {
  color: #e39b75;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .double .top .column-5 .b1 .text2 {
  background-color: #e39b75;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .double .top .column-5 .b1 .text3 {
  margin-top: 60px;
  color: #e39b75;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .double .top .column-5 .b1 .text4 {
  color: #e39b75;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .double .top .column-6 .b1 .text1 {
  color: #df7c75;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .double .top .column-6 .b1 .text2 {
  background-color: #df7c75;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .double .top .column-6 .b1 .text3 {
  margin-top: 72px;
  color: #df7c75;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .double .top .column-6 .b1 .text4 {
  color: #df7c75;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .double .bottom {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .double .bottom div {
  font-size: 9px;
  color: #3d3d3d;
  border: 1px solid #cacaca;
  text-align: center;
  border-radius: 3px;
}
.bn-wrapper .block5 .content .graphic-content-wrapper .double .bottom .b3 {
  font-size: 7px;
  line-height: 28px;
}
.bn-wrapper .block6 .content {
  padding: 30px 20px 30px 20px;
}
.bn-wrapper .block6 .content .title-w .title {
  width: 290px;
}
.bn-wrapper .block6 .content .title-w .title .circle-big {
  top: -5px;
  left: -5px;
}
.bn-wrapper .block6 .content .title-w .title .circle-small {
  bottom: 0px;
}
.bn-wrapper .block6 .content .list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 15px;
}
.bn-wrapper .block6 .content .list .item {
  display: flex;
  flex-direction: row;
  justify-content: left;
  column-gap: 20px;
  align-items: center;
  background-color: #fff9ee;
  border: 1px solid #f3b257;
  border-radius: 5px;
  font-size: 12px;
  padding: 10px 10px 10px 10px;
}
.bn-wrapper .block6 .content .list .item .img1 {
  width: 74px;
  height: 63px;
  margin-left: -3px;
}
.bn-wrapper .block6 .content .list .item .img2 {
  width: 71px;
  height: 63px;
}
.bn-wrapper .block6 .content .list .item .img3 {
  width: 64px;
  height: 63px;
}
.bn-wrapper .block7 {
  background-color: #ffc65b;
  padding: 30px 14px 20px 14px;
}
.bn-wrapper .block7 .content .title-w {
  margin-bottom: 18px;
}
.bn-wrapper .block7 .content .title-w .title {
  width: 350px;
}
.bn-wrapper .block7 .content .title-w .title .circle-big {
  top: -5px;
  left: 15px;
}
.bn-wrapper .block7 .content .title-w .title .circle-small {
  bottom: 5px;
}
.bn-wrapper .block7 .content .title-w .line {
  margin-top: 10px;
  background-color: rgba(255, 249, 238, 0.5);
}
.bn-wrapper .block7 .content .text-content {
  font-size: 11px;
  color: #3d3d3d;
  margin-top: 10px;
  text-align: left;
  padding: 0 10px;
}
.bn-wrapper .block7 .content .list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 12px;
  row-gap: 17px;
  margin-top: 20px;
}
.bn-wrapper .block7 .content .list .item {
  width: 165px;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  color: #3d3d3d;
  padding: 13px 10px 17px 10px;
}
.bn-wrapper .block7 .content .list .item .name {
  font-size: 18px;
  font-weight: bold;
}
.bn-wrapper .block7 .content .list .item .line {
  width: 34px;
  height: 4px;
  background-color: #f3b257;
  border-radius: 2px;
  margin: 0 auto;
}
.bn-wrapper .block7 .content .list .item .sub-list {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  align-items: flex-start;
  padding: 14px 5px 0 14px;
}
.bn-wrapper .block7 .content .list .item .sub-list .sub-item {
  text-align: left;
  font-size: 11px;
  position: relative;
}
.bn-wrapper .block7 .content .list .item .sub-list .sub-item .dot {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: #ffc65b;
  top: 5px;
  left: -8px;
}
.bn-wrapper .block8 .content {
  padding: 33px 12px 0 12px;
}
.bn-wrapper .block8 .content .title-w .title {
  width: 100px;
}
.bn-wrapper .block8 .content .title-w .title .circle-big {
  top: -5px;
  left: -5px;
}
.bn-wrapper .block8 .content .title-w .title .circle-small {
  bottom: 0;
}
.bn-wrapper .block8 .content .list {
  display: flex;
  justify-content: center;
  column-gap: 15px;
  flex-direction: row;
  margin-top: 20px;
}
.bn-wrapper .block8 .content .list .item {
  width: 168px;
  background-color: #fff9ee;
  border: 1px solid #ffc65b;
  border-radius: 5px;
  position: relative;
  padding: 0 5px 10px 5px;
}
.bn-wrapper .block8 .content .list .item .title {
  position: absolute;
  width: 150px;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  background-image: url("../assets/business/p14@3x.png");
  background-size: contain;
  background-repeat: no-repeat;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.bn-wrapper .block8 .content .list .item .b1 {
  padding-top: 46px;
  font-size: 9px;
}
.bn-wrapper .block8 .content .list .item .b1 div {
  display: inline-block;
  vertical-align: middle;
}
.bn-wrapper .block8 .content .list .item .b1 .left {
  font-weight: bold;
}
.bn-wrapper .block8 .content .list .item .b1 .right {
  width: 100px;
  border-radius: 10px;
  text-align: center;
  padding: 3px 10px;
  background-color: #ffc65b;
  margin-left: 20px;
}
.bn-wrapper .block8 .content .list .item .b1 .right2 {
  width: 80px;
  text-align: center;
  padding: 3px 0px 3px 0px;
  margin-left: 30px;
}
.bn-wrapper .block8 .content .list .item .b-d {
  padding-top: 39px;
}
.bn-wrapper .block8 .content .list .item .b2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 9px;
  justify-content: space-between;
  margin-top: 12px;
}
.bn-wrapper .block8 .content .list .item .b2 .left {
  font-weight: bold;
}
.bn-wrapper .block8 .content .list .item .b2 .right {
  width: 105px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.bn-wrapper .block8 .content .list .item .b2 .right div {
  position: relative;
}
.bn-wrapper .block8 .content .list .item .b2 .right div::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ffc65b;
  top: 5px;
  left: -8px;
}
.bn-wrapper .block8 .content .list .item .b2-d {
  margin-top: 7px;
}
.bn-wrapper .block8 .content .list .item .b3,
.bn-wrapper .block8 .content .list .item .b4 {
  font-size: 9px;
}
.bn-wrapper .block8 .content .list .item .b3 div,
.bn-wrapper .block8 .content .list .item .b4 div {
  display: inline-block;
  vertical-align: middle;
}
.bn-wrapper .block8 .content .list .item .b3 .left,
.bn-wrapper .block8 .content .list .item .b4 .left {
  font-weight: bold;
}
.bn-wrapper .block8 .content .list .item .b3 .right,
.bn-wrapper .block8 .content .list .item .b4 .right {
  margin-left: 15px;
}
.bn-wrapper .block8 .content .list .item .b3 {
  margin-top: 10px;
}
.bn-wrapper .block8 .content .list .item .b4 {
  margin-top: 5px;
}
.bn-wrapper .block9 .content {
  padding: 35px 0px 28px 0px;
}
.bn-wrapper .block9 .content .title-w .title {
  width: 370px;
}
.bn-wrapper .block9 .content .title-w .title .circle-big {
  left: -3px;
}
.bn-wrapper .block9 .content .title-w .title .circle-small {
  bottom: 0;
  right: 10px;
}
.bn-wrapper .block9 .content .title-w .line {
  margin-top: 9px;
}
.bn-wrapper .block9 .content .wechat-pics {
  margin-top: 17px;
  padding: 0 15px 14px 15px;
  background-color: white;
  overflow: scroll;
}
.bn-wrapper .block9 .content .wechat-pics img {
  width: 762px;
  height: 308px;
}
.bn-wrapper .block10 {
  background-color: #585858;
  padding: 22px 0 20px 0;
  text-align: center;
  color: white;
}
.bn-wrapper .block10 .title {
  font-size: 18px;
  font-weight: bold;
}
.bn-wrapper .block10 .line {
  width: 80px;
  height: 6px;
  border-radius: 3px;
  margin: 5px auto 0;
  background-color: #ffc65b;
}
.bn-wrapper .block10 .contact-text {
  font-size: 13px;
  margin-top: 15px;
}
.bn-wrapper .block10 img {
  width: 100px;
  height: 96px;
  margin-top: 18px;
}
@media screen and (min-width: 1175px) {
  .bn-wrapper .content {
    width: 1200px;
    margin: 0 auto;
  }
  .bn-wrapper .title-w {
    font-size: 36px;
  }
  .bn-wrapper .title-w .title {
    width: 760px;
    margin: 0 auto;
  }
  .bn-wrapper .title-w .title .circle-big {
    width: 40px;
    height: 40px;
  }
  .bn-wrapper .title-w .title .circle-small {
    width: 17px;
    height: 17px;
  }
  .bn-wrapper .title-w .line {
    width: 169px;
    height: 12px;
    border-radius: 6px;
    margin: 15px auto 0;
  }
  .bn-wrapper .block1 .content {
    padding: 78px 50px 75px 50px;
  }
  .bn-wrapper .block1 .content .text {
    font-size: 72px;
    line-height: 100px;
    font-weight: bold;
  }
  .bn-wrapper .block1 .content .text-en {
    font-size: 40px;
    font-weight: lighter;
    line-height: 57px;
  }
  .bn-wrapper .block1 .content img {
    right: 70px;
  }
  .bn-wrapper .block1 .content .learn-btn {
    width: 302px;
    height: 90px;
    line-height: 90px;
    border-radius: 45px;
    margin-top: 57px;
    font-size: 36px;
  }
  .bn-wrapper .block1 .content .p2 {
    display: block;
    width: 656px;
  }
  .bn-wrapper .block1 .content .mbp2 {
    display: none;
  }
  .bn-wrapper .block2 .content {
    padding: 72px 0;
  }
  .bn-wrapper .block2 .content .title-w .title .circle-big {
    left: -10px;
    bottom: 25px;
  }
  .bn-wrapper .block2 .content .title-w .title .circle-small {
    bottom: 5px;
  }
  .bn-wrapper .block2 .content .title-w .title-pc {
    display: block;
  }
  .bn-wrapper .block2 .content .title-w .title-mb {
    display: none;
  }
  .bn-wrapper .block2 .content .sub-title-mb {
    display: none;
  }
  .bn-wrapper .block2 .content .text-content-wrapper {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    column-gap: 45px;
  }
  .bn-wrapper .block2 .content .text-content-wrapper .sub-title-pc {
    margin-top: 13px;
    display: block;
    width: 576px;
    height: 56px;
    line-height: 56px;
    background-color: #ffc65b;
    border-radius: 28px;
    font-size: 28px;
    color: #3d3d3d;
    text-align: center;
  }
  .bn-wrapper .block2 .content .text-content-wrapper .text-content {
    width: 615px;
    font-size: 20px;
    color: #3d3d3d;
    margin-top: 22px;
    line-height: 32px;
  }
  .bn-wrapper .block2 .content .text-content-wrapper .illustration {
    width: 340px;
  }
  .bn-wrapper .block3 .content {
    padding: 10px 0 0 0;
  }
  .bn-wrapper .block3 .content .title-w .title {
    width: 620px;
  }
  .bn-wrapper .block3 .content .title-w .title .circle-small {
    bottom: 10px;
    right: -20px;
  }
  .bn-wrapper .block3 .content .sub-title {
    font-size: 24px;
    text-align: center;
    margin-top: 24px;
  }
  .bn-wrapper .block3 .content .text-content-w {
    margin-top: -20px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .bn-wrapper .block3 .content .text-content-w .text-content .item {
    font-size: 20px;
    margin-top: 15px;
  }
  .bn-wrapper .block3 .content .text-content-w .text-content .item div:first-child {
    width: 26px;
    height: 26px;
    font-size: 18px;
  }
  .bn-wrapper .block3 .content .text-content-w .text-content .item .custom {
    width: 600px;
  }
  .bn-wrapper .block3 .content .text-content-w .illustration {
    display: block;
    width: 495px;
  }
  .bn-wrapper .block4 {
    padding: 35px 4px 40px 4px;
  }
  .bn-wrapper .block4 .content .title-w .title .circle-big {
    top: -10px;
    left: 130px;
  }
  .bn-wrapper .block4 .content .title-w .title .circle-small {
    bottom: 10px;
  }
  .bn-wrapper .block4 .content .title-w .sub-title {
    margin-top: 17px;
    font-size: 24px;
    color: #3d3d3d;
    font-weight: 400;
  }
  .bn-wrapper .block4 .content .list {
    justify-content: space-between;
    margin-top: 20px;
  }
  .bn-wrapper .block4 .content .list .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 220px;
    border-radius: 10px;
    background-color: white;
    padding: 23px 0 18px 0;
  }
  .bn-wrapper .block4 .content .list .item .icon {
    width: 77px;
    height: 77px;
    line-height: 77px;
  }
  .bn-wrapper .block4 .content .list .item .icon img {
    width: 42px;
    height: 42px;
  }
  .bn-wrapper .block4 .content .list .item .text {
    font-size: 26px;
    margin-top: 18px;
  }
  .bn-wrapper .block4 .content .list .item .text1,
  .bn-wrapper .block4 .content .list .item .text2 {
    font-size: 20px;
  }
  .bn-wrapper .block4 .content .list .item .text1 {
    margin-top: 5px;
  }
  .bn-wrapper .block5 .content {
    padding: 50px 0 0 0;
  }
  .bn-wrapper .block5 .content .title-w .title {
    width: 420px;
  }
  .bn-wrapper .block5 .content .title-w .title .circle-big {
    top: -5px;
    left: 0;
  }
  .bn-wrapper .block5 .content .title-w .title .circle-small {
    bottom: 10px;
    right: 2px;
  }
  .bn-wrapper .block5 .content .title-w .line {
    margin-top: 21px;
  }
  .bn-wrapper .block5 .content .text-content {
    width: 812px;
    border-radius: 10px;
    margin: 35px auto 0;
    padding: 12px 0px 12px 48px;
    row-gap: 5px;
    align-items: flex-start;
  }
  .bn-wrapper .block5 .content .text-content div {
    font-size: 20px;
  }
  .bn-wrapper .block5 .content .text-content div::before {
    width: 8px;
    height: 8px;
    top: 36%;
    left: -20px;
  }
  .bn-wrapper .block5 .content .text-content .single::before {
    top: 35%;
  }
  .bn-wrapper .block5 .content .sub-title {
    width: 387px;
    height: 52px;
    line-height: 52px;
    border-radius: 26px;
    margin: 55px auto 0;
    font-size: 28px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper {
    column-gap: 6px;
    padding: 0;
    overflow: hidden;
    margin-top: 12px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column {
    width: 175px;
    row-gap: 10px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column .b1 {
    border-radius: 10px;
    padding: 30px 0px 40px 0;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column .b1 .text1 {
    font-size: 28px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column .b1 .text2 {
    width: 150px;
    height: 48px;
    line-height: 48px;
    border-radius: 24px;
    font-size: 24px;
    margin: 10px auto 0;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column .b1 .text3 {
    font-size: 18px;
    line-height: 36px;
    margin-top: 20px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column .b1 .text4 {
    font-size: 18px;
    line-height: 36px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column .b1 .text5 {
    font-size: 14px;
    width: 162px;
    height: 200px;
    margin: 10px auto 0;
    text-align: justify;
    padding: 0 6px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column .b2,
  .bn-wrapper .block5 .content .graphic-content-wrapper .column .b3,
  .bn-wrapper .block5 .content .graphic-content-wrapper .column .b4,
  .bn-wrapper .block5 .content .graphic-content-wrapper .column .b5 {
    font-size: 18px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column .b6 {
    font-size: 14px;
    padding: 6px 4px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column .b2 div {
    width: 84px;
    height: 32px;
    line-height: 32px;
    border-radius: 6px;
    border: 1px solid #cacaca;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column .b3,
  .bn-wrapper .block5 .content .graphic-content-wrapper .column .b4,
  .bn-wrapper .block5 .content .graphic-content-wrapper .column .b5,
  .bn-wrapper .block5 .content .graphic-content-wrapper .column .b6 {
    border-radius: 6px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column-0 {
    width: 150px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column-0 .b1 .text3,
  .bn-wrapper .block5 .content .graphic-content-wrapper .column-0 .b1 .text4 {
    font-size: 18px;
    padding-right: 10px;
    text-align: right;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column-0 .b1 .text5 {
    padding: 0 20px;
    font-size: 18px;
    text-align: right;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column-0 .b3 {
    padding: 3px 10px 1px 0;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column-0 .b6 {
    padding: 4px 0 6px 0;
    padding-right: 10px;
    font-size: 18px;
    line-height: 44px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column-2 .b1 .text3 {
    margin-top: 45px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column-3 .b1 .text3 {
    margin-top: 70px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .column-4 .b1 .text3 {
    margin-top: 95px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .double {
    row-gap: 10px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .double .top {
    column-gap: 6px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .double .top .column-5 .b1 .text3 {
    margin-top: 120px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .double .top .column-6 .b1 .text3 {
    margin-top: 145px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .double .bottom {
    row-gap: 10px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .double .bottom div {
    font-size: 18px;
    border-radius: 6px;
  }
  .bn-wrapper .block5 .content .graphic-content-wrapper .double .bottom .b3 {
    font-size: 14px;
    line-height: 56px;
  }
  .bn-wrapper .block6 .content {
    padding: 70px 0 60px 0;
  }
  .bn-wrapper .block6 .content .title-w .title {
    width: 550px;
  }
  .bn-wrapper .block6 .content .title-w .title .circle-big {
    top: -10px;
    left: -35px;
  }
  .bn-wrapper .block6 .content .title-w .title .circle-small {
    bottom: 5px;
    right: -10px;
  }
  .bn-wrapper .block6 .content .title-w .line {
    margin-top: 20px;
  }
  .bn-wrapper .block6 .content .list {
    flex-direction: row;
    column-gap: 25px;
    margin-top: 43px;
  }
  .bn-wrapper .block6 .content .list .item {
    width: 385px;
    flex-direction: column;
    row-gap: 20px;
    border-radius: 10px;
    font-size: 20px;
    padding: 20px 35px;
  }
  .bn-wrapper .block6 .content .list .item .img1 {
    width: 148px;
    height: 126px;
  }
  .bn-wrapper .block6 .content .list .item .img2 {
    width: 143px;
    height: 126px;
  }
  .bn-wrapper .block6 .content .list .item .img3 {
    width: 130px;
    height: 126px;
  }
  .bn-wrapper .block7 {
    padding: 35px 0px 30px 0px;
  }
  .bn-wrapper .block7 .content .title-w {
    margin-bottom: 28px;
  }
  .bn-wrapper .block7 .content .title-w .title {
    width: 580px;
  }
  .bn-wrapper .block7 .content .title-w .title .circle-big {
    top: -10px;
    left: -25px;
  }
  .bn-wrapper .block7 .content .title-w .title .circle-small {
    bottom: 5px;
  }
  .bn-wrapper .block7 .content .title-w .line {
    margin-top: 24px;
  }
  .bn-wrapper .block7 .content .text-content {
    font-size: 18px;
    margin-top: 0px;
    padding: 0 75px;
  }
  .bn-wrapper .block7 .content .list {
    justify-content: space-between;
    column-gap: 20px;
    margin-top: 45px;
  }
  .bn-wrapper .block7 .content .list .item {
    width: 272px;
    border-radius: 10px;
    padding: 18px 10px 12px 10px;
  }
  .bn-wrapper .block7 .content .list .item .name {
    font-size: 32px;
  }
  .bn-wrapper .block7 .content .list .item .line {
    width: 58px;
    height: 6px;
    border-radius: 3px;
    margin-top: 12px;
  }
  .bn-wrapper .block7 .content .list .item .sub-list {
    padding: 20px 10px 0 20px;
    row-gap: 5px;
  }
  .bn-wrapper .block7 .content .list .item .sub-list .sub-item {
    font-size: 18px;
  }
  .bn-wrapper .block7 .content .list .item .sub-list .sub-item .dot {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    top: 10px;
    left: -15px;
  }
  .bn-wrapper .block8 .content {
    padding: 66px 30px 0 30px;
  }
  .bn-wrapper .block8 .content .title-w .title {
    width: 190px;
  }
  .bn-wrapper .block8 .content .list {
    margin-top: 40px;
    column-gap: 60px;
  }
  .bn-wrapper .block8 .content .list .item {
    width: 540px;
    border-radius: 10px;
    padding: 0 25px 20px 20px;
  }
  .bn-wrapper .block8 .content .list .item .title {
    width: 297px;
    height: 54px;
    line-height: 54px;
    font-size: 26px;
  }
  .bn-wrapper .block8 .content .list .item .b1 {
    padding-top: 85px;
    font-size: 18px;
  }
  .bn-wrapper .block8 .content .list .item .b1 .right {
    width: 200px;
    border-radius: 20px;
    margin-left: 85px;
    padding: 6px 15px;
  }
  .bn-wrapper .block8 .content .list .item .b1 .right2 {
    text-align: center;
    width: 200px;
    margin-left: 85px;
  }
  .bn-wrapper .block8 .content .list .item .b-d {
    padding-top: 85px;
  }
  .bn-wrapper .block8 .content .list .item .b2 {
    font-size: 18px;
    margin-top: 24px;
    justify-content: left;
    column-gap: 60px;
  }
  .bn-wrapper .block8 .content .list .item .b2 .left {
    font-weight: bold;
  }
  .bn-wrapper .block8 .content .list .item .b2 .right {
    width: 294px;
    row-gap: 7px;
  }
  .bn-wrapper .block8 .content .list .item .b2 .right div::before {
    width: 8px;
    height: 8px;
    top: 10px;
    left: -18px;
  }
  .bn-wrapper .block8 .content .list .item .b2-d {
    margin-top: 24px;
  }
  .bn-wrapper .block8 .content .list .item .b3,
  .bn-wrapper .block8 .content .list .item .b4 {
    font-size: 18px;
  }
  .bn-wrapper .block8 .content .list .item .b3 .right,
  .bn-wrapper .block8 .content .list .item .b4 .right {
    margin-left: 40px;
  }
  .bn-wrapper .block8 .content .list .item .b3 {
    margin-top: 15px;
  }
  .bn-wrapper .block8 .content .list .item .b4 {
    margin-top: 10px;
  }
  .bn-wrapper .block9 .content {
    padding: 80px 0 33px 0;
  }
  .bn-wrapper .block9 .content .title-w .title {
    width: 690px;
  }
  .bn-wrapper .block9 .content .title-w .title .circle-big {
    left: -10px;
  }
  .bn-wrapper .block9 .content .title-w .title .circle-small {
    bottom: 0;
    right: -20px;
  }
  .bn-wrapper .block9 .content .wechat-pics {
    overflow: hidden;
    margin-top: 40px;
    padding: 0 15px 14px 0;
  }
  .bn-wrapper .block9 .content .wechat-pics img {
    width: 1200px;
    height: 486px;
  }
  .bn-wrapper .block10 {
    padding: 44px 0 40px 0;
  }
  .bn-wrapper .block10 .title {
    font-size: 36px;
  }
  .bn-wrapper .block10 .line {
    width: 161px;
    height: 12px;
    border-radius: 6px;
    margin: 20px auto 0;
  }
  .bn-wrapper .block10 .contact-text {
    font-size: 26px;
    margin-top: 20px;
  }
  .bn-wrapper .block10 img {
    width: 199px;
    height: 193px;
    margin-top: 12px;
  }
}
.create-post-btns-wrapper {
  margin-bottom: 10px !important;
}
.create-post-btns-wrapper .ant-card-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 10px 5px !important;
}
.create-post-btns-wrapper .ant-card-body .ant-btn {
  width: 45%;
  margin-top: 0 !important;
}
.post-report-records-modal .ant-space-item {
  width: 100%;
}
.apk-upload-modal {
  position: absolute;
  top: 30px;
  width: 700px;
  padding: 20px 20px;
}
.job-placeholder {
  position: absolute;
  top: 70px;
  left: 20px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  pointer-events: none;
  white-space: pre-line;
}
.job-placeholder ul {
  padding: 10px 0px 10px 15px;
}
.job-placeholder li {
  padding: 3px 0;
}
@media (max-width: 768px) {
  .job-placeholder {
    font-size: 12px;
  }
  .job-placeholder ul {
    padding: 10px 10px 0 10px;
  }
}
.apk-top {
  width: 100%;
  height: 50px;
  background-image: url("~/assets/images/apk-top.jpg");
  background-size: contain;
  background-repeat: repeat-x;
}
.apk {
  width: 750px;
  margin: 50px auto;
}
.apk .latest-version {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 16px;
}
.apk .latest-version .latest-version-item {
  width: 44%;
  display: flex;
  padding: 10px 0px;
  border-bottom: 1px solid #e5e5e5;
  justify-content: space-between;
}
.apk .divider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  margin: 30px 0;
}
.apk .divider .line {
  flex: 1;
  height: 1px;
  background-color: #e5e5e5;
  margin: 0 10px;
}
.apk .release-note {
  font-size: 16px;
  margin-top: 30px;
}
.apk .release-note ul {
  padding-left: 16px !important;
}
.apk .release-note ul li {
  padding: 5px 0;
}
.apk .apk-version-list {
  font-size: 16px;
}
.apk .apk-version-list .apk-version-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 4px;
  cursor: pointer;
}
.apk .apk-version-list .apk-version-item:nth-child(odd) {
  background: #ede8dd;
}
.apk .apk-version-list .apk-version-item:nth-child(even) {
  background: #f8f7f5;
}
.apk .apk-version-list .apk-version-item .release-note-short {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.apk .apk-version-list .apk-version-item .version {
  position: relative;
  max-width: 180px;
  text-align: left;
}
.apk .apk-version-list .apk-version-item .version .new-tag {
  position: absolute;
  right: -55px;
  top: 50%;
  transform: translateY(-50%);
}
.apk .apk-version-list .apk-version-list-more {
  cursor: pointer;
  margin-top: 10px;
}
.apk .apk-version-list .apk-version-list-more .apk-version-list-more-icon {
  transform: rotate(90deg);
  margin: 5px;
}
.apk .description > div {
  margin-top: 20px;
}
.apk .description > div div {
  line-height: 30px;
}
.apk-overlay {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 999;
  font-size: 22px;
}
.apk-overlay img {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.apk-overlay .content {
  padding: 100px 0 0 40px;
}
.apk-overlay .content .one {
  display: flex;
  align-items: center;
}
.apk-overlay .content .one .more-wrapper {
  position: relative;
  margin-left: 10px;
  width: 50px;
  height: 40px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.5);
}
.apk-overlay .content .one .more-wrapper .more {
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
}
.apk-overlay .content .two {
  margin-top: 15px;
}
.apk-overlay .content .num {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  color: white;
  background-color: #f9ba48;
  margin-right: 10px;
}
.apk-download-top {
  position: relative;
  padding: 30px 80px 30px 20px;
  font-size: 18px;
  background: grey;
  color: #d1cbcb;
}
.apk-download-top .arrow {
  width: 60px;
  position: absolute;
  right: 15px;
  top: 20px;
}
.show-overlay {
  display: flex;
}
@media screen and (max-width: 480px) {
  .apk {
    width: 95%;
  }
  .apk .latest-version .latest-version-item {
    width: 100%;
  }
  .apk .apk-version-list .apk-version-item .release-note-short {
    width: 60px;
  }
  .apk .apk-version-list .apk-version-item .version {
    width: 35%;
  }
  .apk .apk-version-list .apk-version-item .version .new-tag {
    right: -37px;
  }
}
.collaborations-container {
  margin-bottom: 55px;
}
.collaborations-container .small-container {
  width: 1200px;
  margin: 0 auto;
}
.collaborations-container .small-container .collaboration-wrapper {
  display: flex;
  margin-bottom: 57px;
  min-height: 387px;
  border-radius: 8px;
  background-color: white;
}
.collaborations-container .small-container .collaboration-wrapper .left {
  position: relative;
  width: 516px;
  overflow: hidden;
  height: inherit;
  text-align: left;
  cursor: pointer;
}
.collaborations-container .small-container .collaboration-wrapper .left .cover {
  position: absolute;
  z-index: 0;
  width: 516px;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.collaborations-container .small-container .collaboration-wrapper .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 57px 30px 0px 30px;
}
.collaborations-container .small-container .collaboration-wrapper .right .collaboration-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.collaborations-container .small-container .collaboration-wrapper .right .collaboration-info .collaboration-name {
  max-width: 440px;
  font-size: 36px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  color: #3d3d3d;
}
.collaborations-container .small-container .collaboration-wrapper .right .collaboration-info .join-wrapper {
  display: flex;
  align-items: center;
}
.collaborations-container .small-container .collaboration-wrapper .right .collaboration-info .join-wrapper .ele-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 20px;
  color: #f9ba48;
}
.collaborations-container .small-container .collaboration-wrapper .right .collaboration-info .join-wrapper .ele-wrapper img {
  width: 16px;
}
.collaborations-container .small-container .collaboration-wrapper .right .owner-info {
  align-self: flex-end;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 40px 0;
  width: 100%;
}
.collaborations-container .small-container .collaboration-wrapper .right .owner-info div:first-child {
  flex-grow: 1;
  text-align: right;
}
.collaborations-container .small-container .collaboration-wrapper .right .owner-info .nickname {
  max-width: 100px;
  margin-left: 10px;
  color: #7b7b7b;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.collaborations-container .small-container .collaboration-wrapper .right .owner-info .date {
  color: #9b9b9b;
  margin-left: 12px;
}
.collaborations-container .small-container .collaboration-wrapper .right .followed-users .pc-users {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.collaborations-container .small-container .collaboration-wrapper .right .followed-users .mp-users {
  display: none;
}
.collaborations-container .small-container .collaboration-wrapper .right .followed-users .more-users {
  position: relative;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #f0f0f0;
  color: #7b7b7b;
  font-size: 30px;
}
.collaborations-container .small-container .collaboration-wrapper .right .followed-users .more-users::after {
  content: "...";
  position: absolute;
  font-size: 26px;
  left: 50%;
  transform: translate(-50%, -15%);
}
.collaborations-container .small-container .collaboration-wrapper .right .followed-users .total-count {
  font-size: 16px;
  font-weight: 400;
}
.collaborations-container .small-container .collaboration-wrapper .right .followed-users .total-count span {
  color: #f9ba48;
}
.collaborations-container .small-container .collaboration-wrapper .right .line {
  display: none;
}
.collaborations-container .small-container .collaboration-wrapper .right .hide-in-pc {
  display: none;
}
.collaborations-container .small-container .collaboration-wrapper-reverse {
  flex-direction: row-reverse;
}
.collaborations-container .small-container .collaboration-wrapper-reverse .tag-wrapper {
  justify-content: end;
}
.collaboration-tag-wrapper {
  display: flex;
  column-gap: 10px;
  position: relative;
  z-index: 1;
  padding: 20px;
  color: white;
  font-size: 16px;
  font-weight: 400;
}
.collaboration-tag-wrapper .tag {
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
  align-items: center;
  padding: 2px 7px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.35);
}
.collaboration-tag-wrapper .tag img {
  width: 20px;
}
.collaboration-tag-wrapper .tag .small {
  display: none;
}
.ant-progress-steps-item {
  width: 10px !important;
  height: 3px !important;
  border-radius: 30%;
}
.collaboration-name {
  color: white;
}
@media screen and (max-width: 1175px) {
  .collaborations-container {
    margin-bottom: 25px;
  }
  .collaborations-container .small-container {
    width: 100%;
    padding: 0 15px;
  }
  .collaborations-container .small-container .collaboration-wrapper {
    margin-bottom: 12px;
    width: 100%;
    min-height: 120px;
  }
  .collaborations-container .small-container .collaboration-wrapper .left {
    width: 88px;
    min-height: 120px;
  }
  .collaborations-container .small-container .collaboration-wrapper .left .hide-in-mp {
    display: none;
  }
  .collaborations-container .small-container .collaboration-wrapper .left .cover {
    width: 88px;
  }
  .collaborations-container .small-container .collaboration-wrapper .right {
    padding: 10px;
    width: auto;
  }
  .collaborations-container .small-container .collaboration-wrapper .right .collaboration-info .collaboration-name {
    font-size: 16px;
    white-space: break-spaces;
    white-space: pre-wrap;
  }
  .collaborations-container .small-container .collaboration-wrapper .right .collaboration-info .join-wrapper .ele-wrapper {
    font-size: 14px;
    gap: 3px;
  }
  .collaborations-container .small-container .collaboration-wrapper .right .collaboration-info .join-wrapper .ele-wrapper img {
    width: 12px;
  }
  .collaborations-container .small-container .collaboration-wrapper .right .owner-info {
    display: none;
  }
  .collaborations-container .small-container .collaboration-wrapper .right .followed-users {
    margin-top: 10px;
  }
  .collaborations-container .small-container .collaboration-wrapper .right .followed-users .pc-users {
    display: none;
  }
  .collaborations-container .small-container .collaboration-wrapper .right .followed-users .mp-users {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
  .collaborations-container .small-container .collaboration-wrapper .right .followed-users .more-users {
    width: 22px;
    height: 22px;
    font-size: 10px;
  }
  .collaborations-container .small-container .collaboration-wrapper .right .followed-users .more-users::after {
    font-size: 15px;
    left: 50%;
    transform: translate(-50%, -20%);
  }
  .collaborations-container .small-container .collaboration-wrapper .right .followed-users .total-count {
    font-size: 12px;
    font-weight: 400;
  }
  .collaborations-container .small-container .collaboration-wrapper .right .followed-users .total-count span {
    color: #f9ba48;
  }
  .collaborations-container .small-container .collaboration-wrapper .right .line {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #f0f0f0;
    margin: 9px 0;
  }
  .collaborations-container .small-container .collaboration-wrapper .right .hide-in-pc {
    display: flex;
  }
  .collaborations-container .small-container .collaboration-wrapper-reverse {
    flex-direction: row;
  }
  .collaboration-tag-wrapper {
    font-size: 11px;
    flex-wrap: wrap;
    padding: 10px 5px;
    color: #e89b11;
    column-gap: 5px;
    padding: 0px;
  }
  .collaboration-tag-wrapper .tag {
    background-color: rgba(249, 186, 72, 0.1);
    border-radius: 4px;
    padding: 2px;
    column-gap: 0;
  }
  .collaboration-tag-wrapper .tag img {
    width: 12px;
    margin-right: 5px;
  }
  .collaboration-tag-wrapper .tag .normal {
    display: none;
  }
  .collaboration-tag-wrapper .tag .small {
    display: block;
  }
  .ant-progress-steps-item {
    width: 7px !important;
    height: 2px !important;
    border-radius: 20%;
  }
  .collaboration-name {
    font-size: 24px;
  }
}
.follow-btn {
  margin-left: 18px;
  height: 45px !important;
  width: 120px !important;
  font-size: 20px !important;
  font-weight: 600;
}
.followed-btn {
  background-color: #f0f0f0;
  color: #7b7b7b;
  border: none;
}
.followed-btn[disabled] {
  background-color: #f0f0f0 !important;
  color: #7b7b7b !important;
  border: none !important;
  cursor: default !important;
}
.add-group-btn {
  background-color: #f9ba48;
  color: #ffffff;
  border: none;
}
@media screen and (max-width: 1175px) {
  .follow-btn {
    width: auto !important;
    height: 24px !important;
    padding: 0 12px !important;
    font-size: 12px !important;
    margin-left: 8px;
  }
}
